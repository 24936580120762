import classNames from "classnames";
import { FC } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { colors } from "theme/theme";

export const LoadingSpinner: FC<{
  className?: string;
  color?: string;
  size?: number;
}> = ({ size = 30, color = colors.blue, className }) => (
  <div className={classNames("loading-wrapper", className)}>
    <ClipLoader color={color} loading={true} size={size} />
  </div>
);
