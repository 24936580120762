import { Button } from "components/button";
import ForceLocaleLink from "components/link";
import { Icon, Shape } from "components/shape";
import { useLabels } from "helpers/hooks/index";
import { useLocale } from "helpers/locale";
import { Provider, getShareLink, getSocialShareLink } from "helpers/share";
import Link from "next/link";
import { FC, useCallback, useEffect, useState } from "react";
import {
  OverlayTrigger,
  Popover,
  PopoverContent,
  PopoverTitle,
  Tooltip,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { breakpoints, colors } from "theme/theme";

const copyToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

interface RecommendButtonProps {
  label?: string;
  share?: [];
  title?: string;
  url?: string;
}

const RecommendButton: FC<RecommendButtonProps> = ({
  label,
  url: initUrl,
  title: initTitle,
  share,
}) => {
  const { locale } = useLocale();
  const [labelVal, setlabelVal] = useState(label);

  const [defaultLabel] = useLabels(["ui-810", "Recommend"]);

  useEffect(() => {
    setlabelVal(defaultLabel.label);
  }, [defaultLabel.label, labelVal, locale]);

  const [copyLabel, copiedLabel, otherLabel, emailLabel] = useLabels(
    ["ui-176", "Copy link"],
    ["ui-811", "Copied!"],
    ["ui-812", "Other options"],
    ["ui-569", "Email"],
  );

  const localShareOptions: { icon: Icon; label: string; provider: string }[] = [
    {
      label: emailLabel.label,
      icon: "envelope",
      provider: "email",
    },
    {
      label: copyLabel.short_title,
      icon: "link",
      provider: "_copy",
    },
    {
      label: otherLabel.label,
      icon: "external-link",
      provider: "_native",
    },
  ];

  const [url, setUrl] = useState(initUrl);
  const [title, setTitle] = useState(initTitle);

  const [copied, setCopied] = useState(false);

  const handleShare = useCallback(
    ({ provider }) => {
      switch (provider) {
        case "_copy":
          copyToClipboard(url);
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
          break;

        case "_native":
          try {
            navigator.share({ title, url });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
          }
          break;
      }
    },
    [url, title],
  );

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (!initUrl) setUrl(window.location.href);
    }
  }, [initUrl]);

  const recommendPopover = (props) => (
    <Popover id="recommendPopover" {...props}>
      <PopoverTitle className="recommendPopover__title">
        {labelVal}
        <span
          className="recommendPopover__closeBtn"
          onClick={() => document.body.click()}
        >
          <Shape variant="close" fill={colors.darkBlue} />
        </span>
      </PopoverTitle>
      <PopoverContent className="recommendPopover__content">
        {share.map(
          (share, index) =>
            getSocialShareLink(share, url, title).url && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip">
                    {getSocialShareLink(share, url, title).provider}
                  </Tooltip>
                }
                key={index}
              >
                <div className="recommendPopover__content__icon">
                  <ForceLocaleLink
                    href={getSocialShareLink(share, url, title).url}
                    passHref
                    target="_blank"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        getSocialShareLink(share, url, title).url,
                        "_blank",
                      );
                      setTitle(window.document.title);
                    }}
                  >
                    <Shape
                      variant={getSocialShareLink(share, url, title).icon}
                      fill={colors.darkBlue}
                    />
                  </ForceLocaleLink>
                </div>
              </OverlayTrigger>
            ),
        )}

        {localShareOptions.map(
          (shareOption, index) =>
            (shareOption.provider !== "_native" || navigator.canShare) && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="button-tooltip">
                    {shareOption.provider === "_copy" && copied
                      ? copiedLabel.label
                      : shareOption.label}
                  </Tooltip>
                }
                key={index}
              >
                {shareOption.provider.startsWith("_") ? (
                  <div
                    className="recommendPopover__content__icon"
                    onClick={() => handleShare(shareOption)}
                  >
                    <Shape variant={shareOption.icon} fill={colors.darkBlue} />
                  </div>
                ) : (
                  <div className="recommendPopover__content__icon">
                    <Link
                      href={getShareLink(shareOption.provider as Provider, {
                        url,
                        title,
                      })}
                      passHref
                      target="_blank"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(
                          getShareLink(shareOption.provider as Provider, {
                            url,
                            title: window?.document.title,
                          }),
                          "_blank",
                        );
                        setTitle(window.document.title);
                      }}
                    >
                      <div>
                        <Shape
                          variant={shareOption.icon}
                          fill={colors.darkBlue}
                        />
                      </div>
                    </Link>
                  </div>
                )}
              </OverlayTrigger>
            ),
        )}
      </PopoverContent>
    </Popover>
  );

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  return (
    <OverlayTrigger
      trigger="click"
      placement={isMobile ? "top" : "right"}
      rootClose
      overlay={recommendPopover}
    >
      <Button
        variant="small"
        icon="forward"
        label={labelVal}
        className="recommend-button"
      />
    </OverlayTrigger>
  );
};

export default RecommendButton;
