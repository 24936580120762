import { WrapperComponentProps } from "constants/types";
import { FC } from "react";

const DefaultWrapper: FC<WrapperComponentProps> = (props) => {
  return (
    <div style={{ margin: "50px 0" }}>
      <pre
        style={{
          background: "#eee",
          color: "#444",
          padding: "25px",
          overflow: "scroll",
          maxHeight: "300px",
        }}
      >
        {JSON.stringify(props, null, 4)}
      </pre>
    </div>
  );
};

export default DefaultWrapper;
