import classNames from "classnames";
import { Shape } from "components/shape";
import { useNavContext } from "contexts/nav-context";
import { useLabels } from "helpers/hooks";
import getConfig from "next/config";
import { FC } from "react";

interface CustomerPortalButtonProps {
  className?: string;
}

const CustomerPortalButton: FC<CustomerPortalButtonProps> = ({ className }) => {
  const { setCustomerPortalOverlayOpen } = useNavContext();
  const [myKrohneLabel] = useLabels(["ui-895", "myKROHNE"]);

  const { publicRuntimeConfig } = getConfig();

  if (publicRuntimeConfig.APP_ENV === "live") return;
  return (
    <div className={classNames(className)}>
      <button
        className="btn btn-sm nav-link"
        onClick={() => setCustomerPortalOverlayOpen((open) => !open)}
      >
        <Shape variant="user" />
        {myKrohneLabel.label}
      </button>
    </div>
  );
};

export default CustomerPortalButton;
