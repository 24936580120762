/**
 * Navigation entity ids
 */
const navigationSettings = {
  meta_pid: "page-154",
  footer_pid: "page-155",
  contact_pid: "page-333",
};

export type NavigationKey = "contact" | "footer" | "meta";

export default navigationSettings;
