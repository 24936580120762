import { LocaleCode } from "constants/types";

/**
 * Splits locale string into country and language.
 *
 * @param locale in format `${country}_${language}` e.g. "de_en"
 * @returns array with `[country, language]`, e. g. ["de", "en"]
 */
export const getCountryAndLanguage = (locale: string): string[] =>
  locale?.split("_") || [];

/**
 * Joins country and language into locale string.
 *
 * @param country two letter country code, e.g. "de"
 * @param language two letter language code, e.g. "en"
 * @returns locale in format `${country}_${language}` e.g. "de_en"
 */
export const getLocale = (country: string, language: string): string =>
  `${country}_${language}`;

/**
 * Get subdomains in reversed order from given hostname.
 *
 * @param hostname a domain with subdomains which can include the BASE_URL
 * @example "level-application-selector.de.krohne.com" -> ["de", "level-application-selector"]
 */
export const getSubdomains = (hostname: string) =>
  hostname
    .replace(process.env.BASE_URL, "")
    .split(":")[0]
    .split(".")
    .filter(Boolean)
    .reverse();

const nonCountrySites = ["k", "lp"];

/**
 * Checks if the given site code is a country code (e. g. "de" is a country code;
 * "k" for group and "lp" for landingpages are site codes which are not country codes).
 *
 * @param site assumes to be a country code which is included in locale-data.json
 * @returns true if the given site is a country code
 */
export const isSiteCountry = (site: string) => {
  return !nonCountrySites.includes(site);
};

/**
 * Mapping of CRM country codes to country codes used in the application.
 */
const crmCountryMapping = {
  uk: "gb",
};

/**
 * Gets the correct country code from the CRM country code.
 *
 * @param crmCountry A country code from KROHNE CRM
 * @returns A country code which can be used in the application
 */
export const getCountryFromCrmCountry = (crmCountry: string) => {
  crmCountry = crmCountry.toLowerCase();

  // special cases
  if (crmCountry in crmCountryMapping) {
    return crmCountryMapping[crmCountry as keyof typeof crmCountryMapping];
  }

  return crmCountry;
};

/**
 * Mapping of country codes used in the application to ISO country codes.
 */
const isoCountryMapping: { [country: string]: string } = {
  uk: "gb",
};

/**
 * Mapping of language codes used in the appication to ISO country codes.
 */
const isoLanguageMapping: { [language: string]: string } = {};

/**
 * Gets the ISO locale with country for HTML `lang`attribute according to W3C.
 *
 * @param locale in format `${country}_${language}` e.g. "de_en"
 * @returns ISO locale in format `${language}-${country}` e.g. "en-GB", according to https://www.w3schools.com/tags/ref_country_codes.asp
 */
export const getIsoLocale = (locale: LocaleCode) => {
  const [country, language] = getCountryAndLanguage(locale);
  if (!country || !language) return getIsoLocale("k_en");

  let isoCountry = country.toUpperCase();
  let isoLanguage = language.toLowerCase();

  if (country in isoCountryMapping) {
    isoCountry =
      isoCountryMapping[
        country as keyof typeof isoCountryMapping
      ].toUpperCase();
  }

  if (language in isoLanguageMapping) {
    isoLanguage =
      isoLanguageMapping[
        language as keyof typeof isoLanguageMapping
      ].toLowerCase();
  }

  if (!isSiteCountry(country)) {
    return isoLanguage;
  }

  return `${isoLanguage}-${isoCountry}`;
};

/**
 * Gets the ISO language for HTML alternate `link` in ISO 639-1 format.
 *
 * @param language e.g. "de"
 * @returns ISO 639-1 locale e.g. "de"
 */
export const getIsoLanguage = (language: string) => {
  if (language in isoLanguageMapping) {
    return isoLanguageMapping[language as keyof typeof isoLanguageMapping];
  }

  return language.toLocaleLowerCase();
};
