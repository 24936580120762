import { FormContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext } from "react";

const FormContext = createContext<FormContextProps>(null);

export const FormContextProvider: FC<
  FormContextProps & { children: ReactNode }
> = ({
  formModal,
  toggleFormModal,
  forms,
  setEntity,
  entity,
  formCt,
  setFormCt,
  formCtData,
  setFormCtData,
  serviceFormOpen,
  setServiceFormOpen,
  children,
}) => {
  return (
    <FormContext.Provider
      value={{
        formModal,
        toggleFormModal,
        forms,
        setEntity,
        entity,
        formCt,
        setFormCt,
        formCtData,
        setFormCtData,
        serviceFormOpen,
        setServiceFormOpen,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error("useFormContext must be used within FormContextProvider");
  }
  return context;
};
