import { useUser } from "@auth0/nextjs-auth0/client";
import { UserData, UserDataContextProps } from "constants/types";
import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";

export const UserDataContext = createContext<UserDataContextProps>(null);

export const UserDataContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { user, isLoading: isUserLoading } = useUser();
  const [data, setData] = useState<UserData | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();

  useEffect(() => {
    if (!user) {
      if (!isUserLoading) {
        setData(undefined);
        setIsLoading(false);
        setError(undefined);
      }

      return;
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`/api/user-data`);
        const json = await response.json();
        if ("code" in json && json.code !== 200) {
          throw json;
        }
        setData(json as UserData);
        setError(undefined);
      } catch (error) {
        setError(error as Error);
      } finally {
        setIsLoading(false);
      }
    };
    void fetchData();
  }, [user, isUserLoading]);

  return (
    <UserDataContext.Provider
      value={{
        data,
        isLoading,
        error,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error(
      "useUserDataContext must be used within UserDataContextProvider",
    );
  }
  return context;
};
