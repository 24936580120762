import { Container } from "components/container";
import HeadingWrapper from "components/wrapper/components/heading";

import { getEdgeSettings } from "components/wrapper/components";
import { Col, Row } from "react-bootstrap";
import ComponentMapper from "components/wrapper";
import { FC, useEffect } from "react";
import { ContentItem, WrapperComponentProps } from "constants/types";
import { getSpeakingId } from "helpers/text-processing";

interface GridWrapperProps extends WrapperComponentProps {
  grid_width?: string;
  side_by_side_mobile?: boolean;
}
const separateContent = (contentArray: ContentItem[]) => {
  const separatedContent = [];
  let currentSection = [];

  contentArray.forEach((item) => {
    if (item.type === "column_seperator") {
      if (currentSection.length > 0) {
        separatedContent.push(currentSection);
        currentSection = [];
      }
    } else {
      currentSection.push(item);
    }
  });

  // Füge den letzten Abschnitt hinzu, falls vorhanden
  if (currentSection.length > 0) {
    separatedContent.push(currentSection);
  }

  return separatedContent;
};

const GridWrapper: FC<GridWrapperProps> = (props) => {
  const width = props?.grid_width?.split("-");
  let grids = [];
  // old grid collumn handling fallback
  if (
    props?.["content-right"]?.length > 0 ||
    props?.["content-middle"]?.length > 0
  ) {
    grids.push(props?.["content-left"]);
    if (props?.["content-middle"] && width?.length === 3)
      grids.push(props?.["content-middle"]);
    grids.push(props?.["content-right"]);
  } else grids = separateContent(props?.["content-left"]);
  const gridLeft = width?.[0] ?? 6;
  const gridMiddle = width?.length === 3 ? width[1] : 0;
  const gridRight = width?.length === 3 ? width[2] : width?.[1] ?? 6;

  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__grid-${props.index}`}
      className="section component__grid"
    >
      <HeadingWrapper {...props} />
      <Row>
        {grids[0] && (
          <Col
            xl={gridLeft}
            lg={gridLeft}
            md={
              gridMiddle
                ? gridLeft < gridMiddle
                  ? 3
                  : 4
                : gridLeft < gridRight
                ? 4
                : 8
            }
            sm={gridMiddle ? 4 : 6}
            xs={props.side_by_side_mobile ? (gridMiddle ? 4 : 6) : null}
          >
            <ComponentMapper
              components={grids[0]}
              page={props.page}
              pc={props.pc}
              parent={props.parent}
              id={"ce-left-" + props.index}
              partOfGrid
            />
          </Col>
        )}
        {gridMiddle && grids[1] ? (
          <Col
            xl={gridMiddle}
            lg={gridMiddle}
            md={gridLeft < gridMiddle ? 6 : 4}
            sm={gridMiddle ? 4 : 6}
            xs={props.side_by_side_mobile ? (gridMiddle ? 4 : 6) : null}
          >
            <ComponentMapper
              components={grids[1]}
              page={props.page}
              pc={props.pc}
              parent={props.parent}
              id={"ce-middle-" + props.index}
              partOfGrid
            />
          </Col>
        ) : null}
        {(gridMiddle && grids[2]) || (!gridMiddle && grids[1]) ? (
          <Col
            xl={gridRight}
            lg={gridRight}
            md={
              gridMiddle
                ? gridLeft < gridMiddle
                  ? 3
                  : 4
                : gridRight < gridLeft
                ? 4
                : 8
            }
            sm={gridMiddle ? 4 : 6}
            xs={props.side_by_side_mobile ? (gridMiddle ? 4 : 6) : null}
          >
            <ComponentMapper
              components={gridMiddle ? grids[2] : grids[1]}
              page={props.page}
              pc={props.pc}
              parent={props.parent}
              id={"ce-right-" + props.index}
              partOfGrid
            />
          </Col>
        ) : null}
      </Row>
    </Container>
  );
};

export default GridWrapper;
