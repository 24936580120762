import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { Heading } from "components/heading";
import { Shape } from "components/shape";
import { LightboxProps } from "constants/types";
import {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { colors } from "theme/theme";

import classNames from "classnames";
import { shameScroll } from "helpers/shame-scroll";
import { createPortal } from "react-dom";
import { Transition } from "react-transition-group";
import { uniqueId } from "lodash";

export interface IPortal {
  selector?: string;
}

const Portal = (props: PropsWithChildren<IPortal>) => {
  props = {
    selector: "__ROOT_PORTAL__",
    ...props,
  };

  const { selector, children } = props;

  const ref = useRef<Element>();
  const [mounted, setMounted] = useState(false);

  const selectorPrefixed = "#" + selector.replace(/^#/, "");

  useEffect(() => {
    ref.current = document.querySelector(selectorPrefixed);

    if (!ref.current) {
      const div = document.createElement("div");
      div.setAttribute("id", selector);
      document.body.appendChild(div);
      ref.current = div;
    }

    setMounted(true);
  }, [selector, selectorPrefixed]);

  return mounted ? createPortal(children, ref.current) : null;
};

export default Portal;

export const Lightbox: FC<LightboxProps & HTMLAttributes<HTMLDivElement>> = ({
  open,
  setOpen,
  heading,
  headingType = "primary",
  size = "small",
  background = "white",
  wrapperClassName = "",
  className = "",
  children,
  disableAnimation = false,
  switchHandler,
  disableNavigation = { prev: false, next: false },
}) => {
  const [id, setId] = useState(uniqueId("lightbox_"));

  const isSmall = size === "small";
  const firstRender = useRef(true);

  // Add default close button
  if (heading && !heading?.contentRight && setOpen) {
    heading.contentRight = (
      <div
        onClick={() => {
          setOpen(false);
        }}
        className="lightbox-close"
      >
        <Shape
          variant="close-big"
          fill={headingType !== "white" ? colors.white : colors.gray100}
        />
      </div>
    );
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (open) shameScroll({ open: true, id: id });
    else shameScroll({ close: true, id: id });
  }, [id, open]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 27) {
      setOpen(false);
    }
  };

  useEffect(() => {
    window.document.addEventListener("keydown", handleKeyDown);
    return () => {
      window.document.removeEventListener("keydown", handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const transitionRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Portal>
        <Transition
          in={open}
          nodeRef={transitionRef}
          mountOnEnter
          unmountOnExit
          appear
          timeout={300}
        >
          {(state) => (
            <div className={wrapperClassName} ref={transitionRef}>
              <div
                className={classNames(
                  "lightbox",
                  className,
                  isSmall && "lightbox-small",
                  state && `lightbox-${state}`,
                  disableAnimation && "lightbox-noAnimation",
                )}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <div
                  className={`lightbox-window ${size}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {heading && (
                    <div className={`lightbox-heading ${headingType}`}>
                      {isSmall ? (
                        <Heading {...heading} />
                      ) : (
                        <Container>
                          <Heading {...heading} />
                        </Container>
                      )}
                    </div>
                  )}
                  <div
                    className={classNames(
                      "lightbox-content",
                      background === "white" && "white",
                    )}
                  >
                    {switchHandler && (
                      <>
                        {Object.keys(switchHandler).map((direction, index) => (
                          <div
                            key={index}
                            className={`lightbox-navigation lightbox-navigation__${direction}`}
                            style={
                              disableNavigation[direction]
                                ? { cursor: "not-allowed" }
                                : null
                            }
                            onClick={switchHandler[direction]}
                          >
                            <Shape
                              variant={`caret-${
                                direction === "prev" ? "left" : "right"
                              }`}
                              fill={
                                disableNavigation[direction]
                                  ? colors.gray40
                                  : colors.gray80
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                    {children}
                    {background === "grey-triangle" && (
                      <Cloudinary
                        className="lightbox-background h-full"
                        media={{
                          digital_asset_id: "im-background/grey-triangle-4-3",
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </Portal>
    </>
  );
};
