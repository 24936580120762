import { buildQueryUrl, useLabels } from "helpers/hooks";
import { Button } from "react-bootstrap";
import { colors } from "theme/theme";
import { ContentsetEntity, Download } from "constants/types";
import { entitiesConfig } from "config/entity";
import { Fragment, useEffect, useRef, useState } from "react";
import { HeaderContext, flexRender } from "@tanstack/react-table";
import { LoadingSpinner } from "components/loading-spinner";
import { useContentsetContext } from "contexts/contentset-context";
import { useRouter } from "next/router";
import { useSlugPath } from "helpers/routing";
import EntityTableRow from "components/contentset/entity-table-rows";
import Head from "next/head";
import LoadMore from "../load-more";

export const EntityTable = () => {
  const {
    tableInstance,
    contentset,
    attributes,
    nextPage,
    isLoadingContentsetPage,
  } = useContentsetContext();
  const entityConfig =
    entitiesConfig[contentset.dlc_api ? "video" : contentset.entity_type] ??
    entitiesConfig._default;

  const [categoryBtn, noResultsBtn] = useLabels(
    ["ui-16", "Categories"],
    [
      entityConfig?.noresult_ui_element ??
        contentset?.noresult_ui_element ??
        "ui-585",
      "No results found",
    ],
  );

  const noResultButton = noResultsBtn?.link_relative_path ? (
    <Button
      key={noResultsBtn.link_relative_path}
      label={noResultsBtn.short_title}
      url={noResultsBtn.link_relative_path}
      target="_blank"
      icon="caret-right"
      className="mt-2"
    />
  ) : null;
  const { rows: entities } = tableInstance.getRowModel();

  if (contentset.layout === "dlc") contentset.entity_type = "document";

  const { query } = useRouter();
  const path = useSlugPath();

  const [loadAll, setLoadAll] = useState(false);

  useEffect(() => {
    if (query.loadAll !== undefined) {
      setLoadAll(true);
    }
  }, [query]);

  const targetUrl = useRef(null);

  useEffect(() => {
    if ("URLSearchParams" in window) {
      const queryParams = new URLSearchParams(window.location.search);

      queryParams.set("loadAll", "");

      const query = Array.from(queryParams.values()).length
        ? "?" + queryParams.toString()
        : "";

      targetUrl.current = path.split("?")[0] + query + window?.location.hash;
    }
  }, [path]);

  return (
    <>
      {loadAll && (
        <Head>
          <meta name="robots" content="noindex, follow" />
        </Head>
      )}
      <div className="entity-table">
        <>
          <div className="thead">
            <div className="tr">
              {tableInstance.getLeafHeaders().map((header) => (
                <Fragment key={header.id}>
                  {flexRender(header.column.columnDef.header, {
                    ...header.getContext(),
                    attributes,
                    categoryBtn,
                  } as HeaderContext<ContentsetEntity, any>)}
                </Fragment>
              ))}
            </div>
          </div>
          <div className="tbody">
            {entities.length === 0 &&
              (isLoadingContentsetPage ? (
                <LoadingSpinner
                  className="d-flex justify-content-center py-4"
                  color={colors.darkBlue}
                />
              ) : (
                <div className="d-flex justify-content-center py-4">
                  {noResultsBtn.title}
                  {noResultButton}
                </div>
              ))}
            {entities.map((entity, index) => {
              const hasRevisions = (entity.original as Download).revisions;

              return hasRevisions !== undefined ? (
                <EntityTableRow
                  entity={entity}
                  variant={index % 2 === 0 ? "gray" : "white"}
                  key={entity.id}
                />
              ) : (
                <div className="tr" key={entity.id}>
                  {entity.getVisibleCells().map((cell) => {
                    return (
                      <Fragment key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </>
      </div>
      <LoadMore
        url={buildQueryUrl(`${contentset.id}-page`, nextPage + 1, 1, path)[0]}
      />
    </>
  );
};
