import { sanitize } from "helpers/text-processing";
import { FunctionComponent } from "react";
import BoostrapTable from "react-bootstrap/Table";
import { TableProps } from "../../constants/types";
import { Heading } from "../heading";

export const Tables: FunctionComponent<TableProps> = ({
  title,
  intro = "",
  striped = true,
  rows,
  className,
}) => {
  return (
    <div className={"table-component " + className}>
      <Heading title={title} level="h4" stylingLevel="h5" className="mb-3" />
      {intro !== "" && <p className="mb-3">{intro}</p>}
      <BoostrapTable striped={striped}>
        <tbody>
          {rows.length > 0 &&
            rows.map((row, index) => (
              <tr key={index}>
                <td
                  dangerouslySetInnerHTML={{
                    __html: sanitize(row.label),
                  }}
                />
                <td
                  dangerouslySetInnerHTML={{
                    __html: sanitize(row.value),
                  }}
                />
              </tr>
            ))}
        </tbody>
      </BoostrapTable>
    </div>
  );
};
