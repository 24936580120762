import { Cloudinary } from "components/cloudinary";
import { FC } from "react";
import { IconNavLinkProps } from "constants/types";
import { Nav } from "react-bootstrap";
import { Shape } from "components/shape";
import classNames from "classnames";
import Image from "next/image";

export const IconNavLink: FC<IconNavLinkProps> = ({
  alt,
  chevron = true,
  className,
  height = 18,
  href,
  icon,
  iconFill,
  label,
  media,
  onClick,
  open = false,
  src,
  type,
  width = 18,
}) => (
  <Nav.Item className={className} onClick={onClick}>
    <Nav.Link
      className={classNames("btn btn-sm", { "icon-nav-link": chevron })}
      href={href}
    >
      {src ? (
        <Image
          className="svg"
          src={src}
          alt={alt}
          height={height}
          width={width}
        />
      ) : type === "media" ? (
        <Cloudinary className="svg" media={media} ar="ar11" />
      ) : (
        <Shape variant={icon} fill={iconFill} />
      )}
      <div>{label}</div>
      {chevron ? (
        <Shape
          variant={open ? "caret-up-small" : "caret-down-small"}
          size={18}
        />
      ) : null}
    </Nav.Link>
  </Nav.Item>
);
