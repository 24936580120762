import capitalize from "lodash/capitalize"; // shared module :(
import { getProductGroupCategoryArray } from "../../config/dlcProductGroup";
import { createContext } from "../../helpers/api/query";
import { parseJson } from "../../helpers/utils";
import { Contentset, Filter, Kp_Kp_Variants } from "../../types";

function getFiltersByType(
  filters: Filter[],
  options,
  includeChildFilter = true,
  attributeTypeMap = [],
) {
  return filters
    ? filters.reduce((acc, filter) => {
        if (filter[`${options.key}`] === options.value) {
          // Remove attribute options if not part of that type, except dlc_type attributes options
          if (
            attributeTypeMap.length > 0 &&
            !filter.filter_attribute.includes("dlc_type")
          ) {
            if (!attributeTypeMap.includes(filter.filter_attribute)) return acc;
          }
          acc.push(filter);
        }
        if (filter.has_child_filter && includeChildFilter) {
          acc.push(
            ...getFiltersByType(
              filter.child_filter,
              options,
              true,
              attributeTypeMap,
            ),
          );
        }
        return acc;
      }, [])
    : [];
}

function getTodayAsString() {
  const d = new Date();
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

// TODO: Maybe rework this and use graphql documents instead of strings
export async function getFilterQueryVariables(
  contentset: Contentset,
  locale = "en",
  country = "k",
) {
  const {
    filter, // entity_type,
    family,
    filter_entity,
    category,
  } = contentset;

  // TODO: Generalize this, maybe by adding this information in the contentset
  const entity_types =
    contentset.layout === "dlc" ? ["kp", "so"] : [contentset.entity_type];

  const typeQueryAttributes = await Promise.all(
    entity_types.map(async (entity_type) => {
      // Create sort array
      const sort = parseJson<any[]>(contentset.sorter).reduce((acc, item) => {
        item.enabled &&
          item.attribute &&
          acc.push({ [item.attribute]: item.order_value });
        item.enabled &&
          item.own_attribute &&
          acc.push({ [item.own_attribute]: item.order_value });
        return acc;
      }, []);
      // Create search array
      const search: { "&": any[] } = {
        "&":
          entity_type === contentset.entity_type && category
            ? [{ categories: `,${category}` }]
            : [],
      };
      if (family?.length > 0) {
        search["&"].push({
          "|": family.map((item) => ({ family: item.code })),
        });
      }
      if (filter_entity && parseJson(filter_entity)) {
        const filterEntitySearch = parseJson<any>(
          filter_entity.replace("TODAY", getTodayAsString()),
        )?.search?.["&"];
        filterEntitySearch?.length > 0 &&
          filterEntitySearch.map((item) => search["&"].push(item));
      }
      // Create other searches
      const options_category_parent = getFiltersByType(filter, {
        key: "source_type",
        value: "category",
      });

      const options_category_code = getFiltersByType(
        options_category_parent,
        {
          key: "filter_label_source",
          value: "attribute",
        },
        false,
      );

      const checkType = (await import("../../types"))[
        "Get" + capitalize(entity_type) + "FullDocument"
      ];

      const attributeTypeMap =
        checkType?.definitions?.[0]?.selectionSet?.selections?.[0]?.selectionSet.selections.map(
          (field) => field.name.value,
        );

      const options_attribute = getFiltersByType(
        filter,
        {
          key: "source_type",
          value: "attribute",
        },
        true,
        attributeTypeMap,
      );

      const label_attribute = getFiltersByType(
        options_attribute,
        {
          key: "filter_label_source",
          value: "attribute",
        },
        false,
      );
      const label_akeneo = getFiltersByType(filter, {
        key: "filter_label_source",
        value: "akeneo",
      });

      if (contentset.layout === "dlc") {
        getProductGroupCategoryArray().map((category) => {
          options_category_code.push({ filter_category: category });
          return null;
        });
      }
      const filter_categories = [
        ...options_category_parent.map((filter) => ({
          parent: filter.filter_category,
        })),
        ...options_category_code.map((filter) => ({
          code: filter.filter_category,
        })),
      ];
      const filter_attribute_options = options_attribute.map((filter) => ({
        attribute: filter.filter_attribute,
      }));
      const filter_akeneo = label_akeneo.map((filter) => ({
        id: filter.filter_ui_element,
      }));
      const filter_attributes = label_attribute.map((filter) => ({
        code: filter.filter_attribute,
      }));

      // Add product_tag to attribute options if dlc for kp and so
      // Maybe add more entity types later or generalize this
      if (
        contentset.layout === "dlc" &&
        (entity_type === "kp" || entity_type === "so")
      ) {
        filter_attribute_options.push({ attribute: "product_tag" });
      }

      // Get Options
      let options =
        filter_attribute_options.length > 0
          ? filter_attribute_options
              .map((item) => item.attribute)
              .reduce((acc, item) => {
                // title, location_city and country are no attributes
                // if left, it will break the graphql query
                // if left out, filtering wont work, because it will need attribute/codes?
                if (
                  contentset.entity_type === "job" &&
                  ["title", "location_city", "country"].includes(item)
                ) {
                  return acc;
                }

                // same as above, ignoring title
                if (
                  contentset.entity_type === "event" &&
                  ["title"].includes(item)
                ) {
                  return acc;
                }

                // label is only a string field, this did throw errors on:
                // /de/products_translation-de_de/flow-measurement_translation-de_de/flowmeters_translation-de_de/differential-pressure-flowmeters_translation-de_de
                if (
                  item === "label" ||
                  item === "title" ||
                  item === "search_alias" ||
                  item === "application_id"
                ) {
                  acc += `
              ${item}`;
                  return acc;
                }
                if (item.includes("related_")) {
                  const type = capitalize(item.split("related_")[1]);
                  acc += `
              ${item} {
                __typename
                ... on ${type} {
                  title
                  id
                  key_visual {
                    __typename
                    ... on Im {
                      label
                      digital_asset_id
                      digital_asset_transformation
                      digital_asset_named_transformation_ar11 {
                        code
                      }
                      digital_asset_custom_transformation_ar11
                    }
                  }
                }
              }`;
                }
                if (!item.includes("dlc_type") && !item.includes("related_")) {
                  acc += `
              ${item} {
                ... on Attribute_option {
                  code
                }
              }`;
                }
                return acc;
              }, "")
          : "";

      if (contentset.layout === "dlc") {
        options += `
            parent_category {
              ... on Category {
                code
                label
              }
            }`;
      }
      return {
        options,
        sort,
        search,
        filter_categories,
        filter_attribute_options,
        filter_attributes,
        filter_akeneo,
      };
    }),
  );

  // Combine values of different entity_types into one object
  // This reduce can probably be shortened or returned directly
  const mergedTypeQueryAttributes: {
    filter_akeneo: { id: string }[];
    filter_attribute_options: { attribute: string }[];
    filter_attributes: { code: string }[];
    filter_categories: { parent: string }[];
    options: { [key: string]: string };
    search: { [key: string]: any };
    sort: { [key: string]: any };
  } = typeQueryAttributes.reduce(
    (acc, queryAttributes, index) => {
      const entity_type = entity_types[index];
      const {
        options,
        sort,
        search,
        filter_categories,
        filter_attribute_options,
        filter_attributes,
        filter_akeneo,
      } = queryAttributes;

      return {
        options: { ...acc.options, [entity_type]: options },
        search: { ...acc.search, [entity_type]: search },
        sort: { ...acc.sort, [entity_type]: sort },
        filter_categories: [...acc.filter_categories, ...filter_categories],
        filter_attribute_options: [
          ...acc.filter_attribute_options,
          ...filter_attribute_options,
        ],
        filter_attributes: [...acc.filter_attributes, ...filter_attributes],
        filter_akeneo: [...acc.filter_akeneo, ...filter_akeneo],
      };
    },
    {
      options: {},
      search: {},
      sort: {},
      filter_categories: [],
      filter_attribute_options: [],
      filter_attributes: [],
      filter_akeneo: [],
    },
  );

  const {
    options,
    sort,
    search,
    filter_categories,
    filter_attribute_options,
    filter_attributes,
    filter_akeneo,
  } = mergedTypeQueryAttributes;

  return {
    options,
    query_variables: {
      context: createContext(
        ["preferred_locale", locale],
        ["site", country === "k" ? "global" : country],
        ["country", country],
      ),
      ...entity_types.reduce((acc, type) => {
        return {
          ...acc,
          [`sort_${type}`]: JSON.stringify(sort[type]),
          [`search_${type}`]: JSON.stringify(search[type]),
        };
      }, {}),
      search_category: JSON.stringify({ "|": filter_categories }),
      search_attribute_option: JSON.stringify({
        "|": filter_attribute_options,
      }),
      search_ui: JSON.stringify({ "|": filter_akeneo }),
      search_attribute: JSON.stringify({ "|": filter_attributes }),
    },
  };
}

/*

### Filtering Functions ###

*/

// Finds the variant with a slug or returns first variant
export const findVariant = (variants: Kp_Kp_Variants[], slug: string) => {
  const found = variants.find((variant: any) => {
    return variant.slug === slug;
  });
  return found ?? variants[0];
};
