import { colors } from "theme/theme";
import { forwardRef } from "react";
import { Shape } from "components/shape";
import classNames from "classnames";

interface SliderNavButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  className?: string;
  inset?: boolean;
  role: "prev" | "next";
  size?: number;
}

const SliderNavButton = forwardRef<HTMLButtonElement, SliderNavButtonProps>(
  ({ children, className, inset = false, role, size, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={classNames(
          role === "prev" ? "slider__prevButton" : "slider__nextButton",
          className,
        )}
        {...props}
      >
        {children ? (
          children
        ) : (
          <Shape
            size={size}
            variant={role === "prev" ? "caret-left" : "caret-right"}
            fill={inset ? colors.white : colors.tertiary}
          />
        )}
      </button>
    );
  },
);

SliderNavButton.displayName = "SliderNavButton";

export default SliderNavButton;
