import { useLocaleContext } from "contexts/locale-context";
import localeData from "../../locale-data.json";
import { getCountryAndLanguage } from "./utils";

/**
 * Hook to get locale information for current route.
 *
 * @returns object with locale information
 */
export const useLocale = () => {
  const { locale: contextLocale } = useLocaleContext();

  const defaultLocale = localeData.defaultLocale;
  const locale = contextLocale ?? defaultLocale;
  const locales = localeData.locales;
  const [country, language] = getCountryAndLanguage(locale);

  return {
    locale,
    country,
    language,
    defaultLocale,
    locales,
  };
};
