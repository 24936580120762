import components from "components/wrapper/components";

import HeroSlider from "components/slider/hero-slider";
import { ComponentMapperProps, KeyVisual, Slide } from "constants/types";
import { useLocale } from "helpers/locale";
import { FC, useEffect, useState } from "react";
import { Slider } from "types";
import isEmpty from "lodash/isEmpty";
import isArray from "lodash/isArray";

const ComponentMapper: FC<ComponentMapperProps> = ({
  base,
  nav,
  parent,
  page,
  pc,
  components: content,
  downloads,
  id,
  partOfGrid = false,
}) => {
  const { locale, country } = useLocale();
  const [slides, setSlides] = useState<Slide[]>();

  useEffect(() => {
    const slider = page?.slider?.[0] as Slider & {
      slides?: Slide[] & { image: KeyVisual };
    };
    if (slider && "slides" in slider) {
      setSlides(
        slider.slides.map((s) => ({
          ...s,
          image: s.image?.[0] || null,
        })),
      );
    }
  }, [page?.slider]);

  return (
    <>
      {slides && <HeroSlider slides={slides} />}
      {isArray(content) &&
        !isEmpty(content) &&
        content.map((element, index) => {
          // KRN-202 Bugfix for form type, typo in statamic... type is form should be forms..
          const TagName =
            components[
              (element.type === "form" ? "forms" : element.type) || "default"
            ] ?? components.default;
          // Assuming that the order doens't change, therefore using index as key
          return (
            element.enabled === true && (
              <TagName
                {...element}
                key={`${locale}-${country} ${page?.id ?? "page"}-${index}`}
                index={id ? id + "-" + index : index}
                parent={parent}
                page={page}
                pc={pc}
                base={base}
                nav={nav}
                downloads={downloads}
                partOfGrid={partOfGrid}
              />
            )
          );
        })}
    </>
  );
};

export default ComponentMapper;
