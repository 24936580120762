import { trim } from "lodash";
import { unescape as htmlUnescape } from "html-escaper";
import DOMPurify from "isomorphic-dompurify";

/**
 * Decode function to unescape HTML character references.
 *
 * @param text The input string to decode.
 * @return Returns the decoded string where
 * `&amp;` and `&#38;` are replaced by &,
 * `&lt;` and `&#60;` are replaced by <,
 * `&gt;` and `&#62;` are replaced by >,
 * `&apos;` and `&#39;` are replaced by ',
 * `&quot;` and `&#34;` are replaced by ",
 * and `&nbsp;` is replaced by a space.
 */
export const unescape = (text: string) => {
  if (typeof text !== "string") {
    return "";
  }

  text = text.replace("&shy;", "-");

  return htmlUnescape(text).replace(/&nbsp;/g, " ") as string;
};

/**
 * Strip out HTML tags from a string.
 *
 * @param text string with html tags
 * @returns string with html tags removed
 */
export const stripHtmlTags = (text: string) => {
  let decoded = text;
  if (typeof document !== "undefined") {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    decoded = textArea.value;
  }
  return decoded?.replace(/(<([^>]+)>)/gi, " ");
};

/**
 * Sanitize a string with html tags.
 *
 * @param content string with (dangerous) html tags (e.g. <script>)
 * @returns string with only allowed html tags (e.g. <a>)
 */
export const sanitize = (content: string) => {
  // Ersetze &nbsp; durch einen einzigartigen Platzhalter
  const placeholder = "PLACEHOLDER_NON_BREAKING_SPACE";

  if (!content) return content;
  let modifiedContent = content.replace(/&nbsp;/g, placeholder);

  // Wende unescape auf den modifizierten Inhalt an
  modifiedContent = trim(unescape(modifiedContent), '"');
  modifiedContent = modifiedContent.replace(/\\(.)/g, "$1");

  // Sanitize den modifizierten Inhalt
  let sanitizedContent = DOMPurify.sanitize(modifiedContent, {
    ALLOWED_ATTR: ["class", "href", "target", "style"],
    ALLOWED_TAGS: [
      "address",
      "article",
      "aside",
      "footer",
      "header",
      "h1",
      "h2",
      "h3",
      "h4",
      "h5",
      "h6",
      "hgroup",
      "main",
      "nav",
      "section",
      "blockquote",
      "dd",
      "div",
      "dl",
      "dt",
      "figcaption",
      "figure",
      "hr",
      "li",
      "main",
      "ol",
      "p",
      "pre",
      "ul",
      "a",
      "abbr",
      "b",
      "bdi",
      "bdo",
      "br",
      "cite",
      "code",
      "data",
      "dfn",
      "em",
      "i",
      "kbd",
      "mark",
      "q",
      "rb",
      "rp",
      "rt",
      "rtc",
      "ruby",
      "s",
      "samp",
      "small",
      "span",
      "strong",
      "sub",
      "sup",
      "time",
      "u",
      "var",
      "wbr",
      "caption",
      "col",
      "colgroup",
      "table",
      "tbody",
      "td",
      "tfoot",
      "th",
      "thead",
      "tr",
      "button",
    ],
    KEEP_CONTENT: true,
    RETURN_DOM: false,
  });

  // Ersetze den Platzhalter durch &nbsp; im sanierten Inhalt
  sanitizedContent = sanitizedContent.replace(
    new RegExp(placeholder, "g"),
    "&nbsp;",
  );

  return sanitizedContent;
};

/**
 * Makes sure the passed string is a paragraph (or multiple paragraphs) html.
 *
 * @param text String which might contain a html paragraph or plain text (possibly with \n)
 * @returns html string with one or multiple paragraphs (already sanitized). If the passed string starts with a paragraph tag the text will be returned,
 * otherwise it will split the plain text on \n and convert each segment into a paragraph html
 *
 * @example
 * makeParagraph("<p>Hello World<script>alert('evil');</script></p>") === "<p>Hello World</p>";
 * makeParagraph("First part\nSecond one") === "<p>First part</p><p>Second one</p>";
 */
export const makeParagraph = (text: string) => {
  if (typeof text !== "string" || !text) return "";
  text = sanitize(text);
  if (text?.match(/^<[a-zA-Z]+(>|.*?[^?]>)/)) return text;
  else
    return text
      .split(/\n/g)
      .map((subtext) => `<p>${subtext}</p>`)
      .join("");
};

export { default as getSpeakingId } from "./speakingId";
