import { StickyItemProps } from "constants/types";
import { Shape } from "components/shape";
import React, { FC, useState, useEffect, ReactElement } from "react";
import classNames from "classnames";

export const StickyItem: FC<StickyItemProps> = ({
  icon,
  children,
  action,
  hidden,
  text,
  iconSize = 18,
}) => {
  const [hover, setHover] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    if (typeof document !== "undefined") {
      // Get width of text to achieve smooth and consistent transitions on width
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = "14px var(--din-web-pro-font)";
      const metrics = context.measureText(text);
      setWidth(metrics.width);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={classNames(
        "sticky-conversion-item",
        `conversion-${icon}`,
        hidden && "d-none",
      )}
      onClick={() => action && action()}
      onMouseEnter={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{ width: `${(hover && width ? width + 20 : 0) + 50}px` }}
    >
      <div className="sticky-conversation-content">
        <Shape variant={icon} fill="#455C6E" size={iconSize} />
        <span className="sticky-conversion-item-text">{text}</span>
      </div>
      {children}
    </div>
  );
};

interface StickyConversionProps {
  children?: ReactElement;
}

export const StickyConversion: FC<StickyConversionProps> = ({ children }) => {
  return <div className="sticky-conversion">{children}</div>;
};
