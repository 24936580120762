import { sanitize } from "helpers/text-processing";
import { FunctionComponent, HTMLAttributes } from "react";
import Form from "react-bootstrap/Form";
import { CheckboxProps } from "../../../constants/types";
import { colors } from "../../../theme/theme";

export const Checkbox: FunctionComponent<
  CheckboxProps & HTMLAttributes<HTMLDivElement>
> = ({
  id = `input-${Math.random().toString(36).substring(7)}`,
  label,
  supportLabel,
  type = "checkbox",
  fill = colors.blue,
  checked,
  onChange,
  className,
}) => {
  const size = type === "checkbox" ? 12 : 10;
  return (
    <Form.Check type={type} id={id} className={className} key={id}>
      <Form.Check.Input onChange={onChange} checked={checked} />
      <div
        className={`form-check-custom ${type} ${checked ? "checked" : ""}`}
        style={{
          ...(checked && {
            borderColor: fill,
          }),
        }}
        onClick={onChange}
      >
        {checked && (
          <div
            className={`svg`}
            style={{ display: "grid", placeItems: "center" }}
          >
            <svg
              fill={fill || "#E5EFF6"}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 18 18"
              style={{
                width: size,
                height: size,
              }}
            >
              {type === "checkbox" ? (
                <path d="M6 15.4L.3 9.7l1.4-1.4L6 12.6 16.3 2.3l1.4 1.4z" />
              ) : (
                <path d="M0 0h18v18H0z" data-name="Ebene 1" />
              )}
            </svg>
          </div>
        )}
      </div>
      <Form.Check.Label>
        <span
          className="form-check-option-label"
          dangerouslySetInnerHTML={{ __html: sanitize(label) }}
        ></span>
        {supportLabel && (
          <span className="form-check-label-support">{supportLabel}</span>
        )}
      </Form.Check.Label>
    </Form.Check>
  );
};
