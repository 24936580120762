import { getPathFromSlug } from "../data/utils";

/**
 * Checks if a given slug is a landingpage.
 *
 * @param slug as string, e. g. "landingpages/level-application-selector", or string array, e. g. ["landingpages", "level-application-selector"]
 * @returns true if slug is a landingpage (format `/landingpages/${landingpageName}`), else false
 * @example
 * const slugPath = useSlugPath();
 * isLandingpage(slugPath);
 */
export const isLandingpage = (slug: string | string[]) => {
  const slugPath = getPathFromSlug(slug);
  const landingpagePathFormat = /^\/?landingpages\/.*/;
  return landingpagePathFormat.test(slugPath?.toLocaleLowerCase() ?? "");
};

/**
 * Gets the landingpage name from a given slug, which should be the subdomain under which the landingpage is reachable.
 *
 * @param slug as string, e. g. "landingpages/level-application-selector/applications", or string array, e. g. ["landingpages", "level-application-selector", "applications"]
 * @returns the name of the landingpage, e. g. "level-application-selector", or undefined if slug is not a landingpage
 */
export const getLandingpageName = (slug: string | string[]) => {
  const slugPath = getPathFromSlug(slug);

  if (!isLandingpage(slugPath)) return;

  return slugPath?.match(/\/?landingpages\/([\w-]*)/)?.[1];
};

/**
 * Gets the path under the landingpage slug from a given complete slug. This is the path which is used to reach the page under the landingpage subdomain.
 *
 * @param slug as string, e. g. "landingpages/level-application-selector/applications", or string array, e. g. ["landingpages", "level-application-selector", "applications"]
 * @returns the slug under the landingpage slug, e. g. "applications", or undefined if slug is not a landingpage
 */
export const getLandingpageSubpath = (slug: string | string[]) => {
  const slugPath = getPathFromSlug(slug);

  if (!isLandingpage(slugPath)) return;

  return slugPath?.replace(/\/?landingpages\/[\w-]*\/?/, "");
};

/**
 * Tests if the url only contains a query string or hash, not a path or whole url.
 *
 * @param url the string to check
 * @returns true if url starts with a query string or hash, else false
 */
export const isQueryOrHash = (url: string) => {
  return url?.startsWith("?") || url?.startsWith("#");
};
