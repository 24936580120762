import { Ws } from "types";
import LanguageCard from "../language-card";

interface LanguageContentProps {
  entities: Ws[];
  globalEntity: Ws;
}

const LanguageContent = ({ entities, globalEntity }: LanguageContentProps) => {
  return (
    <div>
      <div className="country-list">
        {entities?.map((entity, index) => {
          return (
            <div className="country-list__item" key={index}>
              <LanguageCard entity={entity} />
            </div>
          );
        })}
      </div>

      <hr className="mt-1 mb-1" />

      {globalEntity ? (
        <div className="p-1">
          <LanguageCard entity={globalEntity} />
        </div>
      ) : null}
    </div>
  );
};

export default LanguageContent;
