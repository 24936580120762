/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useUser } from "@auth0/nextjs-auth0/client";
import { Button } from "components/button";
import { LoadingSpinner } from "components/loading-spinner";
import { Icon } from "components/shape";
import { Tables } from "components/table";
import { FormProps } from "constants/types";
import { useFormContext } from "contexts/form-context";
import { useUserData } from "contexts/user-data-context";
import { findVariant } from "helpers/filter";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import {
  getCountryAndLanguage,
  getCountryFromCrmCountry,
} from "helpers/locale/utils";
import { makeParagraph, unescape } from "helpers/text-processing";
import { flattenDeep, isEmpty } from "lodash-es";
import getConfig from "next/config";
import { useRouter } from "next/router";
import fetch from "node-fetch";
import { FC, useCallback, useEffect, useRef, useState } from "react";

function setRequestedEntity(formioForm, value, data) {
  const { components } = formioForm.components.find(
    ({ component }) => component.key === "requestedEntity",
  ) ?? [null];
  if (components?.[0]) {
    components[0]?.components[0].setValue(value);
    components[1]?.components[0].setValue(data);
  }
}

export const FormWrapper: FC<FormProps> = ({
  formName = "no-form",
  type,
  id = "",
  title,
  description,
  customCt,
  modal = false,
  successPage,
  additionalContact,
}) => {
  const { entity, forms, formCt } = useFormContext();

  const [formData, setFormData] = useState<any>();
  const [form, setForm] = useState(null);
  const [formularName, setFormularName] = useState<string>(
    formName.replace(/_/g, "-"),
  );
  const [formularTitle, setFormularTitle] = useState<string>(title);

  const [formularDescription, setFormularDescription] =
    useState<string>(description);
  const [formularSuccessScript, setFormularSuccessScript] =
    useState<string>("");

  const formularType = useRef(
    type ?? forms?.[formularName.replace(/-/g, "_")]?.[0]?.id ?? "fm-1",
  );
  const [submitted, setSubmitted] = useState(false);
  const [resultTable, setResultTable] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const { query, push } = useRouter();
  const { language, country, locale } = useLocale();
  const formWrapperRef = useRef(null);
  const { publicRuntimeConfig } = getConfig();
  const { user } = useUser();
  const { data: userData } = useUserData();
  const [searchInput] = useLabels(["ui-887", "Type to search"]);
  const slug = (query?.variant ??
    query?.slug?.[query?.slug.length - 1]) as string;
  const formioFormRef = useRef<any>();
  type FormUserData = { [key: string]: string };
  const [formUserData, setFormUserData] = useState<FormUserData>();

  const getForm = async () => {
    if (formularName !== "no-form") {
      try {
        console.log(String(publicRuntimeConfig.FORM_SERVICE_URL));
        const response = await fetch(
          `${String(publicRuntimeConfig.FORM_SERVICE_URL)}/api/form`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              formEntity: formularType.current,
              form: formularName,
              ...(query.q ? { quoteFeedback: query.q } : {}),
              localisation: {
                preferred_locale: language,
                fallback_locale: "en",
                sort_local_language: "NULL",
                country: country,
              },
            }),
          },
        );
        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        const formDataTemp = await response.json();
        if (additionalContact) {
          formDataTemp.components.forEach((component) => {
            if (component.key === "additionalReceiver") {
              component.defaultValue = additionalContact;
            }
          });
        }

        if (formDataTemp.components[0]?.key === "ressourceId") {
          if (customCt) formDataTemp.components[0].defaultValue = customCt;
          else if (formCt) formDataTemp.components[0].defaultValue = formCt;
        } else {
          formDataTemp.components[0].defaultValue = "";
        }
        setFormData(formDataTemp);
        setFormularTitle((formularTitle) => {
          // don't overwrite title if it was already set
          if (formularTitle) {
            return formularTitle;
          }

          return formDataTemp.title;
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    }
  };

  const getFmData = async () => {
    if (formularType.current && !forms?.[formularName]) {
      const requestFm = async () => {
        let results;
        try {
          const response = await fetch(`/api/fm`, {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({
              ids: ["id", formularType.current],
              locale: locale,
            }),
          });

          results = await response.json();
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        if (!results) {
          // wait 1 second and try again
          await new Promise((resolve) => setTimeout(resolve, 1000));
          return await requestFm();
        }

        return results;
      };

      const results = await requestFm();

      if (results?.fm?.[0]) {
        const formEntity: {
          excerpt: string;
          family: [{ code: string; label: string }];
          success_script: string;
          title: string;
        } = results?.fm?.[0];

        setFormularTitle(formEntity.title);
        !description && setFormularDescription(formEntity.excerpt);
        setFormularSuccessScript(formEntity.success_script);
        setFormularName(formEntity.family?.[0].code.replace(/_/g, "-"));
      }
    } else {
      if (formularTitle === undefined) {
        setFormularTitle(forms?.[formularName]?.[0]?.title as string);
      }
      if (formularDescription === undefined || !description) {
        setFormularDescription(forms?.[formularName]?.[0]?.excerpt as string);
      }
      if (forms?.[formularName]?.[0]?.success_script) {
        setFormularSuccessScript(
          forms?.[formularName][0].success_script as string,
        );
      }
      setFormularName(formName.replace(/_/g, "-"));
    }
    void getForm();
  };

  function getButtonIcon(label: string) {
    if (undefined === label) return "";
    return label.substr(
      label.indexOf("#RPLINLSVG-") + 11,
      label.substr(label.indexOf("#RPLINLSVG-") + 11).indexOf("#"),
    );
  }
  function getButtonLabel(label: string) {
    if (undefined === label) return "";
    return label
      .substr(label.indexOf("#RPLINLSVG-") + 11)
      .substr(label.substr(label.indexOf("#RPLINLSVG-") + 11).indexOf("#") + 2);
  }

  const replacePlaceholders = (scriptText, replacements) => {
    let modifiedScriptText = scriptText;
    for (const key in replacements) {
      if (replacements.hasOwnProperty(key)) {
        const placeholder = `<<${key.toUpperCase()}>>`;
        modifiedScriptText = modifiedScriptText.replace(
          new RegExp(placeholder, "g"),
          replacements[key],
        );
      }
    }
    return modifiedScriptText;
  };

  const successScript = (replacements) => {
    // Remove script tag from success_script string
    const code = formularSuccessScript.replace(/(<\/script>|<script.*>)/g, "");
    if (code) {
      // KRN-1022 replace success_script with placeholder
      // different language different formData keys...
      // Add code via script element
      const script = document.createElement("script");
      const modifiedScriptText = replacePlaceholders(code, replacements);
      script.textContent = modifiedScriptText;
      document.head.appendChild(script);
    }
  };

  const prefillUserData = useCallback(
    (nestedComponents: any[]) => {
      if (!user) {
        return;
      }

      const newFormUserData = userData
        ? {
          firstname: userData?.contacts?.[0]?.firstName,
          lastname: userData?.contacts?.[0]?.lastName,
          company: userData?.businessPartnerName,
          phone: userData?.contacts?.[0]?.phone,
          addressStreetNo2: userData?.contacts?.[0]?.address?.address1,
          postcode: userData?.contacts?.[0]?.address?.postCode,
          city: userData?.contacts?.[0]?.address?.city,
          country2: getCountryFromCrmCountry(
            userData?.contacts?.[0]?.address?.countryIso,
          ),
          email: userData?.contacts?.[0]?.email,
        }
        : {
          lastname: user.nickname || user.name,
          email: user.email,
        };

      setFormUserData(newFormUserData);

      Object.keys(newFormUserData).forEach((key) => {
        nestedComponents
          .find((component) => component.component.key === key)
          ?.setValue(newFormUserData[key]);
      });
    },
    [user, userData],
  );

  const updateRequestProfileUpdateChangedFields = (nestedComponents: any[]) => {
    if (formName !== "fm-request-profile-update" || !user || !formUserData) {
      return;
    }

    const changedFields = Object.keys(formUserData).filter((key) => {
      const component = nestedComponents.find(
        (component) => component.component.key === key,
      );

      return component?.getValue() !== formUserData[key];
    });

    const fieldNames = {
      firstname: "Firstname",
      lastname: "Lastname",
      email: "Email",
      phone: "Phone",
      company: "Company",
      country2: "Country",
      city: "City",
      postcode: "Postcode",
      addressStreetNo2: "Street",
    };

    nestedComponents
      .find((component) => component.component.key === "changedFields")
      ?.setValue(
        changedFields
          .map((changedFieldName) => fieldNames[changedFieldName])
          .join(", "),
      );
  };

  useEffect(() => {
    if (!formioFormRef.current) return;

    const getNestedComponents = (components) => {
      return components.map((component) => {
        if (component?.components) {
          return getNestedComponents(component?.components);
        } else return component;
      });
    };
    // Get all formio components
    const nestedComponents: any[] = flattenDeep(
      getNestedComponents(formioFormRef.current.components),
    );

    prefillUserData(nestedComponents);
  }, [prefillUserData, userData]);

  const prepareForm = (
    formioForm = { components: [], on: (_a: any, _b: any) => { } },
  ) => {
    // Function to recursively get formio components
    const getNestedComponents = (components) => {
      return components.map((component) => {
        if (component?.components) {
          return getNestedComponents(component?.components);
        } else return component;
      });
    };
    // Get all formio components
    const nestedComponents: any[] = flattenDeep(
      getNestedComponents(formioForm.components),
    );

    // Add maxLength from validation to textfield, email and textarea input elements
    nestedComponents
      .filter((component) =>
        ["textfield", "email", "textarea"].includes(component.type),
      )
      .forEach((textfield) => {
        const maxLength =
          textfield.component?.validate?.maxLength !== "" &&
            textfield.component?.validate?.maxLength !== 0
            ? textfield.component?.validate?.maxLength
            : textfield.component?.maxLength;
        if (maxLength) {
          textfield.inputs.forEach((input) =>
            input.setAttribute("maxlength", maxLength),
          );
        }
      });

    // Focus the flatpickr element when opening the datepicker to prevent the need for a second click
    Array.from(document.querySelectorAll(".formio-component-dateTime")).forEach(
      (element) =>
        element.addEventListener("click", () => {
          const element: HTMLDivElement =
            document.querySelector(".flatpickr-days");
          element.focus();
        }),
    );

    // Make select component clickable
    nestedComponents
      .filter((component) => component.type === "select")
      .forEach((select) => {
        if (select.focusableElement) {
          if (!select.focusableElement?.classList.contains("choices__input")) {
            select.focusableElement.addEventListener(
              "click",
              // Don't really know why there is a difference between these. But somehow this works
              id
                ? () => select.choices.toggleDropdown
                : () => {
                  select.choices.toggleDropdown();
                },
            );
          } else {
            select.focusableElement.previousElementSibling.addEventListener(
              "click",
              () => {
                select.choices.hideDropdown(true);
              },
            );
          }
        }
      });

    prefillUserData(nestedComponents);

    // Prefill requested entity input with product
    if (
      ["kp"].includes(
        // @ts-ignore
        entity?.type ?? "",
      )
    ) {
      const activeEntity = findVariant(
        // @ts-ignore
        [entity, ...entity[`${entity?.type as string}_variants`]],
        slug,
      );

      setRequestedEntity(
        formioForm,
        unescape(activeEntity.label),
        `(${entity?.id} ${locale})`,
      );
    } else if (
      ["so", "sv", "news", "job", "ae", "dp", "technology"].includes(
        // @ts-ignore
        entity?.type ?? "",
      )
    ) {
      setRequestedEntity(
        formioForm,
        unescape(entity.title ? entity.title : entity.label),
        `(${entity?.id} ${locale})`,
      );
    } else if (entity?.title) {
      setRequestedEntity(
        formioForm,
        unescape(entity?.title),
        `(${entity?.id} ${locale})`,
      );
    } else {
      setRequestedEntity(
        formioForm,
        `(${locale})`,
        `(${entity?.id} ${locale})`,
      );
    }

    // set current country from locale as default country on any form
    const [country] = getCountryAndLanguage(locale);

    const countryComponent = nestedComponents.find((component) => {
      const countryRegex = /country/i;
      const countryIsPartOfComponentKey = countryRegex.test(
        component.component.key,
      );

      return countryIsPartOfComponentKey;
    });

    const countryOptions = countryComponent?.originalComponent?.data?.values;
    if (
      country &&
      country !== "k" &&
      countryComponent &&
      countryOptions.some((option) => option.value === country)
    ) {
      countryComponent.setValue(getCountryFromCrmCountry(country));
    }

    // prefill email field for newsletter
    if (formularName === "fm-newsletter") {
      const node = document.getElementById(
        "newsletter-email",
      ) as HTMLInputElement;
      nestedComponents
        .find((component) => component.component.key === "email")
        ?.setValue(node?.value ?? "");
    }

    // prefill fields from url query (prefix "form_")
    Object.entries(query).forEach(([key, value]) => {
      if (!key.startsWith("form_")) return;

      const component = nestedComponents.find(
        (component) => component.component.key === key.replace("form_", ""),
      );

      if (!component) return;

      component.setValue(value);
    });

    // decode html strings in form
    document
      .querySelectorAll(".formio-component .radio span")
      .forEach((comment) => (comment.innerHTML = unescape(comment.innerHTML)));
    document
      .querySelectorAll(".formio-component-selectboxes span")
      .forEach((check) => {
        check.innerHTML = unescape(check.innerHTML);
        check.innerHTML = unescape(check.innerHTML);
      });

    // Get news category component
    const newsletterCategory = formioForm?.components.find(
      ({ key }) => key === "newsletterCategory",
    );

    // Replace html strings with html elements
    formioForm.on("change", (event) => {
      updateRequestProfileUpdateChangedFields(nestedComponents);

      document
        .getElementById("formio" + id)
        ?.querySelectorAll(".form-check label > span, .help-block")
        ?.forEach((label) => {
          const template = document.createElement("template");
          template.innerHTML = unescape(unescape(label.innerHTML.trim()));
          label.innerHTML = "";
          label.append(template.content);
        });

      // Automatically toggle all newsletter category checkboxes when all_news checkbox is clicked
      if (event?.changed) {
        const { component, value } = event?.changed ?? {};
        if (component?.key === "all_news" && newsletterCategory) {
          newsletterCategory.components.forEach((c) => c.setValue(value));
        }
      }
    });

    setForm(formioForm);

    formioForm.on("submitDone", async (response) => {
      const replacements = {
        country: formioForm?.data[countryComponent.component.label],
      };

      // Überprüfen Sie, ob es sich um einen Fehler handelt
      if (response?.errorMessage) {
        // Extrahieren Sie den Fehler-Titel aus der Antwort
        const errorMessage =
          response.errorMessage || "Unbekannter Serverfehler";
        // Hier können Sie die Fehlermeldung anzeigen, z.B. in einem Zustand speichern oder ein Alert anzeigen
        console.error("Form Submit Error:", errorMessage);
        // Hier können Sie beispielsweise den Zustand aktualisieren, um die Fehlermeldung in der Benutzeroberfläche anzuzeigen
        setErrorMessage(errorMessage);
        setSubmitted(true);
      } else if (!response?.redirect && !successPage) {
        setSubmitted(true);
        successScript(replacements);
      } else {
        if (response?.redirect) {
          successScript(replacements);
          setTimeout(() => {
            window.open(response.redirect as string, "_self");
          }, 300);
        }

        if (successPage) {
          const relativeUrlRegex =
            /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim;
          let relativeUrl = successPage.replace(relativeUrlRegex, "");

          if (relativeUrl === "") relativeUrl = "/";
          if (relativeUrl) {
            // Wait for the redirect to finish
            await push(relativeUrl);
            successScript(replacements);
          }
        }
      }
    });
  };

  const initializeForm = async () => {
    const findComponentByType = (components: any[], type: string) => {
      let foundComponent;
      components.forEach((component) => {
        if (foundComponent) return;

        if (component.type === type) {
          foundComponent = component;
        } else if (Array.isArray(component?.components)) {
          foundComponent = findComponentByType(component.components, type);
        }
      });
      return foundComponent;
    };

    const filePlaceholder =
      findComponentByType(formData.components, "file")?.placeholder ?? "";

    const filePlaceholderPureText = filePlaceholder
      .split(" ")
      .slice(0, -1)
      .join(" ");
    const filePlaceholderLink = filePlaceholder.split(" ").slice(-1).join(" ");

    // Import formio here since it needs to be run in the browser
    const { Formio } = await import("formiojs");
    // After that initialize form and wait for it to be ready
    const formioForm = await Formio.createForm(
      document.getElementById("formio" + id),
      formData,
      {
        renderMode: "form",
        i18n: {
          [language]: {
            ...(filePlaceholder && {
              "Drop files to attach, or": filePlaceholderPureText,
              browse: filePlaceholderLink,
            }),
            "Type to search": searchInput.label,
          },
        },
      },
    );
    formioFormRef.current = formioForm;

    formioForm.language = language;

    await formioForm.formReady;
    prepareForm(formioForm);
  };

  useEffect(() => {
    void getFmData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formularName]);

  useEffect(() => {
    if (formularName === formData?.path) {
      void initializeForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  useEffect(() => {
    if (submitted) {
      document.querySelector(".contact-tabs")?.scrollIntoView();
      setResultTable([]);
      for (const [label, value] of Object.entries(form?.submission?.data)) {
        if (
          label !== "ressourceId" &&
          label !== "globalReference" &&
          label !== "_custom" &&
          label !== "addfilefordocumentation" &&
          label !== "addfilefordocumentation2" &&
          label !== "addfilefordocumentation3" &&
          value
        ) {
          if (typeof value === "object") {
            Object.entries(value).map(([innerLabel, innerValue]) => {
              if (Array.isArray(innerValue) && innerValue?.[0]?.url)
                setResultTable((prevResultTable) => [
                  ...prevResultTable,
                  {
                    label: innerLabel,
                    value: innerValue?.[0]?.url,
                  },
                ]);
              else if (!isEmpty(innerValue))
                setResultTable((prevResultTable) => [
                  ...prevResultTable,
                  {
                    label: innerLabel,
                    value: `${(innerValue as string) ?? ""}`,
                  },
                ]);
              return null;
            });
          } else {
            if (!isEmpty(value)) {
              setResultTable((prevResultTable) => [
                ...prevResultTable,
                { label, value: `${(value as string) ?? ""}` },
              ]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  return formData ? (
    <div ref={formWrapperRef}>
      {!submitted ? (
        <div key="the-form">
          {formularTitle && <h3 className="h4">{formularTitle}</h3>}
          {formularDescription && (
            <div
              dangerouslySetInnerHTML={{
                __html: makeParagraph(formularDescription),
              }}
            />
          )}
          <div id={"formio" + id} className="mt-3"></div>
          <div className="formio-control-buttons">
            {!disableButton ? (
              <>
                <Button
                  variant="primary"
                  label={getButtonLabel(
                    // @ts-ignore
                    formData.components[formData.components.length - 1]
                      ?.columns?.[0]?.components?.[0]?.label,
                  )}
                  icon={
                    getButtonIcon(
                      // @ts-ignore
                      formData.components[formData.components.length - 1]
                        ?.columns?.[0]?.components?.[0]?.label,
                    ) as Icon
                  }
                  // @ts-ignore
                  onClick={async () => {
                    setDisableButton(true);
                    try {
                      await form?.submit();
                      const formWrapperTop =
                        Number(
                          formWrapperRef.current.getBoundingClientRect().top,
                        ) +
                        window.pageYOffset -
                        128 ?? 0;
                      setTimeout(() =>
                        window.scrollTo({
                          top: formWrapperTop,
                          behavior: "smooth",
                        }),
                      );
                      setDisableButton(false);
                    } catch (err) {
                      setDisableButton(false);
                      document
                        .getElementById("formio" + id)
                        .querySelectorAll(".help-block")
                        .forEach((helpMessage) => {
                          const template = document.createElement("template");
                          template.innerHTML = unescape(
                            unescape(helpMessage.innerHTML.toString().trim()),
                          );
                          helpMessage.innerHTML = "";
                          helpMessage.append(template.content);
                        });
                      const firstErrorPosition =
                        document
                          .getElementsByClassName("has-error")[0]
                          .getBoundingClientRect().top +
                        window.pageYOffset -
                        108;
                      if (window.scrollY > firstErrorPosition) {
                        window.scrollTo({
                          top: firstErrorPosition,
                          behavior: "smooth",
                        });
                      }
                    }
                  }}
                />
                <Button
                  variant="tertiary"
                  label={getButtonLabel(
                    // @ts-ignore
                    formData.components[formData.components.length - 1]
                      ?.columns?.[1]?.components?.[0]?.label,
                  )}
                  icon={
                    getButtonIcon(
                      // @ts-ignore
                      formData.components[formData.components.length - 1]
                        ?.columns?.[1]?.components?.[0]?.label,
                    ) as Icon
                  }
                  onClick={() => {
                    form.setSubmission({
                      data: {},
                    });
                    form.redraw();
                    prepareForm(form);
                  }}
                />
              </>
            ) : (
              <LoadingSpinner size={36} />
            )}
          </div>
        </div>
      ) : (
        <div key="the-result">
          <Tables
            title={form?.submission?.title}
            intro={form?.submission?.message}
            striped={true}
            className="form-result"
            rows={resultTable.filter(
              (row) => !row.label.endsWith("{{Entity}}"),
            )}
          />
          {errorMessage && (
            <p className="has-error bg-danger">{errorMessage}</p>
          )}
          {!modal && (
            <Button
              variant="tertiary"
              label="Send New"
              icon="step-backward"
              onClick={() => {
                location.reload();
              }}
            />
          )}
        </div>
      )}
    </div>
  ) : (
    <div className="w-100 d-flex justify-content-center py-3">
      <LoadingSpinner />
    </div>
  );
};
