import { ProcessedFilter } from "constants/types";
import { useContentsetContext } from "contexts/contentset-context";
import { createUniqueID } from "helpers/contentset/functions";
import { shameScroll } from "helpers/shame-scroll";
import { FC, Fragment, MouseEvent } from "react";
import FilterButton from "../filter-button";

const filterButtonClickHandler = (
  setModalState: (state: boolean) => void,
  setModalTarget: (filterId: string) => void,
  filterId: string,
) => {
  setModalState(true);
  setModalTarget(filterId);
  shameScroll({ open: true });
};

interface FilterButtonGroupProps {
  filters: ProcessedFilter[];
  level?: number;
  onClick?: (event: MouseEvent, filterId: string) => void;
  path?: string;
  selectedOptions: object;
}

const FilterButtonGroup: FC<FilterButtonGroupProps> = ({
  filters,
  level = 0,
  onClick,
  selectedOptions,
  path = "",
}) => {
  const { setModalState, setModalTarget } = useContentsetContext();

  return (
    <>
      {filters.map((filter) => {
        const options = filter.options
          .map((option) => {
            const uniqueID = createUniqueID(option, filter);
            return { uniqueID, ...option };
          })
          .filter((option) => {
            const previewString = option.preview?.state?.[path]?.visibility;
            return Boolean(previewString) && previewString !== "hide";
          });

        if (!options.length && filter.filter_type !== "single-value-dropdown") {
          return null;
        }

        const selectedChildOptions = options.filter(
          (option) =>
            filter.has_child_filter &&
            option.triggersChildFilter &&
            selectedOptions[option.uniqueID],
        );

        return (
          <Fragment key={`group-${filter.id}`}>
            {/* Current filter */}
            <FilterButton
              filter={filter}
              selectedOptions={selectedOptions}
              onClick={
                onClick ??
                ((_, filterId: string) =>
                  filterButtonClickHandler(
                    setModalState,
                    setModalTarget,
                    filterId ?? filter.id,
                  ))
              }
            />

            {/* SubFilters */}
            {selectedChildOptions
              .map((selectedChildOption) => ({
                uniqueID: selectedChildOption.uniqueID,
                subFilters: filter.child_filter.filter((childFilter) => {
                  return selectedChildOption.filter.includes(childFilter.id);
                }),
              }))
              .map(({ uniqueID, subFilters }) => (
                <FilterButtonGroup
                  filters={subFilters}
                  selectedOptions={selectedOptions[uniqueID]}
                  level={level + 1}
                  onClick={
                    onClick ??
                    ((_, filterId: string) =>
                      filterButtonClickHandler(
                        setModalState,
                        setModalTarget,
                        filterId ?? filter.id,
                      ))
                  }
                  path={`${path}${path ? "." : ""}${uniqueID}`}
                  key={uniqueID}
                />
              ))}
          </Fragment>
        );
      })}
    </>
  );
};

export default FilterButtonGroup;
