import { FormWrapper } from "components/forms/formio";
import { Lightbox } from "components/lightbox";
import { Shape } from "components/shape";
import { Tabs } from "components/tabs";
import { useFormContext } from "contexts/form-context";
import { useLabels, usePrevious } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { useRouter } from "next/router";
import { FC, Fragment, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints, colors } from "theme/theme";
import { Ct, Fm, Ui } from "types";

export const FormModal: FC<{ contact: Ct; tabText: Ui }> = (props) => {
  const { formModal, toggleFormModal, forms } = useFormContext();
  const prevFormModal = usePrevious(formModal);
  const [innerContact, setInnerContact] = useState<Ct>(props.contact);
  const { locale } = useLocale();
  const router = useRouter();
  const [popedState, setPopedState] = useState(false);
  const [formTitle, setformTitle] = useState<string>("");

  const [tabKey, setTabKey] = useState(0);

  const [selectedForm, setSelectedForm] = useState<Fm>(
    formModal && Object.keys(forms).includes(`${formModal}`)
      ? forms[`${formModal}`]?.[0]
      : forms?.fm_contact_general?.[0],
  );

  useEffect(() => {
    if (!(typeof formModal === "boolean")) {
      if (formModal === "direct_contact") {
        setTabKey(1);
      } else {
        setTabKey(0);
      }
    }
    if (formModal && !prevFormModal) {
      window.history.pushState({ formModal: true }, null, null);
      router.beforePopState(() => {
        return false;
      });
      const popStateListener = () => {
        setPopedState(true);
        toggleFormModal(false);
        window?.removeEventListener("popstate", popStateListener);
      };
      window?.addEventListener("popstate", popStateListener);
    }
    if (!formModal && prevFormModal) {
      if (!popedState) {
        router.back();
      }
      setPopedState(false);
    }
    if (formModal && Object.keys(forms).includes(`${formModal}`)) {
      setSelectedForm(forms[`${formModal}`]?.[0]);
    }

    if (typeof formModal === "object") {
      setInnerContact(formModal);
    } else setInnerContact(props.contact);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formModal]);

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.md - 1}px)`,
  });

  const [contactBtn] = useLabels(["ui-313", "Contact"]);

  useEffect(() => {
    if (formModal) {
      const response = fetch(`/api/fm`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          ids: ["id", selectedForm?.id],
          locale: locale,
        }),
      });

      void response
        .then((res) => res.json())
        .then((data) => setformTitle(data?.fm?.[0]?.title as string));
    }
  }, [locale, selectedForm?.id, formModal]);

  return (
    <Lightbox
      heading={{
        title: contactBtn.label,
        level: "h4",
      }}
      open={!!formModal}
      setOpen={toggleFormModal}
      size={isMobile ? "large" : "small"}
    >
      <Tabs
        activeTabIndex={tabKey}
        className="contact-tabs"
        onSelect={(k) => setTabKey(k)}
        tabs={[
          {
            title: formTitle ? formTitle : selectedForm?.title,
            component: (
              <FormWrapper
                formName={selectedForm?.family[0].code}
                title={formTitle ? formTitle : selectedForm?.title}
                description={selectedForm?.excerpt}
                type={selectedForm?.id}
                modal
                customCt={innerContact?.id ?? null}
              />
            ),
          },
          ...(innerContact
            ? [
                {
                  title: props.tabText?.label,
                  component: (
                    <>
                      <div className="service-banner">
                        <div className="service-banner-column">
                          <div className="service-banner-title">
                            {innerContact?.subtitle}
                          </div>
                          <div className="service-banner-content">
                            <span className="medium">
                              {innerContact?.title}
                            </span>
                            <br />
                            {innerContact?.street_address
                              ? innerContact.street_address
                                  .split("\n")
                                  .map((line) => (
                                    <Fragment key={line}>
                                      <span>{line}</span>
                                      <br />
                                    </Fragment>
                                  ))
                              : null}
                          </div>
                        </div>
                        <div className="service-banner-column">
                          <div className="service-banner-title">
                            {contactBtn.label}
                          </div>
                          <div className="service-banner-phone">
                            <Shape
                              variant="phone"
                              size={30}
                              fill={colors.blue}
                            />
                            {innerContact?.phone_1}
                          </div>
                          <p>{innerContact?.opening_hours}</p>
                        </div>
                      </div>
                      <div className="call-wrapper">
                        <a
                          href={"tel:" + innerContact?.phone_1}
                          className="btn btn-primary btn-lg"
                        >
                          <Shape variant="phone" fill={colors.white} />{" "}
                          <div className="label p small">
                            {contactBtn.title}
                          </div>
                        </a>
                      </div>
                    </>
                  ),
                },
              ]
            : []),
        ]}
      />
    </Lightbox>
  );
};
