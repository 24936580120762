/*
  This components was copied from react-windowed-select library
  https://github.com/jacobworrel/react-windowed-select/blob/master/src/MenuList.tsx
  This library at the time had issuses with nextjs
  It's not copied 1:1, some things have been altered for better styling compatibility
*/

import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  ListChildComponentProps,
  VariableSizeList as List,
} from "react-window";
import { OptionProps, GroupTypeBase, OptionTypeBase } from "react-select";

interface Style extends React.CSSProperties {
  top: number;
}

interface ListChildProps extends ListChildComponentProps {
  style: Style;
}

const flattenGroupedChildren = (children) => {
  return children.reduce((result, child) => {
    const {
      props: { children: nestedChildren = [] },
    } = child;

    return [
      ...result,
      React.cloneElement(child, { type: "group" }, []),
      ...nestedChildren,
    ];
  }, []);
};

const isFocused = ({ props: { isFocused } }) => {
  return isFocused === true;
};

const getCurrentIndex = (children) => {
  return Math.max(children.findIndex(isFocused), 0);
};

const createGetHeight = ({
  groupHeadingStyles,
  noOptionsMsgStyles,
  optionStyles,
  loadingMsgStyles,
}) => {
  return function getHeight(child) {
    const {
      props: {
        type,
        children,
        inputValue,
        selectProps: { noOptionsMessage, loadingMessage },
      },
    } = child;

    if (type === "group") {
      const { height = 25 } = groupHeadingStyles;
      return height;
    } else if (type === "option") {
      const { height = 35 } = optionStyles;
      return height;
    } else if (
      typeof noOptionsMessage === "function" &&
      children === noOptionsMessage({ inputValue })
    ) {
      const { height = 35 } = noOptionsMsgStyles;
      return height;
    } else if (
      typeof loadingMessage === "function" &&
      children === loadingMessage({ inputValue })
    ) {
      const { height = 35 } = loadingMsgStyles;
      return height;
    } else {
      return 35;
    }
  };
};

function MenuItem({ data, index, setMeasuredHeight }) {
  const ref = React.useRef<HTMLDivElement>(null);

  // using useLayoutEffect prevents bounciness of options of re-renders
  React.useLayoutEffect(() => {
    if (ref.current) {
      const measuredHeight = ref.current.getBoundingClientRect().height;

      setMeasuredHeight({ index, measuredHeight });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <div key={`option-${index}`} ref={ref}>
      {data}
    </div>
  );
}

function MenuList(props) {
  const children = React.useMemo(() => {
    const children = React.Children.toArray(props.children);

    const head = children[0] || {};

    if (
      React.isValidElement<
        OptionProps<OptionTypeBase, boolean, GroupTypeBase<OptionTypeBase>>
      >(head)
    ) {
      const { props: { data: { options = [] } = {} } = {} } = head;
      const groupedChildrenLength = options.length;
      const isGrouped = groupedChildrenLength > 0;
      const flattenedChildren = isGrouped && flattenGroupedChildren(children);

      return isGrouped ? flattenedChildren : children;
    } else {
      return [];
    }
  }, [props.children]);

  const { getStyles } = props;
  const groupHeadingStyles = getStyles("groupHeading", props);
  const loadingMsgStyles = getStyles("loadingMessage", props);
  const noOptionsMsgStyles = getStyles("noOptionsMessage", props);
  const optionStyles = getStyles("option", props);
  const getHeight = createGetHeight({
    groupHeadingStyles,
    noOptionsMsgStyles,
    optionStyles,
    loadingMsgStyles,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const heights = useMemo(() => children.map(getHeight), [children]);
  const currentIndex = useMemo(() => getCurrentIndex(children), [children]);

  const itemCount = children.length;

  const [measuredHeights, setMeasuredHeights] = useState({});

  // calc menu height
  const { maxHeight } = getStyles("menuList", props);
  const totalHeight = React.useMemo(() => {
    return heights.reduce((sum, height, idx) => {
      if (measuredHeights[idx]) {
        return sum + measuredHeights[idx];
      } else {
        return sum + height;
      }
    }, 0);
  }, [heights, measuredHeights]);
  const totalMenuHeight = totalHeight;
  const menuHeight = Math.min(maxHeight, totalMenuHeight);
  const estimatedItemSize = Math.floor(totalHeight / itemCount);

  const { innerRef, selectProps } = props;

  const { classNamePrefix, isMulti } = selectProps || {};
  const list = useRef<List>(null);

  useEffect(() => {
    setMeasuredHeights({});
  }, [props.children]);

  // method to pass to inner item to set this items outer height
  const setMeasuredHeight = ({ index, measuredHeight }) => {
    if (
      measuredHeights[index] !== undefined &&
      measuredHeights[index] === measuredHeight
    ) {
      return;
    }

    setMeasuredHeights((measuredHeights) => ({
      ...measuredHeights,
      [index]: measuredHeight,
    }));

    // this forces the list to rerender items after the item positions resizing
    if (list.current) {
      list.current.resetAfterIndex(index);
    }
  };

  useEffect(() => {
    if (currentIndex >= 0 && list.current !== null) {
      list.current.scrollToItem(currentIndex);
    }
  }, [currentIndex, children, list]);

  return (
    <List
      className={
        classNamePrefix
          ? `${classNamePrefix}__menu-list${
              isMulti ? ` ${classNamePrefix}__menu-list--is-multi` : ""
            }`
          : ""
      }
      ref={list}
      outerRef={innerRef}
      estimatedItemSize={estimatedItemSize}
      innerElementType={forwardRef(function InnerList(
        // @ts-ignore
        { style, ...rest },
        ref,
      ) {
        return (
          <div
            // @ts-ignore
            ref={ref}
            style={{
              ...style,
              height: `${Math.floor(parseFloat(style.height))}px`,
            }}
            {...rest}
          />
        );
      })}
      height={menuHeight}
      width="100%"
      itemCount={itemCount}
      itemData={children}
      itemSize={(index) => measuredHeights[index] || heights[index]}
    >
      {/* @ts-ignore */}
      {({ data, index, style }: ListChildProps) => {
        return (
          <div
            style={{
              ...style,
              top: `${Math.floor(parseFloat(style.top.toString()))}px`,
            }}
          >
            <MenuItem
              data={data[index]}
              index={index}
              setMeasuredHeight={setMeasuredHeight}
            />
          </div>
        );
      }}
    </List>
  );
}

export default MenuList;
