import { RoutingContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext } from "react";

export const RoutingContext = createContext<RoutingContextProps>(null);

export const RoutingContextProvider: FC<
  RoutingContextProps & { children: ReactNode }
> = ({ slugPath, children }) => {
  return (
    <RoutingContext.Provider
      value={{
        slugPath,
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
};

export const useRoutingContext = () => {
  const context = useContext(RoutingContext);
  if (!context) {
    throw new Error(
      "useRoutingContext must be used within RoutingContextProvider",
    );
  }
  return context;
};
