import classNames from "classnames";
import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { ErrorType } from "constants/types";
import { getGlobalPageProps } from "helpers/data";
import { getFirstParam, parseJson } from "helpers/utils";
import { GetStaticProps } from "next";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useLabels } from "../helpers/hooks";

export default function Custom500({ errors = [] }: { errors: ErrorType[] }) {
  const [button] = useLabels(["ui-782", "500 - Server Error"]);
  const router = useRouter();

  useEffect(() => {
    // Remove some data so the query doen't get too long
    // Otherwise we will get a 431 error: "Request header fields too large"
    errors.forEach((error) => {
      delete error.extensions;
      delete error.path;
      delete error.trace;
      delete error.locations;
    });
  }, [errors]);

  const queryErros =
    parseJson(decodeURIComponent((router?.query?.errors as string) || "")) ||
    [];

  const errorArray: ErrorType[] = [...errors, ...queryErros].filter(Boolean);

  useEffect(() => {
    if (errorArray.length) {
      errorArray.forEach((error) => {
        if (error?.message) {
          // eslint-disable-next-line no-console
          console.error(error?.message);
        }
      });
    }
  }, [errorArray]);

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Container>
        <Row>
          <Col
            xs={{ offset: 3, span: 6 }}
            sm={{ offset: 2, span: 8 }}
            lg={{ offset: 4, span: 4 }}
          >
            <Cloudinary
              media={{
                digital_asset_id: "im-photo-composition/mfc-400-500-error",
              }}
            />
          </Col>
        </Row>
        <h1 className="title mb-4 text-center">{button.title}</h1>
        <p className="mb-5 text-center">{button.label}</p>
        <>
          {errorArray.map((error, index) => {
            return (
              <div
                className={classNames(
                  "mb-3",
                  index < errorArray.length - 1 && "border-bottom",
                )}
                key={index}
              >
                {error?.name && <pre>{error.name}</pre>}
                {error?.message && <pre>{error.message}</pre>}
                {error?.debugMessage && <pre>{error.debugMessage}</pre>}
              </div>
            );
          })}
        </>
      </Container>
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const locale = getFirstParam(params?.locale) ?? "k_en";
  const globalProps = await getGlobalPageProps(locale);

  return {
    props: globalProps,
    revalidate: +(process.env.PAGE_ERROR_CACHE_VALIDITY ?? 30), // 30 seconds as fallback
  };
};
