import classNames from "classnames";
import { Shape } from "components/shape";
import { ProcessedFilter } from "constants/types";
import { FC, MouseEvent } from "react";
import { colors } from "theme/theme";

interface FilterButtonProps {
  filter: ProcessedFilter;
  onClick?: (event: MouseEvent, filterId?: string) => void;
  selectedOptions?: object;
}

const FilterButton: FC<FilterButtonProps> = ({
  filter,
  selectedOptions,
  onClick,
}) => {
  const isSelected = Object.keys(selectedOptions).some((entry) => {
    const item = entry.split("§");

    return (
      item[0] === filter[`filter_${filter.source_type}`] &&
      item[2] === filter.source_type
    );
  });

  return (
    <div
      key={filter.id}
      className={classNames(
        "filter-button",
        filter.filter_type,
        isSelected && "selected",
      )}
      onClick={(e) => onClick(e, filter.id)}
      title={filter.label}
    >
      <span
        className="filter-button-label"
        dangerouslySetInnerHTML={{ __html: filter.label }}
      />
      <div className="afterIcon">
        <Shape fill={colors.gray60} variant={"caret-down-small"} />
      </div>
    </div>
  );
};

export default FilterButton;
