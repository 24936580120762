/**
 * Checks if the text has Cyrillic or Diacritic letters
 *
 * @param text slug or title of element
 * @returns boolean
 * @example
 * hasCyrillicOrDiacritic("Пример текста") // true
 */
function hasCyrillicOrDiacritic(text: string): boolean {
  // Cyrillic: [\u0400-\u04FF], Diacritic (Czech): [\u0100-\u017F]
  return /[\u0400-\u04FF]|[\u0100-\u017F]/.test(text);
}

type CyrillicAndCzechToLatinMap = { [key: string]: string };

// Mapping for Cyrillic (Russian) chars
const cyrillicToLatinMapRussian: CyrillicAndCzechToLatinMap = {
  А: "A",
  Б: "B",
  В: "V",
  Г: "G",
  Д: "D",
  Е: "E",
  Ё: "Yo",
  Ж: "Zh",
  З: "Z",
  И: "I",
  Й: "Y",
  К: "K",
  Л: "L",
  М: "M",
  Н: "N",
  О: "O",
  П: "P",
  Р: "R",
  С: "S",
  Т: "T",
  У: "U",
  Ф: "F",
  Х: "Kh",
  Ц: "Ts",
  Ч: "Ch",
  Ш: "Sh",
  Щ: "Shch",
  Ъ: "",
  Ы: "Y",
  Ь: "",
  Э: "E",
  Ю: "Yu",
  Я: "Ya",
  а: "a",
  б: "b",
  в: "v",
  г: "g",
  д: "d",
  е: "e",
  ё: "yo",
  ж: "zh",
  з: "z",
  и: "i",
  й: "y",
  к: "k",
  л: "l",
  м: "m",
  н: "n",
  о: "o",
  п: "p",
  р: "r",
  с: "s",
  т: "t",
  у: "u",
  ф: "f",
  х: "kh",
  ц: "ts",
  ч: "ch",
  ш: "sh",
  щ: "shch",
  ъ: "",
  ы: "y",
  ь: "",
  э: "e",
  ю: "yu",
  я: "ya",
};

// Mapping for diacritic (Czech) chars
const czechToLatinMap: CyrillicAndCzechToLatinMap = {
  Á: "A",
  Č: "C",
  Ď: "D",
  É: "E",
  Ě: "E",
  Í: "I",
  Ň: "N",
  Ó: "O",
  Ř: "R",
  Š: "S",
  Ť: "T",
  Ú: "U",
  Ů: "U",
  Ý: "Y",
  Ž: "Z",
  á: "a",
  č: "c",
  ď: "d",
  é: "e",
  ě: "e",
  í: "i",
  ň: "n",
  ó: "o",
  ř: "r",
  š: "s",
  ť: "t",
  ú: "u",
  ů: "u",
  ý: "y",
  ž: "z",
};

// Specific language cases for best possible transliteration
const specificCases: Array<{ from: string; to: string }> = [
  // Russian special cases
  { from: "ые", to: "yye" },
  { from: "ий", to: "iy" },
  { from: "ия", to: "iya" },
  { from: "ие", to: "iye" },
  { from: "йе", to: "ye" },
  { from: "ье", to: "ye" },
  { from: "чье", to: "chye" },
  { from: "чья", to: "chya" },
  { from: "шья", to: "shya" },
  { from: "жья", to: "zhya" },
  { from: "щья", to: "shchya" },
  { from: "галь", to: "gal'" },
  { from: "тель", to: "tel'" },
  { from: "ель", to: "el'" },
  { from: "йе", to: "ye" },
  { from: "йа", to: "ya" },
  // No special cases for Czech chars (for now...)
];

/**
 * Transliterates a string containing Cyrillic characters into Latin characters.
 *
 * @param text - The input string that may contain Cyrillic characters.
 * @returns A string where all Cyrillic characters have been replaced by their Latin equivalents.
 * @example
 * transliterate("Это кириллические буквы") // Eto kirillicheskiye bukvy
 */
function transliterate(text: string): string {
  specificCases.forEach(({ from, to }) => {
    const regex = new RegExp(from, "g");
    text = text.replace(regex, to);
  });

  return text
    .split("")
    .map((char, index, arr) => {
      if (char === "ь") {
        // special case: Remove 'ь' at the beginning or end of a word
        if (index === 0 || index === arr.length - 1 || arr[index - 1] === " ") {
          return "";
        }
        return "-";
      }
      return cyrillicToLatinMapRussian[char] || czechToLatinMap[char] || char;
    })
    .join("");
}

/**
 * Converts a title or slug to a speaking ID.
 *
 * @param content object with at least slug or title
 * @returns a speaking ID string
 * @example
 * getSpeakingId({ title: "My Awesome Talk" }) // my-awesome-talk
 */
const getSpeakingId = ({ slug, title }: { slug?: string; title?: string }) => {
  if (!title && !slug) return;

  if (slug) {
    return slug;
  }

  // Check for cyrillic or diacritic chars and transliterate them to Latin
  if (hasCyrillicOrDiacritic(title)) {
    title = transliterate(title);
  }

  // Replace unwanted characters and format the string
  return title
    .trim() // Remove leading and trailing spaces
    .replace(/[&%@$/!#*()+,."]/g, "-") // Replace special characters with a single dash
    .replace(/\s+/g, "-") // Replace all spaces with a single dash
    .replace(/--+/g, "-") // Replace multiple dashes with a single dash
    .replace(/'+/g, "-") // Replace multiple apostrophes with a single dash
    .toLowerCase();
};

export default getSpeakingId;
