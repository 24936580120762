import { FC } from "react";
import { HeroSliderProps } from "constants/types";
import { Slider } from "..";
import HeroSlide from "./hero-slide";

const HeroSlider: FC<HeroSliderProps> = ({
  indicators = true,
  pagination = true,
  slides,
}) => {
  const colors = slides.map((slide) => slide.color || "white");
  const headlines = slides.map((slide) => slide.short_label || slide.headline);

  return (
    <Slider
      className="slider--hero"
      indicators={indicators ? headlines : undefined}
      colors={colors}
      navigation="inset"
      loop
      pagination={pagination}
      slidesPerView={1}
      autoplay={{
        delay: 6000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
    >
      {slides.map((slide, index) => (
        <HeroSlide
          priority={index === 0}
          {...slide}
          key={slide.id || `slide-${index}`}
        />
      ))}
    </Slider>
  );
};

export default HeroSlider;
