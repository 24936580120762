import { useUser } from "@auth0/nextjs-auth0/client";
import classNames from "classnames";
import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { Heading } from "components/heading";
import Portal from "components/lightbox";
import ForceLocaleLink, { Link } from "components/link";
import { Shape } from "components/shape";
import { GlobalPageProps } from "constants/types";
import { useUserData } from "contexts/user-data-context";
import { useLabels } from "helpers/hooks";
import { shameScroll } from "helpers/shame-scroll";
import { useRouter } from "next/router";
import { FC, useEffect, useRef, useState } from "react";
import { Transition } from "react-transition-group";
import { colors } from "theme/theme";
import { GetMyKrohneDataQuery } from "types";

interface CustomerPortalOverlayProps {
  close?: () => void;
  navigation: GlobalPageProps["header"]["myKrohne"];
  open?: boolean;
}

const CustomerPortalOverlay: FC<CustomerPortalOverlayProps> = ({
  open = false,
  close,
  navigation,
}) => {
  const { events } = useRouter();
  const { user, isLoading } = useUser();
  const { data: userData, isLoading: isUserDataLoading } = useUserData();
  const [myKrohneLabel, signInLabel, signOutLabel] = useLabels(
    ["ui-895", "myKROHNE"],
    ["ui-896", "sign-in to myKROHNE"],
    ["ui-897", "Sign-out"],
  );
  const transitionRef = useRef<HTMLDivElement>(null);
  const [subnavigation, setSubnavigation] = useState<
    GetMyKrohneDataQuery["pc"][number] | null
  >(null);

  useEffect(() => {
    if (open) {
      setSubnavigation(null);
      shameScroll({ open: true });
    } else {
      shameScroll({ close: true });
    }
  }, [open]);

  useEffect(() => {
    const handleRouteChange = () => {
      close?.();
    };

    events.on("routeChangeStart", handleRouteChange);

    return () => {
      events.off("routeChangeStart", handleRouteChange);
    };
  }, [close, events]);

  return (
    <Portal>
      <Transition
        in={open}
        nodeRef={transitionRef}
        mountOnEnter
        unmountOnExit
        appear
        timeout={150}
      >
        {(state) => (
          <div
            className="customer-portal-overlay"
            ref={transitionRef}
            onClick={close}
          >
            <div className="container customer-portal-overlay__wrapper">
              <div
                className={`customer-portal-overlay__window ${state}`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="customer-portal-overlay__window__heading">
                  <Heading
                    title={myKrohneLabel.title}
                    contentRight={
                      <div onClick={close}>
                        <Shape variant="close-big" fill={colors.white} />
                      </div>
                    }
                    level="h4"
                  />
                </div>
                <div className="customer-portal-overlay__window__header">
                  <Cloudinary
                    className="customer-portal-overlay__window__header__background"
                    media={{
                      digital_asset_id: "im-background/grey-triangle-4-3",
                    }}
                  />
                  <div className="customer-portal-overlay__window__header__content">
                    {isLoading ? (
                      <>
                        <div className="customer-portal-overlay__window__header__content__loading-1" />
                        <div className="customer-portal-overlay__window__header__content__loading-2" />
                      </>
                    ) : !user ? (
                      <>
                        <Button
                          variant="primary"
                          icon="external-link"
                          label={signInLabel.label}
                          className="customer-portal-overlay__window__header__content__sign-in-button"
                          as="a"
                          href="/api/auth/login"
                        />
                        <p className="customer-portal-overlay__window__header__content__register-text">
                          {signInLabel.title}
                          <Link
                            href="/api/auth/signup"
                            isAnchor
                            label={signInLabel.short_title}
                            className="customer-portal-overlay__window__header__content__register-link"
                          />
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="customer-portal-overlay__window__header__content__heading">
                          {signOutLabel.title}
                          <span className="customer-portal-overlay__window__header__content__heading__username">
                            {userData?.contacts?.[0]?.firstName
                              ? `${userData?.contacts?.[0]?.firstName}${
                                  userData?.contacts?.[0]?.lastName
                                    ? ` ${userData?.contacts?.[0]?.lastName}`
                                    : ""
                                }`
                              : user.name}
                          </span>
                        </p>
                        <Link
                          href="/api/auth/logout"
                          isAnchor
                          label={signOutLabel.label}
                          className="customer-portal-overlay__window__header__content__sign-out-link"
                        />
                      </>
                    )}
                  </div>
                </div>

                <ul
                  className={classNames(
                    "customer-portal-overlay__window__list",
                    !user && "not-signed-in",
                  )}
                >
                  {!subnavigation ? (
                    <>
                      {navigation
                        ?.sort((a) => (!a.slug ? -1 : 0))
                        .map((item) =>
                          !item.link_absolute_path ? (
                            <li
                              className="customer-portal-overlay__window__list__item-wrapper"
                              key={item.id}
                            >
                              <ForceLocaleLink
                                href={
                                  item.redirect ??
                                  `/mykrohne/${item.slug ?? ""}`
                                }
                                target={item?.external ? "_blank" : undefined}
                                className="customer-portal-overlay__window__list__item"
                              >
                                <span className="customer-portal-overlay__window__list__item__label">
                                  {item.label}
                                </span>
                              </ForceLocaleLink>
                            </li>
                          ) : (
                            <li
                              className="customer-portal-overlay__window__list__item-wrapper"
                              key={item.id}
                            >
                              <div
                                onClick={() => setSubnavigation(item)}
                                className="customer-portal-overlay__window__list__item"
                              >
                                <span className="customer-portal-overlay__window__list__item__label">
                                  Assets
                                </span>
                                <Shape
                                  variant="caret-right"
                                  fill={colors.gray40}
                                />
                              </div>
                            </li>
                          ),
                        )}
                      {/* <li className="customer-portal-overlay__window__list__item-wrapper">
                        <ForceLocaleLink
                          href="/mykrohne/account"
                          className="customer-portal-overlay__window__list__item"
                        >
                          <span className="customer-portal-overlay__window__list__item__label">
                            {signOutLabel.short_title}
                          </span>
                        </ForceLocaleLink>
                      </li> */}
                    </>
                  ) : (
                    <>
                      <li className="customer-portal-overlay__window__list__item-wrapper">
                        <div
                          className="customer-portal-overlay__window__list__item customer-portal-overlay__window__list__item--subnavigation-header"
                          onClick={() => setSubnavigation(null)}
                        >
                          <Shape
                            variant="caret-left"
                            fill={colors.gray40}
                            className="customer-portal-overlay__window__list__item__icon"
                          />
                          <span className="customer-portal-overlay__window__list__item__label">
                            {subnavigation.label}
                          </span>
                        </div>
                      </li>
                      <li className="customer-portal-overlay__window__list__item-wrapper">
                        <ForceLocaleLink
                          href={`/mykrohne/${subnavigation.slug ?? ""}`}
                          className="customer-portal-overlay__window__list__item"
                        >
                          <span className="customer-portal-overlay__window__list__item__label">
                            {subnavigation.title}
                          </span>
                        </ForceLocaleLink>
                      </li>
                      {subnavigation.link_absolute_path && (
                        <li className="customer-portal-overlay__window__list__item-wrapper">
                          <ForceLocaleLink
                            href={subnavigation.link_absolute_path}
                            target="_blank"
                            className="customer-portal-overlay__window__list__item"
                          >
                            <span className="customer-portal-overlay__window__list__item__label">
                              {subnavigation.subtitle}
                            </span>
                          </ForceLocaleLink>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
      </Transition>
    </Portal>
  );
};

export default CustomerPortalOverlay;
