type Term = [string, string];
type FormattedTerm = {
  [operator: string]: ({ [key: string]: string } | FormattedTerm)[];
};
type TermInput = Term | (Term | FormattedTerm)[];

/**
 * Formats terms to be used in GraphQL API variable.
 *
 * @param termInput term or list/object of terms which should be formatted
 * @returns formatted terms which can be used in GraphQL API variable
 *
 * @example
 * formatTerms("enabled", "=1")
 * // [{"enabled": "=1"}]
 *
 * formatTerms(["enabled", "=1"], ["deactivated", "=0"])
 * // [{"enabled": "=1"}, {"deactivated": "=0"}]
 *
 * formatTerms({"&": [{"enabled": "=1"}]}, ["deactivated", "=0"])
 * // [{"&": [{"enabled": "=1"}]}, {"deactivated": "=0"}]
 */
const formatTerms = (...termInput: TermInput) => {
  if (termInput.length === 0) {
    return;
  }

  if (typeof termInput[0] === "string" && termInput.length !== 2) {
    return;
  }

  const isTermTuple =
    typeof termInput[0] === "string" && termInput.length === 2;
  const terms: (Term | FormattedTerm)[] = isTermTuple
    ? [termInput as Term]
    : (termInput as (Term | FormattedTerm)[]);

  return terms.reduce<({ [key: string]: string } | FormattedTerm)[]>(
    (formattedTerms, term) => {
      if (typeof term === "object" && !Array.isArray(term)) {
        return [...formattedTerms, term];
      }

      const [key, value] = term;
      return [
        ...formattedTerms,
        {
          [key]: value,
        },
      ];
    },
    [],
  );
};

/**
 * Combines the terms with the given combiner to be used in GraphQL API variable.
 *
 * @param operator boolean operation, either "&" for AND or "|" for OR
 * @param termInput terms which should be combined
 * @returns object with combined terms
 *
 * @example
 * combine("&", "enabled", "=1")
 * // {"&": [{"enabled": "=1"}]}
 * // meaning: (enabled = 1)
 * // it doesen't matter if "&" or "|" is used with only one term
 *
 * combine("|", ["enabled", "=1"], ["deactivated", "=0"])
 * // {"|": [{"enabled": "=1"}, {"deactivated": "=0"}]}
 * // meaning: (enabled = 1) OR (deactivated = 0)
 *
 * combine("&", { "|": [{server_usage_type: "=live"}, {default_language: "(" }]}, ["deactivated", "=0"])
 * // {"&": [{ "|": [{server_usage_type: "=live"}, {default_language: "(" }]}, {"deactivated": "=0"}]}
 * // meaning: ( (server_usage_type = live) OR (default_language = "(") ) AND (deactivated = 0)
 */
const combine = (operator: "&" | "|", ...termInput: TermInput) => {
  const terms = formatTerms(...termInput);

  if (!terms) {
    return;
  }

  return {
    [operator]: terms,
  };
};

/**
 * Combines the terms with AND operator to be used in GraphQL API variable.
 *
 * @param termInput terms which should be combined with AND operator
 * @returns string representation of the combined terms which can be used in GraphQL API variable
 * @example
 * and() // undefined
 *
 * and("enabled") // undefined
 *
 * and("enabled", "=1")
 * // {"&": [{"enabled": "=1"}]}
 *
 * and(["enabled", "=1"], ["deactivated", "=0"])
 * // {"&": [{"enabled": "=1"}, {"deactivated": "=0"}]}
 *
 * and(
 *  {
 *    "|": [
 *      {server_usage_type: "=live"},
 *      {default_language: "(" }
 *    ]
 *  },
 *  ["deactivated", "=0"]
 * )
 * // {"&": [{ "|": [{server_usage_type: "=live"}, {default_language: "(" }]}, {"deactivated": "=0"}]}
 */
export const and = (...termInput: TermInput) => combine("&", ...termInput);

/**
 * Combines the terms with OR operator to be used in GraphQL API variable.
 *
 * @param termInput terms which should be combined with OR operator
 * @returns string representation of the combined terms which can be used in GraphQL API variable
 * @example
 * or() // undefined
 *
 * or("enabled") // undefined
 *
 * or("enabled", "=1")
 * // {"|": [{"enabled": "=1"}]}
 *
 * or(["enabled", "=1"], ["deactivated", "=0"])
 * // {"|": [{"enabled": "=1"}, {"deactivated": "=0"}]}
 *
 * or(
 *  {
 *    "&": [
 *      {server_usage_type: "=live"},
 *      {default_language: "(" }
 *    ]
 *  },
 *  ["deactivated", "=0"]
 * )
 * // {"|": [{ "&": [{server_usage_type: "=live"}, {default_language: "(" }]}, {"deactivated": "=0"}]}
 */
export const or = (...termInput: TermInput) => combine("|", ...termInput);

/**
 * Combines the terms to be used as sort in GraphQL API variable.
 *
 * @param termInput terms which should be used as sort
 * @returns string representation of the sort which can be used in GraphQL API variable
 * @example
 * sort() // undefined
 * sort("id") // [{"id":"ASC"}]
 * sort("id", "DESC") // [{"id":"DESC"}]
 * sort(["id", "DESC"], ["another", "ASC"]) // [{"id":"DESC"},{"another":"ASC"}]
 */
export const sort = (...termInput: TermInput) => {
  if (termInput.length === 1 && typeof termInput[0] === "string") {
    return formatTerms([termInput[0], "ASC"]);
  }

  return formatTerms(...termInput);
};

type ContextConfig = [string, any] | [string, any][] | [{ [key: string]: any }];

/**
 * Formats the context with default fallbacks to be used in GraphQL API variable.
 *
 * @param contextConfig configuration options for the context
 * @returns a context object which can be used in GraphQL API variable
 * @example
 * formatContext()
 * // { site: 'global', country: 'k', preferred_locale: 'en', fallback_locale: 'en', visibility: 'draft' }
 * formatContext("preferred_locale", "de")
 * // { site: 'global', country: 'k', preferred_locale: 'de', fallback_locale: 'en', visibility: 'draft' }
 * formatContext(["preferred_locale", "de"], ["country", "de"])
 * // { site: 'global', country: 'de', preferred_locale: 'de', fallback_locale: 'en', visibility: 'draft' }
 * formatContext({ preferred_locale: "de", country: "de", site: "de" })
 * // { site: 'de', country: 'de', preferred_locale: 'de', fallback_locale: 'en', visibility: 'draft' }
 */
const formatContext = (...contextConfig: ContextConfig) => {
  const visibility = process.env.APP_ENV === "live" ? "published" : "draft";

  const defaultContext = {
    site: "global",
    country: "k",
    preferred_locale: "en",
    fallback_locale: "en",
    visibility,
  };

  if (contextConfig.length === 0) {
    return defaultContext;
  }

  if (typeof contextConfig[0] === "string" && contextConfig.length !== 2) {
    return defaultContext;
  }

  if (typeof contextConfig[0] === "string" && contextConfig.length === 2) {
    const [key, value] = contextConfig;
    return {
      ...defaultContext,
      [key]: value,
    };
  }

  if (
    typeof contextConfig[0] === "object" &&
    !Array.isArray(contextConfig[0])
  ) {
    return {
      ...defaultContext,
      ...contextConfig[0],
    };
  }

  return (contextConfig as [string, any][]).reduce(
    (context, [key, value]) => ({ ...context, [key]: value }),
    defaultContext,
  );
};

/**
 * Creates a context which can be used in GraphQL API variable.
 *
 * @param contextConfig configuration options for the context
 * @returns stringified context object which can be used in GraphQL API variable
 * @example
 * createContext()
 * // "{ site: 'global', country: 'k', preferred_locale: 'en', fallback_locale: 'en', visibility: 'draft' }"
 * createContext("preferred_locale", "de")
 * // "{ site: 'global', country: 'k', preferred_locale: 'de', fallback_locale: 'en', visibility: 'draft' }"
 * createContext(["preferred_locale", "de"], ["country", "de"])
 * // "{ site: 'global', country: 'de', preferred_locale: 'de', fallback_locale: 'en', visibility: 'draft' }"
 * createContext({ preferred_locale: "de", country: "de", site: "de" })
 * // "{ site: 'de', country: 'de', preferred_locale: 'de', fallback_locale: 'en', visibility: 'draft' }"
 */
export const createContext = (...contextConfig: ContextConfig) =>
  formatContext(...contextConfig);

/**
 * Encodes a variable as string for use in graphQL query.
 *
 * @param variable Any graphQL variable value
 * @returns stringified variable for use in graphQL query
 */
export const encodeVariable = (variable: any) => JSON.stringify(variable);

/**
 * Encodes a variable object so that the values are stringified for use in graphQL query.
 *
 * @param variables Object containing variable name and value
 * @returns Object containing variable name and stringified value
 */
export const encodeVariables = <T extends Record<string, unknown>>(
  variables: T,
) =>
  Object.entries(variables).reduce<{ [K in keyof T]: string }>(
    (variables, [key, value]) => {
      return {
        ...variables,
        [key]: typeof value !== "string" ? JSON.stringify(value) : value,
      };
    },
    {} as { [K in keyof T]: string },
  );
