import Languages from "components/languages";
import { Lightbox } from "components/lightbox";
import { useLocaleContext } from "contexts/locale-context";
import { useLabels } from "helpers/hooks";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints } from "theme/theme";

import { LocaleSwitcherProps } from "constants/types";

export const languageLabel = {
  en: "English",
  de: "German",
  fr: "French",
  pl: "Polish",
  tr: "Turkish",
  ru: "Russian",
  it: "Italian",
  cs: "Czech",
  es: "Spanish",
  nl: "Dutch",
  pt: "Portuguese",
  zh: "Chinese",
  ko: "Korean",
};

export const LocaleSwitcher: FC<LocaleSwitcherProps> = ({
  breadcrumb,
  currentCountry,
  entityId,
}) => {
  const { localeOverlay, setLocaleOverlay } = useLocaleContext();

  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.sm - 1}px)`,
  });

  const [overlayTitle] = useLabels([
    "ui-626",
    "Change country or language of the website",
  ]);

  return (
    <Lightbox
      background="grey-triangle"
      open={localeOverlay}
      setOpen={setLocaleOverlay}
      size={isMobile ? "large" : "small"}
      heading={{
        title: isMobile
          ? overlayTitle.short_title || overlayTitle.title
          : overlayTitle.title,
        level: "h4",
        className: "mb-0",
      }}
    >
      <Languages
        breadcrumb={breadcrumb}
        currentCountry={currentCountry}
        entityId={entityId}
      />
    </Lightbox>
  );
};

export default LocaleSwitcher;
