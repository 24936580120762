import getConfig from "next/config";
import type { NextRouter } from "next/router";
import packageJson from "../package.json";

export const getFirstParam = (param: NextRouter["query"][string]) => {
  if (!param) return undefined;
  return Array.isArray(param) ? param[0] : param;
};

/**
 * Parses any JS Object without errors, falls back to input if strict.
 *
 * @param json string which should be parsed, if other type it would be returned directly
 * @param strict if true, function will return `undefined` if json param is string and could not be parsed, else json param will be returned if errors occur
 * @returns if string could be parsed, parsed JSON will be returned, otherwise: if strict it will return `undefined`, else json param will be returned
 */
export const parseJson = <T = any>(json: any, strict?: boolean): T => {
  if (typeof json !== "string") return json;

  let parsed;
  try {
    parsed = JSON.parse(json);
  } catch {
    if (strict) {
      return undefined;
    }
  }

  return parsed ?? json;
};

/**
 * In .env file in Next.js runtime the $-sign has to be escaped with a \ before it, but not in ts-node runtimes, so we are removing backslashes from this string.
 *
 * @param password string which should be searched
 * @returns Unescaped password
 */
export const deescapePassword = (password: string) => {
  if (typeof password !== "string") return;

  return password.replace(/\\/g, "");
};

/**
 * Checks if current runtime is localhost based on `BASE_URL` in `.env`.
 *
 * @returns true if current runtime is localhost
 */
export const checkLocalhost = () => {
  const { publicRuntimeConfig } = getConfig();
  return (
    (publicRuntimeConfig.BASE_URL as string).includes("localhost") ||
    (publicRuntimeConfig.BASE_URL as string).includes(
      "krohne-ng-nextjs.ddev.site",
    )
  );
};

/**
 * Checks if given key exists in given object.
 *
 * @param object Object which should be checked
 * @param key Key which could exist in object
 * @returns true if key exists in object
 */
export const isKeyOfObject = <T extends Record<string, unknown>>(
  object: T,
  key: string | number | symbol,
): key is keyof T => key in object;

/**
 * Gets the app info about the version, system and environment from package.json and .env.
 *
 * @returns an object with \
 * `version`, representing the current version of the app,\
 * `system`, representing the current system (`dev`, `stage`, `live`), will always be `dev` on localhost and \
 * `environment`, representing the current environment (`development`, `production`), depending if the app was started using `yarn dev` or via build and start
 */
export const getAppInfo = () => {
  const { publicRuntimeConfig } = getConfig();
  return {
    version: packageJson.version,
    system: publicRuntimeConfig.APP_ENV,
    environment: process.env.NODE_ENV,
  };
};
