import { NavContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext } from "react";

const NavContext = createContext<NavContextProps>(null);

export const NavContextProvider: FC<
  NavContextProps & { children: ReactNode }
> = ({
  isLandingpage,
  mobileNavOverlay,
  setMobileNavOverlay,
  customerPortalOverlayOpen,
  setCustomerPortalOverlayOpen,
  showCountryForwardingBanner,
  setShowCountryForwardingBanner,
  children,
}) => {
  return (
    <NavContext.Provider
      value={{
        isLandingpage,
        mobileNavOverlay,
        setMobileNavOverlay,
        customerPortalOverlayOpen,
        setCustomerPortalOverlayOpen,
        showCountryForwardingBanner,
        setShowCountryForwardingBanner,
      }}
    >
      {children}
    </NavContext.Provider>
  );
};

export const useNavContext = () => {
  const context = useContext(NavContext);
  if (!context) {
    throw new Error("useNavContext must be used within NavContextProvider");
  }
  return context;
};
