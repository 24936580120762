import { CallToActionEntity } from "constants/types";
import { getEntityTypeAndNumber } from "helpers/data/utils";

/**
 * Configuration and meta data for entities
 */
export const entitiesConfig = {
  _default: {
    counter_definition: "ui-36",
    show_more_entities_button: "ui-15",
    call_to_action: "none" as const,
    call_to_action_button: {
      _default: "ui-13",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_background: "grey-triangle",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    parent_entity: "kp",
    detail_page_entity_type: "kp",
    relation_sort_mode: {
      related_media: "relation_order",
    },
    overview_label: "ui-19",
    related_solutions_label: "ui-249",
    applications_label: "ui-188",
    media_label: "ui-614",
    overview_search_label: "xx-xxx",
  },
  kp: {
    counter_definition: "ui-36",
    show_more_entities_button: "ui-15",
    noresult_ui_element: "ui-959",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-13",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_background: "grey-triangle",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    parent_entity: "kp",
    parent_field: "parent_product",
    detail_page_entity_type: "kp",
    relation_sort_mode: {
      related_media: "relation_order",
      additional_key_visuals: "relation_order",
    },
    overview_search_label: "ui-41",
    specifications_attribute_blacklist: {
      ambient_temperature_c_max: true,
      industrial_requirements: true,
      process_connections_compression_fitting_g_inch_female: true,
      process_connections_compression_fitting_g_inch_male: true,
      process_connections_compression_fitting_in_m_male: true,
      process_connections_compression_fitting_npt_inch_female: true,
      process_connections_compression_fitting_npt_inch_male: true,
      process_connections_hygienic_aseptic_screwed_union_din_11864_1a: true,
      process_connections_hygienic_clamp_din_32676: true,
      process_connections_hygienic_clamp_iso_2852: true,
      process_connections_hygienic_collared_weld_in_sleeve: true,
      process_connections_hygienic_cone: true,
      process_connections_hygienic_dairy_screw_din_11851: true,
      process_connections_hygienic_fitting_with_compression_nut: true,
      process_connections_hygienic_neumo_biocontrol: true,
      process_connections_hygienic_sms_1145: true,
      process_connections_hygienic_spherical_weld_in_sleeve: true,
      process_connections_hygienic_tri_clamp: true,
      process_connections_hygienic_varivent: true,
      process_connections_hygienic_weld_in_sleeve: true,
      process_connections_hygienic_weld_in_sleeve_with_shoulder: true,
      process_connections_others: true,
      process_connections_thread_g_inch_female: true,
      process_connections_thread_g_inch_male: true,
      process_connections_thread_g_inch_union_nut: true,
      process_connections_thread_m_male: true,
      process_connections_thread_npt_inch_female: true,
      process_connections_thread_npt_inch_male: true,
      process_connections_weld_in_diameter: true,
      process_connections_weld_in_fitting: true,
      process_connection_flange_a_jis_max: true,
      process_connection_flange_a_jis_min: true,
      process_connection_flange_dn_en_max: true,
      process_connection_flange_dn_en_min: true,
      process_connection_flange_inch_asme_max: true,
      process_connection_flange_inch_asme_min: true,
      process_pressure_barg_rel_max: true,
      process_pressure_barg_rel_min: true,
      process_pressure_bar_abs_max: true,
      process_pressure_bar_abs_min: true,
      process_pressure_classification: true,
      process_pressure_psig_rel_max: true,
      process_pressure_psig_rel_min: true,
      process_pressure_psi_abs_max: true,
      process_pressure_psi_abs_min: true,
      process_temperature_classification: true,
      process_temperature_c_max: true,
      process_temperature_c_min: true,
      process_temperature_f_max: true,
      process_temperature_f_min: true,
      process_temperature_range: true,
      specific_requirements: true,
      previous_product_names: true,
      communication_output_wire_design: true,
      alternative_product_categories: true,
      medium_properties: true,
      medium_properties_gases: true,
      medium_properties_liquids: true,
      medium_properties_solids: true,
      medium_properties_steam: true,
      design_requirements: true,
      application_requirements: true,
      pressure_rating_asme: true,
      pressure_rating_en: true,
      pressure_rating_jis: true,
      measuring_point_liquids: true,
      measuring_point_solids: true,
    },
  },
  ws: {
    counter_definition: "ui-622",
    show_more_entities_button: "ui-624",
    call_to_action: "target-language" as const,
    call_to_action_button: {
      _default: "ui-623",
    },
    ratio_multiplicator: 1,
    preview_image_framing: "ar11",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
  },
  ct: {
    counter_definition: "ui-36",
    show_more_entities_button: "ui-15",
    call_to_action: "target" as const,
    call_to_action_button: {
      _default: "ui-13",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "grey-triangle",
    preview_image_container_border: false,
  },
  ka: {
    counter_definition: "ui-188",
    show_more_entities_button: "ui-248",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-82",
    },
    call_to_action_button_secondary: "ui-187",
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "ka",
    sort_current_language_on_top: true,
  },
  so: {
    counter_definition: "ui-194",
    show_more_entities_button: "ui-193",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-192",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "so",
    relation_sort_mode: {
      related_media: "relation_order",
    },
  },
  dp: {
    counter_definition: "ui-137",
    show_more_entities_button: "ui-146",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-145",
    },
    preview_image_framing: "none",
    preview_image_background: "none",
    preview_image_container_border: true,
    parent_category_field: "parent_category",
    detail_page_entity_type: "dp",
  },
  mp: {
    counter_definition: "ui-137",
    show_more_entities_button: "ui-146",
    call_to_action: "parent_detail" as const,
    parent_entity: "dp",
    parent_field: "parent_detailed_process",
    call_to_action_button: {
      _default: "ui-147",
    },
    preview_image_framing: "none",
    preview_image_background: "none",
    preview_image_container_border: true,
    parent_category_field: "parent_category",
    detail_page_entity_type: "dp",
  },
  im: {
    counter_definition: "",
    show_more_entities_button: "",
    call_to_action: "lightbox" as const,
    call_to_action_button: {
      _default: "",
    },
    preview_image_framing: "none",
    preview_image_background: "none",
    preview_image_container_border: true,
  },
  sv: {
    counter_definition: "ui-256",
    show_more_entities_button: "ui-257",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-258",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "sv",
  },
  tb: {
    call_to_action: "none" as const,
    parent_category_field: "parent_category",
    preview_image_framing: "none",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
  },
  li: {
    counter_definition: "ui-139",
    show_more_entities_button: "ui-150",
    call_to_action: "target" as const,
    call_to_action_button: {
      _default: "ui-276",
      li_download_center: "ui-8",
      li_online_media_reference: "ui-254",
      li_krohne_academy_online: "ui-253",
    },
    preview_image_source: "self",
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
  },
  news: {
    counter_definition: "ui-598",
    show_more_entities_button: "ui-606",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-602",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "grey-triangle",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "news",
    relation_sort_mode: {
      related_media: "relation_order",
    },
  },
  event: {
    counter_definition: "ui-599",
    show_more_entities_button: "ui-607",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-603",
    },
    ratio_multiplicator: 0.75,
    preview_image_orientation: "height",
    preview_image_framing: "none",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "event",
    ignore_different_lang: true,
    relation_sort_mode: {
      related_media: "relation_order",
    },
  },
  job: {
    counter_definition: "ui-600",
    show_more_entities_button: "ui-608",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-604",
    },
    ignore_different_lang: true,
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "job",
    relation_sort_mode: {
      related_media: "relation_order",
    },
  },
  technology: {
    detail_page_entity_type: "technology",
    call_to_action_button: {
      _default: "ui-630",
    },
    preview_image_framing: "none",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    counter_definition: "ui-629",
    show_more_entities_button: "ui-631",
    call_to_action: "detail" as const,
  },
  page: {
    counter_definition: "ui-856",
    show_more_entities_button: "ui-858",
    call_to_action: "page" as const,
    call_to_action_button: {
      _default: "ui-857",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "grey-triangle",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "document",
    relation_sort_mode: {
      related_media: "relation_order",
    },
  },
  slider: {
    counter_definition: "",
    show_more_entities_button: "",
    call_to_action: "lightbox" as const,
    call_to_action_button: {
      _default: "",
    },
    preview_image_framing: "none",
    preview_image_background: "none",
    preview_image_container_border: true,
  },
  ta: {
    counter_definition: "ui-188",
    show_more_entities_button: "ui-248",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-899",
    },
    ratio_multiplicator: 0.75,
    preview_image_orientation: "height",
    preview_image_framing: "ar34",
    preview_image_framing_cl_param: "t_ar34_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "ta",
  },
  pr: {
    counter_definition: "ui-39",
    show_more_entities_button: "ui-79",
    call_to_action: "detail_parent" as const,
    call_to_action_parent_attribute: "related_measuring_principle",
    call_to_action_button: {
      _default: "ui-82",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "pr",
  },
  video: {
    counter_definition: "ui-143",
    show_more_entities_button: "ui-157",
    call_to_action_button: {
      _default: "ui-872",
    },
    preview_image_framing: "ar169",
    call_to_action: "lightbox" as const,
    preview_image_background: "none",
    preview_image_container_border: true,
  },
  document: {
    counter_definition: "ui-601",
    show_more_entities_button: "ui-609",
    call_to_action: "download" as const,
    call_to_action_button: {
      _default: "ui-605",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "document",
    relation_sort_mode: {
      related_media: "relation_order",
    },
  },
  service: {
    counter_definition: "ui-256",
    show_more_entities_button: "ui-257",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-258",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "service",
  },
  sustainability: {
    counter_definition: "ui-39",
    show_more_entities_button: "ui-79",
    call_to_action: "detail" as const,
    call_to_action_button: {
      _default: "ui-82",
    },
    ratio_multiplicator: 0.75,
    preview_image_framing: "ar43",
    preview_image_framing_cl_param: "t_ar43_cr_c",
    preview_image_background: "none",
    preview_image_container_border: false,
    parent_category_field: "parent_category",
    detail_page_entity_type: "sustainability",
  },
};

/**
 * Gets the call to action link for an entity.
 *
 * @param entity a contentset entity with resolved url (_url attribute)
 * @returns the call to action link
 */
export function getCallToActionLink(entity: CallToActionEntity) {
  const [type] = getEntityTypeAndNumber(entity.id) as [
    keyof typeof entitiesConfig,
    number,
  ];
  const callToAction = (entitiesConfig[type] ?? entitiesConfig._default)
    ?.call_to_action;

  switch (callToAction) {
    case "target":
      if ("link_absolute_path" in entity) {
        return entity.link_absolute_path as string;
      }
      break;
    case "target-language":
      if ("website_protocol_and_hostname" in entity) {
        return entity.website_protocol_and_hostname as string;
      }
  }
  return entity._url;
}
