import { LocaleContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext } from "react";

export const LocaleContext = createContext<LocaleContextProps>(null);

export const LocaleContextProvider: FC<
  LocaleContextProps & { children: ReactNode }
> = ({ locale, localeOverlay, setLocaleOverlay, children }) => {
  return (
    <LocaleContext.Provider
      value={{
        locale,
        localeOverlay,
        setLocaleOverlay,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => {
  const context = useContext(LocaleContext);
  if (!context) {
    throw new Error(
      "useLocaleContext must be used within LocaleContextProvider",
    );
  }
  return context;
};
