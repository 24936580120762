import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { getGlobalPageProps } from "helpers/data";
import { useLabels } from "helpers/hooks";
import { getFirstParam } from "helpers/utils";
import { GetStaticProps } from "next";
import Head from "next/head";
import { Col, Row } from "react-bootstrap";

export default function Custom404() {
  const [button, backBtn, search] = useLabels(
    ["ui-633", "Sorry, this searched page could not be found"],
    ["ui-781", "Back to home page"],
    ["tb-83", "Search & find"],
  );

  return (
    <>
      <Head>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      <Container>
        <Row>
          <Col
            xs={{ offset: 2, span: 8 }}
            sm={{ offset: 3, span: 6 }}
            lg={{ offset: 4, span: 4 }}
          >
            <Cloudinary
              media={{
                digital_asset_id: "im-photo-composition/mfc-400-404-error",
              }}
            />
          </Col>
        </Row>
        <h1 className="title mb-4 text-center">{button.title}</h1>
        <p className="mb-5 text-center">{button.label}</p>
        <div className="buttons">
          <Button
            icon="search"
            variant="primary"
            label={search.title}
            onClick={() => {
              document
                .querySelector<HTMLElement>(
                  ".header .navigation .search-nav .svg.search",
                )
                ?.click();
            }}
          />
          <Button url="/" label={backBtn.label} />
        </div>
      </Container>
    </>
  );
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const locale = getFirstParam(params?.locale) ?? "k_en";
  const globalProps = await getGlobalPageProps(locale);

  return {
    props: globalProps,
    revalidate: +(process.env.PAGE_ERROR_CACHE_VALIDITY ?? 30), // 30 seconds
  };
};
