import { Button } from "components/button";
import { CSSProperties, forwardRef } from "react";
import { RevisionMap } from "constants/types";
import { upperCase } from "lodash";
import { useLabels } from "helpers/hooks";
import classNames from "classnames";

interface EntityTableRevisionsProps {
  isSoftware: boolean;
  languages: string[];
  revisions: RevisionMap;
  style?: CSSProperties;
  variant: "gray" | "white";
}

const EntityTableRevisions = forwardRef<
  HTMLTableElement,
  EntityTableRevisionsProps
>(({ isSoftware, languages, revisions, style, variant }, ref) => {
  const [revisionBtn] = useLabels(["ui-950", "Revision"]);

  const languagePriorityOrder = [
    "en",
    "de",
    "fr",
    "es",
    "cs",
    "pl",
    "ru",
    "zh",
  ];

  const sortLanguages = () => {
    return languages.sort((a: string, b: string) => {
      const indexA = languagePriorityOrder.indexOf(a);
      const indexB = languagePriorityOrder.indexOf(b);

      if (indexA > -1 && indexB > -1) {
        return indexA - indexB;
      } else if (indexA > -1) {
        return -1;
      } else if (indexB > -1) {
        return 1;
      } else {
        return a.localeCompare(b);
      }
    });
  };

  const sortedLanguages = sortLanguages();

  return (
    <table
      ref={ref}
      className={`revision-table revision-table--${variant}`}
      style={style}
    >
      <thead>
        <tr>
          <th />
          {sortedLanguages.map((language, index) => (
            <th key={index}>{upperCase(language)}</th>
          ))}
          <th />
        </tr>
      </thead>
      <tbody>
        {Object.entries(revisions)
          .reverse()
          .map(([version, subrevisions], index) => {
            return (
              <tr key={index}>
                <td>
                  {isSoftware
                    ? Object.values(subrevisions)[0]?.version ?? version
                    : `${revisionBtn.title} ${version}`}
                </td>
                {sortedLanguages.map((language, index) => (
                  <td
                    className={classNames({ odd: index % 2 === 0 })}
                    key={index}
                  >
                    {Object.keys(subrevisions).includes(language) ? (
                      <Button
                        url={subrevisions[language].url}
                        label=""
                        icon="download"
                        variant="small"
                        blank
                        onlyIcon
                      />
                    ) : null}
                  </td>
                ))}
                <td />
              </tr>
            );
          })}
      </tbody>
    </table>
  );
});

EntityTableRevisions.displayName = "EntityTableRevisions";

export default EntityTableRevisions;
