import { ContentsetContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext } from "react";

export const ContentsetContext = createContext<ContentsetContextProps>(null);

export const ContentsetContextProvider: FC<
  ContentsetContextProps & { children: ReactNode }
> = ({
  base,
  clearFilters,
  contentset,
  contentsetWrapperRef,
  dataInfo,
  filterAndLoadMore,
  filters,
  increaseSize,
  isLoadingContentsetPage,
  language,
  modalState,
  modalTarget,
  nextPage,
  searchInput,
  searchValue,
  selectedOptions,
  setColumnVisibility,
  setModalState,
  setModalTarget,
  setNextPage,
  setSearchInput,
  setSearchValue,
  setSelectedOptions,
  setView,
  skeleton,
  tableInstance,
  view,
  viewMap,
  attributes,
  children,
}) => {
  return (
    <ContentsetContext.Provider
      value={{
        base,
        clearFilters,
        contentset,
        contentsetWrapperRef,
        dataInfo,
        filterAndLoadMore,
        filters,
        increaseSize,
        isLoadingContentsetPage,
        language,
        modalState,
        modalTarget,
        nextPage,
        searchInput,
        searchValue,
        selectedOptions,
        setColumnVisibility,
        setModalState,
        setModalTarget,
        setNextPage,
        setSearchInput,
        setSearchValue,
        setSelectedOptions,
        setView,
        skeleton,
        tableInstance,
        view,
        viewMap,
        attributes,
      }}
    >
      {children}
    </ContentsetContext.Provider>
  );
};

export const useContentsetContext = () => {
  const context = useContext(ContentsetContext);
  if (!context) {
    throw new Error(
      "useContentsetContext must be used within ContentsetContextProvider",
    );
  }
  return context;
};
