import { Category } from "../types";

const groupMapping = {
  electromagnetic_flowmeter: "nv_electromagnetic_flowmeters",
  ultrasonic_flowmeter: "nv_ultrasonic_flowmeters",
  process_pressure_transmitter: "nv_pressure_transmitters",
  vortex_flowmeter: "nv_vortex_flowmeters",
  sludge_level_sensor_system: "nv_sludge_level_measuring_systems",
  temperature_sensor: "nv_temperature_sensors",
  analytical_transmitter: "nv_analytical_transmitters",
  coriolis_mass_flowmeter: "nv_coriolis_mass_flowmeters",
  variable_area_flowmeter: "nv_variable_area_flowmeters",
};

export const getProductGroupCategoryArray = (): string[] => {
  return Object.values(groupMapping);
};

const getParentCategory = (
  category: string,
  categories: Category[],
): Category[] => {
  const returnCategories = [];

  const foundCategory = categories.find(({ code }) => code === category);

  if (foundCategory) {
    returnCategories.push(foundCategory);

    if (foundCategory.parent !== "nv_home") {
      returnCategories.push(
        ...getParentCategory(foundCategory.parent, categories),
      );
    }
  }

  return returnCategories;
};

export const getProductGroupCategory = (
  productGroup: string,
  categories: Category[],
  recursive?: boolean,
): Category[] => {
  const category = categories.find(
    (cat) =>
      cat.code === (groupMapping?.[productGroup] ?? `nv_${productGroup}`),
  );

  if (!category) return [];

  if (recursive) return getParentCategory(category.code, categories);

  return [category];
};
