import { Button } from "../../button";
import { Category } from "types";
import { Cloudinary } from "components/cloudinary";
import { colors } from "../../../theme/theme";
import { Download, RevisionMap, SelectOption } from "../../../constants/types";
import { Row } from "@tanstack/react-table";
import { sanitize } from "helpers/text-processing";
import { Select } from "../../forms/select";
import { Shape } from "../../shape";
import { useEffect, useMemo, useState } from "react";
import { useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import isEmpty from "lodash/isEmpty";
import uniq from "lodash/uniq";

interface EntityTableDownloadProps {
  row: Row<Download>;
  setShowRevisions?: (value: boolean) => void;
  showRevisions?: boolean;
  width: number;
}

export const EntityTableDownload = ({
  showRevisions,
  setShowRevisions,
  width,
  ...props
}: EntityTableDownloadProps) => {
  const downloads: RevisionMap = props.getValue();
  const { country, language } = useLocale();

  const [downloadBtn, showRevisionsBtn, hideRevisionsBtn] = useLabels(
    ["ui-66", "download"],
    ["ui-924", "Show revisions"],
    ["ui-925", "Hide revisions"],
  );

  // Prepare revisions as selectoptions
  const revisionOptions = useMemo<SelectOption[]>(
    () =>
      Object.entries(downloads)
        .sort(([a], [b]) => +b - +a)
        .map(([revisionNumber, revisions]) => {
          const version = revisions[language]?.version;
          return {
            value: revisionNumber,
            label: `${
              version ||
              "Rev. " + (+revisionNumber < 10 ? "0" : "") + revisionNumber
            }`,
            ...(version && { version }),
          };
        }),
    [downloads, language],
  );

  const languageSort = (
    a: string,
    b: string,
    hasEnglishVariants: boolean = false,
  ) => {
    if (hasEnglishVariants) {
      if (country === "us") {
        if (a === "en") return -1;
        if (b === "en") return 1;
        if (a === "gb") return -1;
        if (b === "gb") return 1;
      } else if (language === "en") {
        if (a === "gb") return -1;
        if (b === "gb") return 1;
        if (a === "en") return -1;
        if (b === "en") return 1;
      } else {
        if (a === language) return -1;
        if (b === language) return 1;
        if (a === "gb") return -1;
        if (b === "gb") return 1;
      }
    } else {
      if (a === language) return -1;
      if (b === language) return 1;
      if (a === "en") return -1;
      if (b === "en") return 1;
    }

    return a.localeCompare(b);
  };

  const getLanguageLabel = (language: string, hasEnglishVariants: boolean) => {
    let label: string = null;

    if (hasEnglishVariants) {
      if (language === "en") {
        label = "US";
      }
      if (language === "gb") {
        label = "EN";
      }
    }

    if (!label) {
      label = language.toUpperCase();
    }
    return label;
  };

  const languageMap = (
    language: string,
    hasEnglishVariants: boolean = false,
  ) => {
    const label = getLanguageLabel(language, hasEnglishVariants);

    return {
      value: language,
      label: label,
    };
  };

  const getLanguageOptions = (languages: string[]) => {
    const hasEnglishVariants =
      languages.includes("gb") && languages.includes("en");

    const languageOptions = languages
      .sort((a, b) => languageSort(a, b, hasEnglishVariants))
      .map((language) => languageMap(language, hasEnglishVariants));

    return languageOptions;
  };

  // Prepare languages as selectoptions
  const [languageOptions, setLanguageOptions] = useState<SelectOption[]>(
    getLanguageOptions(
      downloads?.[revisionOptions?.[0]?.value]
        ? Object.keys(downloads[revisionOptions[0].value])
        : [],
    ),
  );

  // Handle current states of revision and language selects
  const [currentLanguage, setCurrentLanguage] = useState<SelectOption>();

  // Update language options when revision changes
  useEffect(() => {
    const revisionKey = revisionOptions[0].value;
    setLanguageOptions(getLanguageOptions(Object.keys(downloads[revisionKey])));
    if (!downloads[revisionKey][currentLanguage?.value])
      setCurrentLanguage(null);
  }, [revisionOptions, currentLanguage, downloads, language]);

  // generate targetUrl
  const [targetUrl, setTargetUrl] = useState<string | null>(null);

  useEffect(() => {
    const rev = revisionOptions[0].value;
    const lang = currentLanguage?.value ?? languageOptions[0].value;
    if (!downloads?.[rev]?.[lang]) return setCurrentLanguage(null);
    setTargetUrl(downloads[rev][lang].url);
  }, [revisionOptions, currentLanguage?.value, languageOptions, downloads]);

  function handleMouseDown() {
    if (window._etracker && window.et_DownloadEvent)
      window._etracker.sendEvent(
        window.et_DownloadEvent(
          props?.row?.original?.label,
          currentLanguage ? currentLanguage.label : languageOptions[0].label,
        ),
      );
  }

  const isSoftware = props.row.original.dlc_type.some(
    (x) => x.code === "software",
  );

  return (
    <div
      className="td"
      style={{
        maxWidth: `${width}%`,
        width: `${width}%`,
      }}
    >
      <div className="entity-table-download">
        {revisionOptions.length > 1 ? (
          <Button
            className="toggle-button"
            label={
              isSoftware
                ? revisionOptions[0]?.version
                : showRevisions
                ? hideRevisionsBtn.title
                : showRevisionsBtn.title
            }
            variant="small-icon"
            icon={showRevisions ? "caret-up" : "caret-down"}
            onClick={() =>
              setShowRevisions ? setShowRevisions(!showRevisions) : null
            }
          />
        ) : isSoftware ? (
          <div className="dlc-version">{revisionOptions[0]?.version}</div>
        ) : null}

        <div className="download-group">
          <Select
            options={languageOptions}
            value={currentLanguage}
            handleChange={setCurrentLanguage}
            width={65}
            placeholder={languageOptions[0].label}
            className="dlc-language"
            isDisabled={languageOptions.length === 1}
          />
          <Button
            label={downloadBtn.title}
            variant="primary"
            icon="download"
            blank
            url={targetUrl}
            onMouseDown={handleMouseDown}
            className="download-btn-desktop"
          />
          <Button
            url={targetUrl}
            blank
            variant="primary"
            label=""
            icon="download"
            onlyIcon
            onClick={handleMouseDown}
            className="download-btn-mobile"
          />
        </div>
      </div>
    </div>
  );
};

export const EntityTableDocumentType = ({ width, ...props }) => {
  const { original } = props.row;
  const documentTypeIconMap = {
    datasheet: "document",
    handbook: "document",
    certificate: "gear",
    brochures: "globe",
    video: "play-circle",
  };

  return (
    <div
      className="td"
      style={{
        maxWidth: `${width}%`,
        width: `${width}%`,
      }}
    >
      <div className="entity-table-document">
        {original.dlc_type[0].code && (
          <Shape
            variant={
              documentTypeIconMap?.[original.dlc_type[0].code] ?? "document"
            }
            fill={colors.darkBlue}
            size={18}
          />
        )}
        {original.dlc_type[0].label}
      </div>
    </div>
  );
};

export const EntityTableTitle = ({ width, ...props }) => {
  const title: string = props.getValue() || "";
  const { original }: { original: Download } = props.row;
  const dlcKey = Object.keys(original).filter(
    (key) => key.includes("dlc_type") && key !== "dlc_type",
  )[0];

  return (
    <div
      className="td"
      style={{
        maxWidth: `${width}%`,
        width: `${width}%`,
      }}
    >
      <div className="entity-table-title">
        {dlcKey && (
          <div className="entity-table-title-description">
            {original[dlcKey][0].label}
          </div>
        )}
        <div
          className="entity-table-title-label"
          dangerouslySetInnerHTML={{
            __html: sanitize(title.replace(/\n/g, "<br />")),
          }}
        ></div>
        {original?.description && (
          <div className="entity-table-title-description">
            {original?.tags?.show_product_names_in_description?.[0] === "yes" &&
              original?.subtitle && (
                <div>
                  <span>
                    {original.subtitle.replace(/\n/g, ", ").replace(/, $/, "")}
                  </span>
                </div>
              )}
            <span>{original.description}</span>
          </div>
        )}
      </div>
    </div>
  );
};

export const EntityTableCategories = ({ width, ...props }) => {
  const categories: Category[] = props.getValue();
  const labels = uniq(categories?.map((category) => category.label));

  return (
    <div
      className="td"
      style={{
        maxWidth: `${width}%`,
        width: `${width}%`,
      }}
    >
      {!isEmpty(labels) && (
        <div className="entity-table-categories">
          {labels.map((category, index) => (
            <div
              key={category}
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  `${category}${index < categories.length - 1 ? "," : ""}`,
                ),
              }}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
};

export const EntityTableImage = ({ width, ...props }) => {
  return (
    <div
      className="td"
      style={{
        maxWidth: `${width}%`,
        width: `${width}%`,
      }}
    >
      <div className="entity-table-image">
        <Cloudinary media={props.getValue()?.[0]} ar="ar11" />
      </div>
    </div>
  );
};
