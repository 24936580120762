import Link from "components/link";

// needed for thin-card variant used in level application selector entity-grid
// outsourced wrapper from index to prevent image flickering on resizing
const LinkedCardWrapper = ({ children, href, target, isThinCard }) =>
  isThinCard ? (
    <Link
      href={href}
      passHref={true}
      prefetch={false}
      target={target}
      className="card-link-wrapper"
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  );

export default LinkedCardWrapper;
