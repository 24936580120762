import { useEffect } from "react";
import { useSwiperSlide } from "swiper/react";

const Slide = ({
  updateColor,
  children,
}: {
  children: JSX.Element;
  updateColor: () => void;
}) => {
  const slide = useSwiperSlide();

  useEffect(() => {
    if (slide?.isActive) updateColor();
  }, [slide, updateColor]);

  return <>{children}</>;
};

export default Slide;
