import { FC, forwardRef, useEffect, useRef, useState } from "react";
import { Row, Breadcrumb, BreadcrumbProps } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";

import Link from "components/link";
import { Container } from "components/container";
import classNames from "classnames";

interface BreadcrumbItemWrapper extends BreadcrumbProps {
  itemProp: string;
  itemScope: boolean;
  itemType: string;
}

const BreadcrumbItemWrapper = forwardRef<HTMLLIElement, BreadcrumbItemWrapper>(
  (props, ref) => (
    <Breadcrumb.Item {...props} ref={ref}>
      {props.children}
    </Breadcrumb.Item>
  ),
);

BreadcrumbItemWrapper.displayName = "BreadcrumbItemWrapper";

interface BreadcrumbWrapperProps {
  crumbs: {
    lang?: string;
    title: string;
    url: string;
  }[];
  title?: string;
}

const BreadcrumbWrapper: FC<BreadcrumbWrapperProps> = ({ crumbs, title }) => {
  const last = crumbs ? crumbs.length - 1 : 0;
  const [height, setHeight] = useState(0);
  const [multilineOpen, setMultilineOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setHeight(ref?.current?.clientHeight);
  }, []);

  const openMultiline = () => {
    setMultilineOpen(!multilineOpen);
  };

  return !isEmpty(crumbs) ? (
    <Container className="breadcrumb-wrapper border-bottom overflow-hidden">
      <Row>
        <div
          className={classNames(
            "col-12 breadcrumb-inner-wrapper",
            height > 40 && "multiline",
            multilineOpen && "multiline-open",
          )}
          onClick={openMultiline}
        >
          <Breadcrumb
            ref={ref}
            itemScope
            itemType="https://schema.org/BreadcrumbList"
          >
            {crumbs.map((crumb, index) =>
              index === last ? (
                <BreadcrumbItemWrapper
                  itemProp="itemListElement"
                  itemType="https://schema.org/ListItem"
                  itemScope
                  key={index + crumb.url}
                  {...(index === last && { active: true })}
                >
                  <span
                    dangerouslySetInnerHTML={{
                      __html: (crumb.title ? crumb.title : title ?? "")
                        .replace(/&nbsp;/g, " ")
                        .replace(/<[^>]+>/g, " "),
                    }}
                    itemProp="name"
                  />
                  <meta itemProp="position" content={`${index + 1}`} />
                </BreadcrumbItemWrapper>
              ) : (
                <li
                  key={index + crumb.url}
                  className={classNames(
                    "breadcrumb-item",
                    index === last && "active",
                  )}
                  itemProp="itemListElement"
                  itemType="https://schema.org/ListItem"
                  itemScope
                >
                  <Link
                    href={crumb.url}
                    passHref={true}
                    prefetch={false}
                    itemProp="item"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: (crumb.title ?? "")
                          .replace(/&nbsp;/g, " ")
                          .replace(/<[^>]+>/g, " "),
                      }}
                      itemProp="name"
                    />
                  </Link>
                  <meta itemProp="position" content={`${index + 1}`} />
                </li>
              ),
            )}
          </Breadcrumb>
        </div>
      </Row>
    </Container>
  ) : null;
};

export default BreadcrumbWrapper;
