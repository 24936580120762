import { Cloudinary } from "components/cloudinary";
import { Container } from "components/container";
import { Lightbox } from "components/lightbox";
import { Slider } from "components/slider";

import { getEdgeSettings } from "components/wrapper/components";
import { WrapperComponentProps } from "constants/types";
import { getSpeakingId } from "helpers/text-processing";
import { FC, useState, useEffect } from "react";
import { Im } from "types";

interface ImagesWrapperProp extends WrapperComponentProps {
  ar?: string;
  aspect_ratio?: string;
  background?: string;
  caption?: string;
  graphql_select?: any[];
  images?: Im[];
  overwrite_caption?: string[];
  render_slider?: boolean;
  show_image_caption?: boolean;
  show_lightbox?: boolean;
}

const ImagesWrapper: FC<ImagesWrapperProp> = (props) => {
  const images = props.images ? props.images : props.graphql_select;

  const [open, setOpen] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const shouldLoop = false;

  const switchHandler = {
    prev: () =>
      setActiveIndex((activeIndex) =>
        activeIndex <= 0
          ? shouldLoop
            ? images?.length - 1
            : activeIndex
          : activeIndex - 1,
      ),
    next: () =>
      setActiveIndex((activeIndex) =>
        activeIndex >= images?.length - 1
          ? shouldLoop
            ? 0
            : activeIndex
          : activeIndex + 1,
      ),
  };

  const keydownHandler = (event) => {
    const callback = {
      ArrowLeft: switchHandler.prev,
      ArrowRight: switchHandler.next,
    }[event.key];
    callback?.();
  };

  useEffect(() => {
    document.addEventListener("keydown", keydownHandler);
    return () => {
      document.removeEventListener("keydown", keydownHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const captions = props.overwrite_caption ? props.caption?.split("\n") : null;

  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  const innerImages = images
    ? images.filter(Boolean).map((image, index) => {
        return (
          <div
            {...(props.show_lightbox
              ? {
                  onClick: () => {
                    setActiveIndex(index);
                    setOpen(true);
                  },
                }
              : {})}
            className={props.show_lightbox ? `hover__zoom` : null}
            key={image?.id || index}
            id={getSpeakingId({ title: image?.caption })}
          >
            <Cloudinary
              media={image}
              {...(props?.aspect_ratio && { ar: props.aspect_ratio })}
              {...(props?.ar && { ar: props.ar })}
              {...(props?.background && { background: props.background })}
              caption={
                props.show_image_caption
                  ? props.overwrite_caption
                    ? captions?.[index]
                    : image?.caption
                  : undefined
              }
            />
          </div>
        );
      })
    : null;

  const lightbox = props.show_lightbox && images && (
    <Lightbox
      heading={{
        title: images?.[activeIndex]?.title,
        stylingLevel: "h4",
      }}
      headingType="primary"
      background="grey-triangle"
      size="large"
      open={open}
      setOpen={setOpen}
      switchHandler={switchHandler}
      disableNavigation={{
        prev: activeIndex <= 0 && !shouldLoop,
        next: activeIndex >= images?.length - 1 && !shouldLoop,
      }}
    >
      <Cloudinary
        media={images?.[activeIndex]}
        {...(props?.aspect_ratio && { ar: props.aspect_ratio })}
        {...(props?.background && { background: props.background })}
      />
    </Lightbox>
  );

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__images-${props.index}`}
      className="section component__images"
    >
      <>
        {props.render_slider && images ? (
          <Slider
            slidesPerView={images.length < 3 ? images.length : 3}
            spaceBetween={10}
            {...(images.length > 3
              ? {
                  navigation: "outer",
                }
              : {})}
          >
            {innerImages}
          </Slider>
        ) : (
          <div className="flex-images d-flex">{innerImages}</div>
        )}
        {lightbox}
      </>
    </Container>
  );
};

export default ImagesWrapper;
