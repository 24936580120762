import { Button } from "components/button";
import { CellContext, Row, flexRender } from "@tanstack/react-table";
import { Download } from "constants/types";
import { Fragment, useEffect, useRef, useState } from "react";
import { useLabels } from "helpers/hooks";
import EntityTableRevisions from "../entity-table-revisions";
import classNames from "classnames";

interface EntityTableRowProps {
  entity: Row<Download>;
  variant: "gray" | "white";
}

interface EntityCellContext<T> extends CellContext<T, unknown> {
  setShowRevisions: (value: boolean) => void;
  showRevisions: boolean;
}

const EntityTableRow = ({ entity, variant }: EntityTableRowProps) => {
  const [showAllBtn, showLessBtn] = useLabels(
    ["ui-912", "Show all"],
    ["ui-108", "Show less"],
  );

  const MIN_REVISIONS = 5;

  const containerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLTableElement>(null);

  const [showRevisions, setShowRevisions] = useState(false);
  const [showAllRevisions, setShowAllRevisions] = useState(false);
  const [containerHeight, setContainerHeight] = useState(0);
  const [tableHeight, setTableHeight] = useState(0);
  const [containerIsOpen, setContainerIsOpen] = useState(false);

  const revisions = entity.original.revisions;
  const revisionsLength = Object.keys(revisions).length;

  const revisionsLanguages = Object.values(revisions)
    .flatMap((versions) => Object.keys(versions))
    .reduce((uniqueLanguages: string[], language) => {
      if (!uniqueLanguages.includes(language)) {
        uniqueLanguages.push(language);
      }
      return uniqueLanguages;
    }, []);

  useEffect(() => {
    if (containerRef.current) {
      if (showRevisions) {
        setContainerHeight(containerRef.current.scrollHeight);

        containerRef.current.parentElement.addEventListener(
          "transitionend",
          () => {
            setContainerIsOpen(true);
          },
          {
            once: true,
          },
        );
      } else {
        setContainerHeight(containerRef.current.scrollHeight);

        setContainerIsOpen(false);

        setTimeout(() => {
          setContainerHeight(0);
        }, 50);
      }
    }
  }, [tableHeight, showRevisions, showAllRevisions]);

  useEffect(() => {
    if (tableRef.current) {
      if (showAllRevisions) {
        setTableHeight(tableRef.current.scrollHeight);
      } else {
        const headRowHeight = tableRef.current.querySelector("tr").offsetHeight;
        const bodyRowHeight =
          tableRef.current.querySelector<HTMLElement>("tbody tr").offsetHeight;

        setTableHeight(
          headRowHeight +
            bodyRowHeight * Math.min(revisionsLength, MIN_REVISIONS),
        );
      }
    }
  }, [revisionsLength, showAllRevisions]);

  return (
    <>
      <div className={`tr tr--${variant}`} key={entity.id}>
        {entity.getVisibleCells().map((cell) => {
          return (
            <Fragment key={cell.id}>
              {flexRender<EntityCellContext<Download>>(
                cell.column.columnDef.cell,
                {
                  ...cell.getContext(),
                  showRevisions: showRevisions,
                  setShowRevisions: setShowRevisions,
                },
              )}
            </Fragment>
          );
        })}
      </div>

      <div
        className={classNames(`tr tr--${variant} revision-row`, {
          "revision-row--collapsed": !showRevisions,
        })}
        style={{
          maxHeight: !containerIsOpen ? `${containerHeight}px` : undefined,
        }}
      >
        <div ref={containerRef} className="revision-container">
          <div
            className="revision-table-wrapper"
            style={{
              height: `${tableHeight}px`,
            }}
          >
            <EntityTableRevisions
              ref={tableRef}
              languages={revisionsLanguages}
              revisions={Object.fromEntries(
                Object.entries(revisions).sort(
                  ([keyA], [keyB]) => parseInt(keyB, 10) - parseInt(keyA, 10),
                ),
              )}
              isSoftware={entity.original.dlc_type.some(
                (x) => x.code === "software",
              )}
              variant={variant}
            />
          </div>
          {revisionsLength > MIN_REVISIONS ? (
            <Button
              className="toggle-button"
              label={showAllRevisions ? showLessBtn.title : showAllBtn.title}
              variant="small-icon"
              icon={showAllRevisions ? "caret-up" : "caret-down"}
              onClick={() => setShowAllRevisions(!showAllRevisions)}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};

export default EntityTableRow;
