import { Heading } from "components/heading";
import { PageProps } from "constants/types";
import { get } from "lodash-es";
import { FC } from "react";

interface PcPageProps {
  content: PageProps["content"];
}

const PcPage: FC<PcPageProps> = ({ content }) => {
  return (
    <div className="container">
      <div className="row">
        <div style={{ width: "100%" }}>
          <Heading title={get(content, "pc.0.title", null)} />
        </div>
        <h4 style={{ width: "100%" }}>{get(content, "pc.0.subtitle", null)}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: get(content, "pc.0.excerpt", null),
          }}
          style={{ marginTop: "25px" }}
        />
      </div>
    </div>
  );
};

export default PcPage;
