import { useContentsetContext } from "contexts/contentset-context";
import { getColumnVisibility } from "..";
import { ViewString } from "../../../constants/types";
import { colors } from "../../../theme/theme";
import { Icon, Shape } from "../../shape";

const ViewSwitcher = () => {
  const { view, setView, contentset, setColumnVisibility } =
    useContentsetContext();
  return (
    <div className="view-switcher">
      {Object.keys(view.map).map((key: ViewString) => {
        const isActive = key === view.active;
        if (contentset.layout === "dlc" && key !== "list_thin") return null;
        if (
          contentset.layout !== "dlc" &&
          key === "fullscreen_button" &&
          contentset.default_view !== "fullscreen_button"
        )
          return null;
        return (
          <div
            key={key}
            className={`view-switcher-button switch-${key.replace("_", "-")}`}
            onClick={() => {
              setView({ active: key, map: view.map });
              setColumnVisibility(getColumnVisibility(contentset, key));
            }}
          >
            <Shape
              variant={key.replace("_", "-") as Icon}
              fill={isActive ? colors.blue : colors.gray80}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ViewSwitcher;
