import { colors } from "theme/theme";
import { CSSTransition } from "react-transition-group";
import { FC, useEffect, useRef, useState } from "react";
import { Icon, Shape } from "components/shape";
import { IconNavLink } from "components/icon-nav-link";
import { isEmpty, unescape } from "lodash-es";
import { Nav } from "react-bootstrap";
import { sanitize } from "isomorphic-dompurify";
import { WebAppsProps } from "constants/types";
import Link from "components/link";
import Logo from "./logo";
import Portal from "components/lightbox";

export const WebApps: FC<WebAppsProps> = ({
  apps,
  webAppsLabel,
  mobile = false,
}) => {
  const [webAppsOverlay, setWebAppsOverlay] = useState(false);

  const node = useRef<HTMLDivElement>(null);
  const nodeBtn = useRef<HTMLDivElement>(null);
  const handleClick = (e) => {
    if (
      node?.current?.contains(e.target) ||
      nodeBtn?.current?.contains(e.target)
    ) {
      // inside click
      return;
    }
    // outside click
    setWebAppsOverlay(false);
  };
  useEffect(() => {
    // add when mounted
    document.addEventListener("mousedown", handleClick);
    // return function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const overlayContent = (
    <div className="app-wrapper">
      {apps.map((app) => {
        let icon: string;
        if (app.vector_icon?.[0].link_absolute_path) {
          const url = new URL(app.vector_icon[0].link_absolute_path);
          const regex = /\/([-a-z_]+)\.([a-z]+)$/gm;
          icon = regex.exec(url.pathname)[1];
        }

        return (
          <a
            className="app"
            key={app.title}
            href={app.link_relative_path || app.link_absolute_path}
            target={app.link_relative_path ? "_self" : "_blank"}
            rel="noreferrer"
          >
            <div>
              {app.vector_icon?.[0].link_absolute_path && (
                <Shape variant={icon as Icon} fill={colors.darkBlue} />
              )}
              <h3
                dangerouslySetInnerHTML={{
                  __html: sanitize(unescape(app.title)),
                }}
              ></h3>
            </div>
          </a>
        );
      })}
    </div>
  );

  return !isEmpty(apps) ? (
    <div className="meta-left d-inline-flex nav">
      <Nav
        onClick={() => {
          setWebAppsOverlay(!webAppsOverlay);
          if (!webAppsOverlay && mobile) {
            setTimeout(() => {
              if (!node.current) return;

              node.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
              });
            }, 200);
          }
        }}
        ref={nodeBtn}
      >
        <IconNavLink
          chevron={!mobile}
          icon={"apps"}
          label={webAppsLabel}
          open={webAppsOverlay}
          type="icon"
        />
        {mobile && (
          <Shape
            fill={colors.white}
            size={16}
            variant={webAppsOverlay ? "caret-down" : "caret-up"}
          />
        )}
      </Nav>
      {webAppsOverlay ? (
        !mobile ? (
          <Portal selector="web-apps-portal">
            <div
              className="web-app"
              ref={node}
              style={{
                left: `${
                  nodeBtn?.current?.getBoundingClientRect().left - 22
                }px`,
              }}
            >
              <div className="web-app-wrapper">
                <Link href="/" passHref={true} prefetch={false} legacyBehavior>
                  <Nav.Link className="p-0">
                    <Logo />
                  </Nav.Link>
                </Link>
                {overlayContent}
              </div>
            </div>
          </Portal>
        ) : null
      ) : null}
      {mobile ? (
        <CSSTransition
          in={webAppsOverlay}
          classNames="mobile-menu-apps-animate"
          unmountOnExit
          timeout={200}
        >
          <div className="web-app web-app-mobile" ref={node}>
            {overlayContent}
          </div>
        </CSSTransition>
      ) : null}
    </div>
  ) : null;
};

export default WebApps;
