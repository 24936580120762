import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { checkForKrohnePick } from "components/contentset/dropdown-filter";
import { Select } from "components/forms/select";
import { Lightbox } from "components/lightbox";
import { Link } from "components/link";
import { Shape } from "components/shape";
import { Entity, SelectGroup, SelectOption } from "constants/types";
import { useLocale } from "helpers/locale";
import { makeParagraph } from "helpers/text-processing";
import debounce from "lodash/debounce";
import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { breakpoints, colors } from "theme/theme";
import { stripHtmlTags } from "helpers/text-processing";
import { Kp, Pc, So, Sv, Dp, News, Job, Event } from "types";

interface ApiResponse {
  dp: Dp[];
  event: Event[];
  job: Job[];
  kp: Kp[];
  news: News[];
  pc: Pc[];
  so: So[];
  sv: Sv[];
}

const calculateTotalEntries = (data: ApiResponse): string => {
  return String(
    Object.values(data).reduce(
      (total: number, array: []) => total + array.length,
      0,
    ),
  );
};

export const SearchBar = ({
  header,
  types = null,
  title = null,
  focusOnMount = false,
  overview = false,
}) => {
  const [value, setValue] = useState<SelectOption>();
  const { locale } = useLocale();

  const debouncedSearch = debounce(async (search: string, callback) => {
    try {
      const response = await fetch(`/api/search`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({
          input: search,
          locale,
          overview,
          ...(types && { types }),
        }),
      });
      const results: ApiResponse = await response.json();

      if (results) {
        const foundEntries = calculateTotalEntries(results);

        // Etracker event for search strings
        window?._etracker?.sendEvent(
          // @ts-ignore
          new et_UserDefinedEvent(
            `[${foundEntries}] ${search}`,
            "Search",
            "searchresult",
            "",
          ),
        );
      }

      if (results) {
        // Etracker event for search strings
        window?._etracker?.sendEvent(
          // @ts-ignore
          new et_UserDefinedEvent(`${search}`, "Search", "searchresult", ""),
        );
      }

      // Turn results into an array of option groups
      const options: SelectGroup[] = Object.entries(results).map(
        ([key, value]: [string, (Entity & { url?: string })[]]) => ({
          label: header?.base?.search?.entity_class.filter(
            (element) => element.code === key,
          )[0]?.label,
          options: value.map((entity) => ({
            label: entity?.label ?? entity?.title,
            value: entity.id,
            url: entity?.url,
            ...(entity?.key_visual?.length > 0 && {
              image: {
                media:
                  entity.key_visual?.[0]?.__typename === "Ct" ||
                  entity.key_visual?.[0]?.__typename === "Ta"
                    ? undefined
                    : entity.key_visual?.[0],
                ar: "ar11",
              },
            }),
          })),
        }),
      );

      if (checkForKrohnePick(search)) {
        options.push({
          label: header.base?.search?.pick?.tb?.title,
          options: [
            {
              value: search,
              label: search,
              id: search,
              isKrohnePick: true,
            },
          ],
        });
      }
      callback(options);
    } catch (error) {
      console.error(error);
      callback(null);
    }
  }, 500);

  const search = (search: string) =>
    new Promise<SelectGroup[]>((resolve) => {
      debouncedSearch(search, resolve);
    });

  return (
    <Select
      focusOnMount={focusOnMount}
      value={value}
      handleChange={(newValue) => {
        setValue(newValue);

        // Etracker event for tracking clicked searchresults
        window?._etracker?.sendEvent(
          // @ts-ignore
          new et_UserDefinedEvent(
            `${newValue?.label}`,
            "Search",
            "searchclick",
            "",
          ),
        );

        newValue?.isKrohnePick
          ? window.open(`https://pick.krohne.com/${newValue.value}`)
          : window.open(newValue?.url, "_self");
      }}
      asyncOptions={search}
      placeholder={title ? title : header.base?.search?.tb?.title}
      width={660}
      isSearch
      noOptionsText={header.base?.search?.ui?.title}
    />
  );
};

export const Search = ({ header }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.sm - 1}px)`,
  });
  const { country } = useLocale();
  const checkRTEInput = /^\s*\S.*$/.test(
    stripHtmlTags(header.base?.search?.tb?.text_body),
  );

  return (
    <div
      onClick={() => {
        setOpen(true);
      }}
    >
      <Shape variant="search" fill={colors.darkBlue} />
      <Lightbox
        open={open}
        setOpen={setOpen}
        size="large"
        heading={{
          title: header.base?.search?.tb?.label,
          stylingLevel: "h4",
        }}
        background="grey-triangle"
        wrapperClassName="search-nav"
        className="search-wrapper"
        disableAnimation={isMobile}
      >
        <div className="search-lightbox-content">
          <div className="search-header">
            <h3>{header.base?.search?.tb?.title}</h3>
            {header.base?.search?.tb?.subtitle && (
              <div
                dangerouslySetInnerHTML={{
                  __html: makeParagraph(header.base?.search?.tb?.subtitle),
                }}
              />
            )}
            {checkRTEInput && (
              <div
                dangerouslySetInnerHTML={{
                  __html: makeParagraph(header.base?.search?.tb?.text_body),
                }}
              />
            )}
          </div>
          <SearchBar header={header} focusOnMount />
          {!isMobile && (
            <div className="search-pick-banner">
              <div className="search-pick-banner-content">
                <h5>{header.base?.search?.pick?.tb?.title}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: makeParagraph(
                      header.base?.search?.pick?.tb?.text_body,
                    ),
                  }}
                ></div>
                <Button
                  variant="white-outline"
                  label={header.base?.search?.pick?.tb?.subtitle}
                  icon="caret-right-small"
                  onClick={() => country === "cn" && setOpen(false)}
                  url={country === "cn" ? "/wechat" : "https://pick.krohne.com"}
                />
              </div>
              <div className="search-pick-banner-image">
                <Cloudinary
                  media={header.base?.search?.pick?.im}
                  responsiveOrientation="height"
                  ar="ar169"
                  fitStrategy="cover"
                />
              </div>
            </div>
          )}
        </div>
        {isMobile && (
          <div className="search-pick-mobile">
            <Link
              label={header.base?.search?.pick.tb?.title}
              onClick={() => country === "cn" && setOpen(false)}
              href={country === "cn" ? "/wechat" : "https://pick.krohne.com"}
              icon={{
                variant: "info-circle",
                fill: colors.darkBlue,
                size: 18,
              }}
            />
          </div>
        )}
      </Lightbox>
    </div>
  );
};
