export const colors = {
  blue: "#005DA8",
  blueHighlight: "#2885D0",
  primary: "#005DA8",
  primaryHighlight: "#2885D0",
  orange: "#E94D19",
  orangeHighlight: "#FD612D",
  secondary: "#E94D19",
  secondaryHighlight: "#FD612D",
  darkBlue: "#455C6E",
  darkBlueHighlight: "#586C7D",
  tertiary: "#455C6E",
  tertiaryHighlight: "#586C7D",
  salmon: "#f6b8a3",
  green: "#28A745",
  gray100: "#222",
  gray80: "#99A2A9",
  gray60: "#B3B9BF",
  gray40: "#CCD1D4",
  gray20: "#E6E8EA",
  white: "#fff",
  whiteHighlight: "#E5EFF6",
};

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 1024,
  xl: 1200,
};
