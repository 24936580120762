import { DateTime } from "luxon";

export const formatDate = (date: string, locale = "k_en") => {
  if (!date) {
    return null;
  }

  const parsed = DateTime.fromJSDate(new Date(date));

  if (!parsed.isValid) {
    return null;
  }

  try {
    return parsed.setLocale(locale.split("_")[1]).toLocaleString({
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  } catch (error) {
    return date;
  }
};

export const formatEventDate = (
  startDate: string,
  endDate: string,
  locale = "k_en",
) => {
  const language = locale.split("_")[1];

  const start_date = DateTime.fromJSDate(new Date(startDate));
  const end_date = DateTime.fromJSDate(new Date(endDate));

  const isDayFirst = (() => {
    const dateFormat = new Intl.DateTimeFormat(language, {
      day: "numeric",
      month: "short",
    }).formatToParts(new Date());

    const dayIndex = dateFormat.findIndex((part) => part.type === "day");
    const monthIndex = dateFormat.findIndex((part) => part.type === "month");

    return dayIndex < monthIndex;
  })();

  if (!start_date.isValid && end_date.isValid) {
    return formatDate(endDate, locale);
  }
  if (!end_date.isValid && start_date.isValid) {
    return formatDate(startDate, locale);
  }

  let date = "";

  try {
    // Always write full dates in chinese
    if (language === "zh") {
      date = `${start_date.setLocale(language).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
      })}到${end_date.setLocale(language).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`;
    }

    if (start_date.month === end_date.month) {
      date = end_date.setLocale(language).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
      });

      if (start_date.day !== end_date.day) {
        switch (language) {
          case "de":
            date = `${start_date.day}. - ${date}`;
            break;
          case "fr":
            date = `${start_date.day} - ${date}`;
            break;
          case "zh":
            break;
          default:
            if (isDayFirst) {
              date = `${start_date.day}-${date}`;
            } else {
              const parts = date.split(" ");
              parts[1] = `${start_date.day}-${parts[1]}`;
              date = parts.join(" ");
            }
            break;
        }
      }
    } else {
      date = `${start_date.setLocale(language).toLocaleString({
        month: "short",
        day: "numeric",
      })} - ${end_date.setLocale(language).toLocaleString({
        year: "numeric",
        month: "short",
        day: "numeric",
      })}`;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }

  return date;
};
