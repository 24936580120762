import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import partition from "lodash/partition";
import set from "lodash/set";
import unset from "lodash/unset";
import omit from "lodash/omit";
import getConfig from "next/config";
import { Attribute_Option } from "types";
import {
  ContentsetEntity,
  FilterValues,
  Option,
  OptionPreview,
  ProcessedFilter,
  SelectOption,
  SelectedOptions,
  SelectedValue,
} from "../../constants/types";
import { unescape } from "../text-processing";

/**
 * Map to get input type by filter type
 */
export const filterTypeMap = {
  "single-value-radio-button": "radio",
  "multiple-value-check-box-list": "checkbox",
  "single-value-dropdown": "dropdown",
  search: "search",
};

/**
 * Get unique id for filter option
 * @param option Filter option
 * @param filter Filter
 * @returns Unique ID based on filter option
 */
export const createUniqueID = (option: Option, filter: ProcessedFilter) => {
  return `${option.parent}§${option.code}§${option.sourceType}§${
    filterTypeMap[filter.filter_type]
  }`;
};

/**
 * Get all filter value strings from selected options, is a bit overkill because they had .'s in their keys
 * @param options Selected options
 * @returns Filter value strings
 */
export const getSelectedValueStrings = (options: SelectedOptions) => {
  const allFilters: string[][] = [];
  if (options) {
    const keys = Object.keys(options);
    allFilters.push(keys);
    keys.map((key) =>
      allFilters.push(getSelectedValueStrings(options[`${key}`])),
    );
  }
  return allFilters.flat();
};

/**
 * Turn unique ID into filter value object
 * @param optionCode Unique id of option
 * @returns Filter value based on option
 */
export const parseSelectedValueString = (optionCode: string): SelectedValue => {
  const values = optionCode.split("§");
  return {
    parent: values[0],
    value: values[1],
    sourceType: values[2] as "category" | "attribute",
    checkType: values[3] as "radio" | "checkbox",
  };
};

/**
 * Replace unwanted characters and unescape html entities
 * @param str String to be processed
 * @returns Processed string
 */
export const decodeAndRemoveTags = (str: string) => {
  if (!str) return "";
  return unescape(str)
    .replace(/<[^>]*>/g, "")
    .replace(/\s+/g, "")
    .trim()
    .toLowerCase();
};

/**
 * Helper function to determine if an entity passes a particular filter condition
 * @param entity - The entity to be checked
 * @param filterCondition - The filter condition that the entity should satisfy
 * @returns true if the entity satisfies the filter condition, false otherwise
 */
const doesEntitySatisfyFilterCondition = (
  entity: ContentsetEntity,
  filterCondition: SelectedValue,
): boolean => {
  // Extract relevant properties from the filter condition
  const { sourceType, value, parent } = filterCondition;

  // Determine the property of the entity to be checked based on the sourceType of the filter condition
  const entityPropertyToCheck =
    sourceType === "category" ? "categories" : parent;

  // TODO: why is sourceType sometimes undefined?
  if (sourceType === "statamic") {
    // Get the relevant property of the entity, defaulting to an empty string if it doesn't exist
    const entityPropertyString = get(entity, entityPropertyToCheck, "");

    // Check if the entityPropertyString matches the value of the filter condition
    return entityPropertyString === value;
  }

  // Get the relevant property of the entity, defaulting to an empty array if it doesn't exist
  const entityPropertyArray = get(entity, entityPropertyToCheck, []) || [];

  if (isArray(entityPropertyArray)) {
    // Check if any item in entityPropertyArray has a code that matches the value of the filter condition
    return entityPropertyArray.some((item) => item.code === value);
  }
  return false;
};

/**
 * Function to separate filter values into exclusive and non-exclusive filters
 * @param filterValues Filter values to be separated
 * @returns Filters split into exclusive and non-exclusive filters
 */
const separateFiltersByType = (
  filterValues: SelectedValue[],
): {
  exclusiveFilters: SelectedValue[];
  nonExclusiveGroupedFilters: SelectedValue[][];
} => {
  const [exclusiveFilters, nonExclusiveFiltersArray] = partition(filterValues, {
    checkType: "radio",
  });
  const nonExclusiveGroupedFiltersByParent = groupBy(
    nonExclusiveFiltersArray,
    "parent",
  );

  const nonExclusiveGroupedFilters = Object.values(
    nonExclusiveGroupedFiltersByParent,
  )
    .map((filter) => filter.filter(Boolean))
    .filter((filters) => filters.length > 0);

  return {
    nonExclusiveGroupedFilters,
    exclusiveFilters: exclusiveFilters || [],
  };
};

/**
 * Determines if a single entity satisfies given filter conditions
 * @param entity - The entity to be checked against the filter conditions
 * @param nonExclusiveGroupedFilters - An array of filter groups, where each group contains filters that are not exclusive.
 *    An entity satisfies a filter group if it satisfies at least one filter in the group.
 *    An entity satisfies the nonExclusiveGroupedFilters if it satisfies all filter groups.
 * @param exclusiveFilters - An array of filters that are exclusive.
 *    An entity satisfies exclusiveFilters if and only if it satisfies all filters in this array.
 * @returns Returns true if the entity satisfies all the filter conditions, and false otherwise
 */
export const filterEntityBySelectedValueStrings = (
  entity: ContentsetEntity,
  exclusiveFilters?: SelectedValue[],
  nonExclusiveGroupedFilters?: SelectedValue[][],
): boolean => {
  // For the entity to pass the exclusive filters, it must satisfy all of them
  if (
    !isEmpty(exclusiveFilters) &&
    !exclusiveFilters.every((filter) =>
      doesEntitySatisfyFilterCondition(entity, filter),
    )
  ) {
    return false;
  }

  // For the entity to pass the non-exclusive filters, it must satisfy at least one filter in each group
  if (
    !isEmpty(nonExclusiveGroupedFilters) &&
    !nonExclusiveGroupedFilters.every((filterGroup) => {
      if (isEmpty(filterGroup)) return false;
      return filterGroup.some((filter) =>
        doesEntitySatisfyFilterCondition(entity, filter),
      );
    })
  ) {
    return false;
  }

  // If the entity passes all filter conditions, return true
  return true;
};

/**
 * Filter an entity by search values
 * @param entity A single entity
 * @param searchValues Object of search values
 * @returns True if entity matches the search values, else false
 */
export const filterEntityBySearchValue = (
  entity: ContentsetEntity,
  searchValues: { [key: string]: SelectOption },
): boolean => {
  // Early return if searchValues is empty
  if (isEmpty(searchValues)) return true;

  return Object.entries(searchValues).every(
    ([searchKey, { value: searchId }]) => {
      const target = get(entity, searchKey);

      if (isEmpty(target)) return false;

      if (isArray(target)) {
        return target.some((targetEntity) => targetEntity.id === searchId);
      }

      return entity.id === searchId;
    },
  );
};

/**
 * Filter an entity by product tags
 * @param entity A single entity
 * @param productTags Array of product tags
 * @returns True if entity matches the product tags, else false
 */
export const filterEntityByProductIdentifier = (
  entity: ContentsetEntity,
  productTags: Attribute_Option[],
): boolean => {
  // Early return if productTags is empty
  if (isEmpty(productTags)) return true;

  const target: string[] = get(entity, "tags.product_identifier");

  if (isEmpty(target) || !isArray(target)) return false;

  return productTags.some(({ code: productTag }) => {
    return target.includes(productTag);
  });
};

const filterAndSortEntitiesByIds = (
  entities: ContentsetEntity[],
  ids: string[],
): ContentsetEntity[] => {
  // Create a map of ids to their index positions for quick lookup
  const idIndexMap = new Map<string, number>();
  ids.forEach((id, index) => idIndexMap.set(id, index));

  // Filter entities to include only those with ids present in the ids array
  const filteredEntities = entities.filter((entity) =>
    idIndexMap.has(entity.id),
  );

  // Sort the filtered entities based on their index in the ids array
  filteredEntities.sort((a, b) => {
    return (
      (idIndexMap.get(a.id) ?? Number.MAX_SAFE_INTEGER) -
      (idIndexMap.get(b.id) ?? Number.MAX_SAFE_INTEGER)
    );
  });

  return filteredEntities;
};

/**
 * Filter an entity by search strings
 * @param entity A single entity
 * @param searchStrings Object with search strings
 * @returns True if entity matches the search strings, else false
 */
export const filterEntityBySearchStrings = (
  entity: ContentsetEntity,
  searchStrings: { [key: string]: string },
): boolean => {
  // Early return if searchStrings is empty
  if (isEmpty(searchStrings)) return true;
  // Create an array of search entries with decoded search strings
  const searchEntries = Object.entries(searchStrings).map(([key, value]) => [
    key,
    decodeAndRemoveTags(value),
  ]);

  return searchEntries.every(([searchKey, decodedSearchString]) => {
    const target = get(entity, searchKey);
    if (isEmpty(target)) return false;

    if (isArray(target)) {
      return target.some((targetEntity) =>
        decodeAndRemoveTags(targetEntity.title as string).includes(
          decodedSearchString,
        ),
      );
    }

    return decodeAndRemoveTags(target as string).includes(decodedSearchString);
  });
};

/**
 * Filter the array of entities based on selected options, search values, search input, and product tags.
 * @param entities The array of entities to filter.
 * @param filterValues The filter values to apply.
 * @param locale The locale to use for filtering.
 * @param preview Indicates whether the filter is used for preview generation.
 * @returns The filtered array of entities.
 */
export const filterEntities = async (
  entities: ContentsetEntity[],
  filterValues: FilterValues,
  locale: string = "k_en",
  preview = false,
): Promise<ContentsetEntity[]> => {
  const { selectedOptions, searchValue, searchInput, productTags } =
    filterValues;

  // Convert selected options to filter values
  const selectedValues = getSelectedValueStrings(selectedOptions).map(
    (option) => parseSelectedValueString(option),
  );

  // Separate filter values into non-exclusive grouped filters and exclusive filters
  const { nonExclusiveGroupedFilters, exclusiveFilters } =
    separateFiltersByType(selectedValues);

  // filter entites by elasticsearch
  const SEARCH_KEY = "search";
  const search = searchInput?.[SEARCH_KEY];
  // dont filter by search for previews
  if (search && !preview) {
    try {
      const { publicRuntimeConfig } = getConfig();
      const baseUrl = publicRuntimeConfig.BASE_URL;

      // request api route
      const response = await fetch(
        `${
          baseUrl.includes("localhost") ? "http://" : "https://"
        }${baseUrl}/api/elasticsearch?query=${search}&locale=${locale}`,
        {
          // dev and stage need basic auth
          headers: new Headers({
            Authorization: `Basic ${Buffer.from(
              `rheinschafe:RS#312546$`,
            ).toString("base64")}`,
          }),
          signal: AbortSignal.timeout(11000),
        },
      );
      if (!response.ok) {
        throw new Error(
          `Failed to fetch search results: ${response.statusText}`,
        );
      }
      const searchResult = await response.json();

      const ids = searchResult.hits.hits
        .map(
          (hit) =>
            typeof hit._source === "object" &&
            "id" in hit._source &&
            hit._source.id,
        )
        .filter(Boolean) as string[];

      entities = filterAndSortEntitiesByIds(entities, ids);
    } catch (error) {
      console.error("Error while fetching search results", error);
    }
  }

  return entities.filter((entity) => {
    // Check if the entity passes the filter values
    const isFilteredByValues = filterEntityBySelectedValueStrings(
      entity,
      exclusiveFilters,
      nonExclusiveGroupedFilters,
    );
    if (!isFilteredByValues) return false;

    // Check if the entity passes the search values
    const isFilteredBySearchValue = filterEntityBySearchValue(
      entity,
      searchValue,
    );
    if (!isFilteredBySearchValue) return false;

    // Check if the entity passes the product tags
    const isFilteredByProductIdentifier = filterEntityByProductIdentifier(
      entity,
      productTags,
    );
    if (!isFilteredByProductIdentifier) return false;

    // Check if the entity passes the search strings
    const isFilteredBySearchStrings = filterEntityBySearchStrings(
      entity,
      omit(searchInput, SEARCH_KEY),
    );
    if (!isFilteredBySearchStrings) return false;

    // If the entity passes all filters, return true to include it in the filtered results
    return true;
  });
};

/*

  Functions for calculation preview

*/

/**
 * Get preview data for the specified option.
 * @param optionCode Code of the option.
 * @param entities All entities.
 * @param pathesToParent Object pathes as an array of strings that lead to this option.
 * @param displayPreview Contentset option that indicates whether the preview should be displayed in the frontend.
 * @param processedFilter Processed filter data.
 * @param isTopLevel Indicates whether the option is at top level of filters
 * @param filterValues All filter values currently selected
 * @param isQuickFilter Indicates whether the option is a quick filter
 * @param hasPreview Indicates whether the option has a preview for the dlc
 * @returns Preview object with data on how many entities are filtered by this option and the state of the option.
 */
const getPreviewCountByOptionCode = async (
  optionCode: string,
  entities: ContentsetEntity[],
  pathesToParent: string[],
  displayPreview: boolean,
  filter: ProcessedFilter,
  isTopLevel: boolean,
  filterValues: FilterValues,
  isQuickFilter: boolean,
  hasPreview: boolean,
  locale?: string,
): Promise<OptionPreview> => {
  // If there is no pathToParent this filter can't be reached, except fitler is at top level
  if (isEmpty(pathesToParent) && !isTopLevel) return null;

  const selectedValue = parseSelectedValueString(optionCode);

  const preview: Partial<OptionPreview> =
    filter?.options?.find((e) => e.code === selectedValue.value)?.preview || {};

  const oldMaximum = preview.currentMaximum;

  const selectedPathToParent = pathesToParent.find((path) =>
    get(filterValues.selectedOptions, path),
  );
  const visible = isTopLevel || Boolean(selectedPathToParent) || hasPreview;

  // Filter the already filtered entities by this option and get the current maximum
  // number of entities that can be filtered by this option
  if (visible) {
    // Remove sibling options with same parent from selected options
    const clonedFilterValues = cloneDeep(filterValues);
    if (selectedPathToParent || isTopLevel) {
      // Create prefix because toplevel is handeled differently
      const prefix = isTopLevel ? "" : `${selectedPathToParent}.`;

      // Get siblings of current option, use all options if toplevel
      const siblingOptions = isTopLevel
        ? clonedFilterValues.selectedOptions || {}
        : get(clonedFilterValues.selectedOptions, selectedPathToParent, {});

      Object.keys(siblingOptions).forEach((siblingOptionCode) => {
        const siblingValue = parseSelectedValueString(siblingOptionCode);
        // Remove siblings with the same parent
        if (
          siblingValue.parent === selectedValue.parent &&
          siblingValue.value !== selectedValue.value
        ) {
          unset(
            clonedFilterValues.selectedOptions,
            `${prefix}${siblingOptionCode}`,
          );
        }
      });
      // Add current option to selected options, also add all nested options if there are any
      set(clonedFilterValues.selectedOptions, `${prefix}${optionCode}`, {
        ...get(
          clonedFilterValues.selectedOptions,
          `${prefix}${optionCode}`,
          {},
        ),
      });
    }

    // Filter entities with all current filters and the modified selected options
    preview.currentMaximum = (
      await filterEntities(entities, clonedFilterValues, locale, true)
    ).length;
  }

  // Used to save time by doing the direct calculation on already filtered entities
  let temporaryEntities: ContentsetEntity[] = entities;

  // Only compute the "maximum" value when it should be displayed in the frontend,
  // is in a preview and not in a quick filter
  if ((!("maximum" in preview) && displayPreview) || isQuickFilter) {
    // Filter all entities by this option and get the maximum number of entities that can be filtered by this option
    temporaryEntities = entities.filter((entity) =>
      filterEntityBySelectedValueStrings(entity, [selectedValue]),
    );
    preview.maximum = temporaryEntities.length;
  }

  // Calculate the number of entities directly filtered by this option and its parent filters
  // Do it in case the state doesn't exist yet or the current maximum value has changed
  // and it is not a quick filter
  if (
    (!("state" in preview) ||
      Boolean(oldMaximum) !== Boolean(preview.currentMaximum)) &&
    !isQuickFilter
  ) {
    pathesToParent.forEach((pathToParent) => {
      let hasMatches = get(preview, ["state", pathToParent, "hasMatches"]);

      if (typeof hasMatches !== "boolean") {
        const selectedValues = isTopLevel
          ? []
          : pathToParent.split(".").map((key) => parseSelectedValueString(key));

        // Here the direct count is calculated for each path leading to this option
        hasMatches = temporaryEntities.some((entity) => {
          return filterEntityBySelectedValueStrings(entity, [
            selectedValue,
            ...selectedValues,
          ]);
        });
      }

      // Determine the visibility of the option based on the counts and path to parent
      set(preview, ["state", pathToParent], {
        hasMatches,
        visibility: hasMatches
          ? preview.currentMaximum
            ? "show"
            : "gray"
          : "hide",
      });
    });
  }

  return preview as OptionPreview;
};

/**
 * Enrich options with preview data
 * @param filters Array of filters
 * @param entities All entities
 * @param pathesToParent Object with filter ids as keys and path to reach to here
 * @param displayPreview Indicates whether the preview should be displayed in the frontend
 * @param isTopLevel Indicates whether the filter is a top level filter
 * @param filterValues Current filter values
 * @param isQuickFilter Indicates whether the filter is a quick filter
 * @param previewFilterIds Array of filter ids that should appear in dlc preview
 * @returns Filters with enriched options
 */
export const addPreviewCountToFilterOptions = async (
  filters: ProcessedFilter[],
  entities: ContentsetEntity[],
  pathesToParent: { [filterId: string]: string[] },
  displayPreview: boolean,
  isTopLevel: boolean,
  filterValues: FilterValues,
  isQuickFilter: boolean,
  previewFilterIds: string[] = [],
  locale?: string,
): Promise<ProcessedFilter[]> => {
  const enrichedFilters = await Promise.all(
    filters?.map(async (filter) => {
      const basePathesToParent = pathesToParent?.[filter.id] || [""];

      const newPathesToParent = {};

      const options = await Promise.all(
        filter.options.map(async (option) => {
          const uniqueID = createUniqueID(option, filter);

          if (option.triggersChildFilter && option.filter.length) {
            option.filter.forEach((filterId) => {
              newPathesToParent[filterId] = [
                ...(newPathesToParent?.[filterId] || []),
                ...basePathesToParent.map(
                  (path) => `${path || ""}${path ? "." : ""}${uniqueID}`,
                ),
              ];
            });
          }

          const preview = await getPreviewCountByOptionCode(
            uniqueID,
            entities,
            basePathesToParent,
            displayPreview,
            filter,
            isTopLevel,
            filterValues,
            isQuickFilter,
            previewFilterIds.includes(filter.id),
            locale,
          );

          return {
            ...option,
            preview,
          };
        }),
      );

      const filteredOptions = options.filter((option) => option.preview);

      const newPreviewFilterIds = filteredOptions
        .filter((option) => option.has_preview)
        .map((option) => option.filter)
        .flat();

      const child_filter = await addPreviewCountToFilterOptions(
        filter.child_filter,
        entities,
        newPathesToParent,
        displayPreview,
        false,
        filterValues,
        isQuickFilter,
        newPreviewFilterIds,
        locale,
      );

      return { ...filter, child_filter, options: filteredOptions };
    }),
  );

  return enrichedFilters ?? [];
};
