import { FC, useEffect, useState } from "react";
import { ContainerProps } from "../../constants/types";

import { Row, Col, Container as BootstrapContainer } from "react-bootstrap";
import classNames from "classnames";

export const Container: FC<ContainerProps> = ({
  content,
  background,
  edge,
  marginTop,
  marginBottom,
  // backgroundOverlowBottom,
  children,
  className,
  id,
  layout,
  fluid = false,
  header,
  noContainer = false,
}) => {
  const [backgroundClass, setbackgroundClass] = useState<string>();

  useEffect(() => {
    switch (background) {
      case "grey":
        setbackgroundClass("bg-gray");
        break;
      case "blue":
        setbackgroundClass("bg-primary-gradient text-white");
        break;
      case "red":
        setbackgroundClass("bg-secondary text-white");
        break;
      default:
        break;
    }
  }, [background]);

  return (
    <div
      className={classNames(
        "container__section",
        backgroundClass,
        edge && "container__section--border",
        edge?.includes("bottom") && "m--bottom",
        edge?.includes("top") && "m--top",
        className,
        marginTop,
        marginBottom,
      )}
      {...(id ? { id } : {})}
      key={id}
    >
      {!noContainer ? (
        <BootstrapContainer fluid={fluid}>
          <Row>
            {layout === "heading-left" && header ? (
              <>
                <Col xl={3} className="heading-wrapper">
                  {header}
                </Col>
                <Col xl={9}>
                  <>{content ?? children}</>
                </Col>
              </>
            ) : (
              <Col md={12}>
                <>
                  {header}
                  {content ?? children}
                </>
              </Col>
            )}
          </Row>
        </BootstrapContainer>
      ) : (
        <Row className="d-block">
          <>
            {header}
            {content ?? children}
          </>
        </Row>
      )}
    </div>
  );
};
