import { LabelContextProps } from "constants/types";
import { FC, ReactNode, createContext, useContext } from "react";

export const LabelContext = createContext<LabelContextProps>(null);

export const LabelContextProvider: FC<{
  children: ReactNode;
  labels: LabelContextProps;
}> = ({ labels, children }) => {
  return (
    <LabelContext.Provider value={labels}>{children}</LabelContext.Provider>
  );
};

export const useLabelContext = () => {
  const context = useContext(LabelContext);
  if (!context) {
    throw new Error("useLabelContext must be used within LabelContextProvider");
  }
  return context;
};
