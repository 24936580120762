import { Button } from "components/button";
import { ButtonProps } from "constants/types";

// TODO: Does not work for outline and white buttons very good
export type ButtonGroupProps = {
  children?: React.ReactNode;
};
export const ButtonGroup = ({ buttons }: { buttons: ButtonProps[] }) => {
  if (buttons.length === 0) return null;
  const firstButton = buttons.shift();
  return (
    <div className="button-group">
      <Button {...firstButton} />
      {Boolean(buttons.length) &&
        buttons.map((button, index) => {
          return <Button key={index} {...button} partOfGroup />;
        })}
    </div>
  );
};
