import { ButtonGroup } from "components/button-group";
import { entitiesConfig } from "config/entity";
import { useContentsetContext } from "contexts/contentset-context";
import { useLabels } from "helpers/hooks";
import { FC, MouseEvent, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

const LoadMore: FC<{
  url?: string;
}> = ({ url }) => {
  const { contentset, dataInfo, isLoadingContentsetPage, filterAndLoadMore } =
    useContentsetContext();

  const entityConfig =
    entitiesConfig[contentset.entity_type] ?? entitiesConfig._default;
  const [showMoreEntitiesBtn, counterBtn, showAllBtn] = useLabels(
    [entityConfig?.show_more_entities_button, "Show more"],
    [entityConfig?.counter_definition, "Showing"],
    ["ui-912", "Show all"],
  );

  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [isLoadingAll, setIsLoadingAll] = useState(false);
  useEffect(() => {
    if (!isLoadingContentsetPage) {
      setIsLoadingPage(false);
      setIsLoadingAll(false);
    }
  }, [isLoadingContentsetPage]);

  if (dataInfo.itemsLeft <= 0 && !isLoadingContentsetPage) {
    return null;
  }

  return (
    <>
      <hr className="load-more-divider" />
      <Row className="mb-3 mt-0">
        <Col xs={12} className="load-more-wrapper">
          <div className="load-more-section">
            <p className="counter-text">
              {`${counterBtn?.cs_counter_prefix} ${
                dataInfo.itemsTotal - dataInfo.itemsLeft
              } ${counterBtn.cs_counter_fraction_separator} ${
                dataInfo.itemsTotal
              }`}
            </p>
            <div className="status-bar">
              <div
                className="bar"
                style={{
                  width: `${dataInfo.percentage}%`,
                }}
              />
            </div>
            {(contentset.show_more_entities_button ||
              /* TODO maybe remove this dlc layout condition here and set show_more_entites_button to true in statamic */
              contentset.layout === "dlc") && (
              <ButtonGroup
                buttons={[
                  {
                    className: "load-more-button",
                    variant: "primary",
                    icon: "plus-circle",
                    label: showMoreEntitiesBtn.short_title,
                    url,
                    loading: isLoadingPage,
                    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
                      setIsLoadingPage(true);
                      e.preventDefault();
                      void filterAndLoadMore();
                    },
                  },
                  {
                    className: "load-more-button",
                    variant: "primary",
                    label: showAllBtn.title,
                    loading: isLoadingAll,
                    onClick: (e: MouseEvent<HTMLAnchorElement>) => {
                      setIsLoadingAll(true);
                      e.preventDefault();
                      void filterAndLoadMore(null, false, false);
                    },
                  },
                ]}
              />
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default LoadMore;
