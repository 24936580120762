import { Button } from "components/button";
import { Heading } from "components/heading";
import { HeadingWrapperProps } from "constants/types";
import { getSpeakingId } from "helpers/text-processing";
import { get } from "lodash-es";
import { FC } from "react";

const HeadingWrapper: FC<HeadingWrapperProps> = (props) => {
  // headline stuff
  const {
    title,
    level,
    styling_level,
    alignment,
    subtitle,
    border,
    margin,
    slug,
  } = props;

  // button stuff
  const {
    outline,
    color,
    icon,
    text,
    link,
    target,
    openlanguageswitcher,
    blank,
    formmodal,
    formtext,
    form_ct,
  } = props;

  if (
    get(props, "slide_entity_title.0.title", title) === undefined ||
    get(props, "slide_entity_title.0.title", title)?.length === 0
  ) {
    return null;
  }

  return (
    <Heading
      margin={margin}
      alignment={alignment}
      contentRight={
        text ? (
          <Button
            label={text}
            outline={outline}
            icon={icon}
            variant={color}
            openlanguageswitcher={openlanguageswitcher}
            formmodal={formmodal}
            formtext={formtext}
            form_ct={form_ct}
            target={target}
            blank={blank}
            {...(link && { url: link })}
          />
        ) : null
      }
      decoration={border ? "underline" : "none"}
      level={level}
      stylingLevel={styling_level}
      subline={get(props, "slide_entity_title.0.subtitle", subtitle)}
      title={get(props, "slide_entity_title.0.title", title)}
      id={getSpeakingId({
        title: get(props, "slide_entity_title.0.title", title),
        slug: get(props, "slide_entity_title.0.slug", slug),
      })}
    />
  );
};

export default HeadingWrapper;
