import classNames from "classnames";
import { Button } from "components/button";
import { Cloudinary } from "components/cloudinary";
import { useLabels } from "helpers/hooks";
import { makeParagraph } from "helpers/text-processing";
import { useSlugPath } from "helpers/routing";
import { useRouter } from "next/router";
import { FC, useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Im, Tb } from "types";
import _debounce from "lodash/debounce";
import { createClipPathPolygon } from "helpers/clipPath";

export const Newsletter: FC<{
  newsletter: { im?: Im; tb?: Tb };
}> = (props) => {
  const { push } = useRouter();
  const path = useSlugPath();
  const [emailBtn] = useLabels(["ui-212", "Email"]);
  const [clipPath, setClipPath] = useState<string>("");
  const captionRef = useRef<HTMLDivElement>();

  // abused the tb field subtitle for the newsletter path
  const newsletterSubtitle = props.newsletter?.tb?.subtitle || "/newsletter";
  const newsletterPath =
    newsletterSubtitle.charAt(0) === "/"
      ? newsletterSubtitle
      : `/${newsletterSubtitle}`;

  useEffect(() => {
    const updateClipPath = () => {
      setClipPath(createClipPathPolygon(captionRef.current, 8, "start"));
    };

    if (captionRef.current) {
      updateClipPath();

      window.addEventListener("resize", _debounce(updateClipPath, 200));

      return () => {
        window.removeEventListener("resize", _debounce(updateClipPath, 200));
      };
    }
  }, [captionRef]);

  return (
    <Container
      className={classNames(
        "my-4 newsletter__container",
        path === newsletterPath && "hidden",
      )}
    >
      <Row>
        <Col>
          <div className="newsletter-disruptor">
            <div
              className="newsletter-caption"
              ref={captionRef}
              style={{ clipPath: clipPath }}
            >
              <div className="align-items-center container">
                <p className="h4">{props.newsletter?.tb?.label}</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: makeParagraph(props.newsletter?.tb?.text_body),
                  }}
                ></div>
                <form
                  className="form-inline"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="form-group">
                    <input
                      defaultValue=""
                      placeholder={emailBtn.label}
                      type="email"
                      id="newsletter-email"
                      onKeyUp={(event) => {
                        if (event.key === "Enter") {
                          push(newsletterPath);
                        }
                      }}
                    />
                  </div>
                  <Button
                    variant="white-outline"
                    icon="caret-right-small"
                    label={props.newsletter?.tb?.excerpt}
                    url={newsletterPath}
                  ></Button>
                </form>
              </div>
            </div>
            <div className="newsletter-image">
              <Cloudinary media={props.newsletter?.im} ar="ar169" />
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};
