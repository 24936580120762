import { Cloudinary } from "components/cloudinary";
import { CloudinaryProps } from "constants/types";
import { colors } from "theme/theme";
import { FunctionComponent, useState, useEffect } from "react";
import { sanitize } from "helpers/text-processing";
import { Shape } from "../shape";
import Select, { components } from "react-select";

export type Action = {
  image?: CloudinaryProps;
  onClick: Function;
  title: string;
};

export type ActionListProps = {
  actions: Array<Action>;
  className?: string;
  clearable?: boolean;
  selected?: string;
};

const SingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.image && <Cloudinary {...props.data.image} ar="ar11" />}
      {props.data?.reactElement ?? null}
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(props.data.label),
        }}
      ></span>
    </components.SingleValue>
  );
};

const Option = (props) => {
  const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
  const newProps = { ...props, innerProps: rest };
  return (
    <components.Option
      {...newProps}
      className="custom-option action-dropdown-option"
    >
      <span>
        {props.data.image && <Cloudinary {...props.data.image} ar="ar11" />}
      </span>
      {props.data?.reactElement ?? null}
      <span
        dangerouslySetInnerHTML={{
          __html: sanitize(props.data.label),
        }}
      ></span>
    </components.Option>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Shape variant={"caret-down-small"} fill={"#99A2A9"} />
    </components.DropdownIndicator>
  );
};

export const ActionList: FunctionComponent<ActionListProps> = ({
  actions,
  className,
  clearable = false,
  selected,
}) => {
  const [active, setActive] = useState(selected);

  // update default
  useEffect(() => {
    if (!clearable && actions && !selected) {
      setActive(actions[0]?.title);
    }
  }, [actions, clearable, selected]);

  // update selected
  useEffect(() => {
    if (selected) {
      setActive(selected);
    }
  }, [selected]);

  if (!actions) return null;

  return (
    <Select
      components={{ DropdownIndicator, SingleValue, Option }}
      className={`action-dropdown ${className || ""} react-select__container`}
      classNamePrefix="react-select"
      theme={(theme) => ({
        ...theme,
        borderRadius: 0,
        borderWidth: 1,
        fontSize: 14,
        lineHeight: 21,
        colors: { ...theme.colors, primary: colors.whiteHighlight },
      })}
      value={
        active && {
          value: actions.find((action) => action.title === active)?.title,
          label: actions.find((action) => action.title === active)?.title,
          image: actions.find((action) => action.title === active)?.image,
        }
      }
      onChange={(action) => {
        setActive(action.title);
        action.onClick();
      }}
      options={actions.map((action) => ({
        value: action.title,
        label: action.title,
        image: action.image,
        onClick: action.onClick,
      }))}
      isClearable={false}
      isSearchable={false}
      {...(typeof document !== "undefined"
        ? {
            menuPortalTarget: document.body,
          }
        : {})}
    />
  );
};
