interface shameScrollProps {
  close?: boolean;
  closeAll?: boolean;
  id?: string | number;
  open?: boolean;
}

let lightboxes = [];

export function shameScroll(props?: shameScrollProps) {
  if (
    document.body.classList.contains("lightbox-opened") ||
    ((props?.close || props?.closeAll) && !props?.open)
  ) {
    if (props.closeAll) {
      clearLightboxes();
    } else if (props.id) {
      unregisterLightbox(props.id);
    }

    if (lightboxes.length === 0) {
      removeLightboxClass();
    }
  } else {
    if (document.body.clientHeight > window.scrollY) {
      if (props.id) {
        registerLightbox(props.id);
      }

      addLightboxClass();
    }
  }
}

function addLightboxClass() {
  const scrolledHeader =
    document
      .querySelector(".header-scrolled:not(.header-narrow)")
      ?.getBoundingClientRect()?.height ?? 0;
  document.body.style.top = `-${window.scrollY - scrolledHeader}px`;
  document.body.classList.add("lightbox-opened");
}

function clearLightboxes() {
  lightboxes = [];
}

function registerLightbox(id: string | number) {
  lightboxes.push(id);
}

function removeLightboxClass() {
  const scrollPos = parseFloat(
    document.body.style.top.replace("-", "").replace("px", ""),
  );

  document.body.style.top = "auto";
  document.body.classList.remove("lightbox-opened");
  document.documentElement.classList.add("normal-scroll");
  window.scrollTo(0, scrollPos);
  document.documentElement.classList.remove("normal-scroll");
}

function unregisterLightbox(id: string | number) {
  lightboxes = lightboxes.filter((x) => x !== id);
}
