import {
  forwardRef,
  FunctionComponent,
  MouseEventHandler,
  ReactNode,
  Ref,
} from "react";

import classNames from "classnames";
import ForceLocaleLink from "components/link";
import { LoadingSpinner } from "components/loading-spinner";
import { useFormContext } from "contexts/form-context";
import { useLocaleContext } from "contexts/locale-context";
import { ButtonProps as BootstrapButtonProps } from "react-bootstrap";
import BootstrapButton from "react-bootstrap/Button";
import { ButtonProps, ButtonVariant } from "../../constants/types";
import { colors } from "../../theme/theme";
import { Shape } from "../shape";

type WrapperButtonProps = {
  children: ReactNode;
  href?: string;
  onClick?: MouseEventHandler;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
};

// why is  not working?
const BootstrapButtonWrapper = forwardRef<
  HTMLAnchorElement | HTMLButtonElement,
  WrapperButtonProps & BootstrapButtonProps
>((props, ref) => (
  <BootstrapButton
    {...props}
    href={props.href}
    onClick={props.onClick}
    ref={ref}
  >
    {props.children}
  </BootstrapButton>
));

BootstrapButtonWrapper.displayName = "BootstrapButtonWrapper";

export const Button: FunctionComponent<ButtonProps> = forwardRef(
  function Button(
    {
      variant = "white",
      label,
      icon = "caret-right-small",
      onClick,
      url,
      outline = false,
      className,
      target = "_self",
      locale,
      formmodal,
      openlanguageswitcher = false,
      formtext,
      form_ct,
      form_ct_data,
      loading,
      blank,
      enabled,
      partOfGroup = false,
      onlyIcon,
      ...props
    },
    ref,
  ) {
    const internalVariant = outline ? `${variant}-outline` : variant;
    let computedTarget = target;
    if (blank) {
      computedTarget = "_blank";
    }
    const { setEntity, toggleFormModal, setFormCt } = useFormContext() || {};
    const { setLocaleOverlay } = useLocaleContext();

    const fill = (internalVariant: ButtonVariant, isSpinner: boolean) => {
      let color = ["white", "white-outline", "white-border"].includes(variant)
        ? colors.blue
        : colors.white;
      if (internalVariant.includes("outline") && variant == "salmon")
        color = colors.darkBlue;
      if (internalVariant.includes("small")) color = colors.darkBlue;
      if (variant === "salmon-outline") color = colors.orange;

      // Extra handling for spinners, because on outline buttons the background where the spinner is placed is white
      if (
        isSpinner &&
        internalVariant.includes("outline") &&
        colors?.[internalVariant.replace("-outline", "")]
      ) {
        color = colors?.[internalVariant.replace("-outline", "")];
      }
      return color;
    };

    const size = (internalVariant) => {
      if (internalVariant.includes("small")) return "sm";
      return "lg";
    };

    return url ? (
      <ForceLocaleLink
        href={url}
        passHref
        prefetch={false}
        target={computedTarget}
        legacyBehavior
        locale={locale}
      >
        <BootstrapButtonWrapper
          target={computedTarget}
          variant={internalVariant}
          onClick={(e) => {
            if (formmodal) {
              setEntity({
                id: "",
                title: formtext,
              });
              if (form_ct) setFormCt(form_ct);
              if (form_ct_data) toggleFormModal(form_ct_data);
              else toggleFormModal(true);
            } else if (openlanguageswitcher) {
              setLocaleOverlay(true);
            }
            onClick?.(e);
          }}
          disabled={loading}
          onMouseEnter={() => {}}
          onMouseLeave={() => {}}
          size={size(internalVariant)}
          ref={ref as Ref<HTMLAnchorElement | HTMLButtonElement>}
          className={classNames(
            loading && "btn--loading",
            onlyIcon && "btn--icon",
            className,
            partOfGroup && "part-of-group",
          )}
          {...props}
        >
          {!partOfGroup && (
            <Shape
              variant={icon}
              size={variant === "small-icon" ? 12 : 18}
              fill={fill(variant, false)}
            />
          )}
          <div className="label p small">{label}</div>
          {loading && (
            <LoadingSpinner
              color={fill(variant, true)}
              size={16}
              className="btn-loader"
            />
          )}
        </BootstrapButtonWrapper>
      </ForceLocaleLink>
    ) : (
      <BootstrapButton
        variant={internalVariant}
        onClick={(e) => {
          if (formmodal) {
            setEntity({
              id: "",
              title: formtext,
            });
            if (form_ct) setFormCt(form_ct);
            if (form_ct_data) toggleFormModal(form_ct_data);
            else toggleFormModal(true);
          } else if (openlanguageswitcher) {
            setLocaleOverlay(true);
          }
          onClick?.(e);
        }}
        onMouseEnter={() => {}}
        onMouseLeave={() => {}}
        disabled={loading}
        size={size(variant)}
        target={computedTarget}
        className={classNames(
          loading && "btn--loading",
          onlyIcon && "btn--icon",
          className,
          partOfGroup && "part-of-group",
        )}
        ref={ref as Ref<HTMLButtonElement>}
        {...props}
      >
        {!partOfGroup && (
          <Shape
            variant={icon}
            size={variant === "small-icon" ? 12 : 18}
            fill={fill(variant, false)}
          />
        )}
        {!onlyIcon ? <div className="label p small">{label}</div> : null}

        {loading && (
          <LoadingSpinner
            color={fill(variant, true)}
            size={16}
            className="btn-loader"
          />
        )}
      </BootstrapButton>
    );
  },
);

export { default as RecommendButton } from "./recommend";
