import { Shape } from "components/shape";
import { ProcessedFilter } from "constants/types";
import { useContentsetContext } from "contexts/contentset-context";
import cloneDeep from "lodash/cloneDeep";
import { forwardRef, useCallback } from "react";
import { colors } from "theme/theme";
import omit from "lodash/omit";
import classNames from "classnames";

const SEARCH_KEY = "search";

type SearchFilterProps = {
  filter: ProcessedFilter;
  input?: string;
  onInputChange?: (input: string) => void;
  onSearch?: () => void;
};

export const SearchFilter = forwardRef<HTMLInputElement, SearchFilterProps>(
  function SearchFilter({ filter, input, onInputChange, onSearch }, ref) {
    const {
      searchInput,
      setSearchInput,
      filterAndLoadMore,
      isLoadingContentsetPage,
    } = useContentsetContext();

    const handleSearch = useCallback(() => {
      onSearch?.();
      void filterAndLoadMore(0);
    }, [onSearch, filterAndLoadMore]);

    const handleInputChange = useCallback(
      (input: string) => {
        if (onInputChange) {
          onInputChange(input || "");
        } else {
          if (input) {
            setSearchInput({ ...cloneDeep(searchInput), [SEARCH_KEY]: input });
          } else {
            setSearchInput(omit(searchInput, [SEARCH_KEY]));
          }
        }
      },
      [onInputChange, setSearchInput, searchInput],
    );

    return (
      <div
        className={classNames(
          "search-filter",
          searchInput?.[SEARCH_KEY] && "search-filter--active",
        )}
      >
        <Shape
          variant="search"
          fill={colors.primary}
          className="search-filter__icon"
          onClick={() => handleSearch()}
        />
        <input
          className="search-filter__input"
          value={input || searchInput[SEARCH_KEY] || ""}
          onChange={(event) => handleInputChange(event.target.value)}
          placeholder={filter?.label}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSearch();
            }
          }}
          ref={ref}
        />
        <Shape
          variant="close"
          fill={colors.gray60}
          className="search-filter__icon search-filter__icon--clear"
          onClick={() => {
            handleInputChange("");
            handleSearch();
          }}
        />
      </div>
    );
  },
);
