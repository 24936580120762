import { CloudinaryUrlHandler } from "components/cloudinary";
import { HeadProps } from "constants/types";
import {
  generateUrl,
  getPathWithoutLeadingOrTrailingSlash,
} from "helpers/data/utils";
import { findVariant } from "helpers/filter";
import { useLocale } from "helpers/locale";
import { getIsoLanguage, getIsoLocale } from "helpers/locale/utils";
import { useSlugPath } from "helpers/routing";
import { isLandingpage as checkLandingpage } from "helpers/routing/utils";
import { stripHtmlTags, unescape } from "helpers/text-processing";
import { isEmpty, isString, pickBy } from "lodash-es";
import { NextSeo } from "next-seo";
import getConfig from "next/config";
import Head from "next/head";
import { useRouter } from "next/router";
import { FC, useEffect } from "react";

/**
 *
 * @param args
 * @returns first argument, which is not empty and is not "null" string, falls back to undefined
 */
function firstNotEmpty<T>(...args: T[]): T | undefined {
  return args.find((arg) => !isEmpty(arg) && arg !== "null");
}

const PageHead: FC<HeadProps> = ({ entity, header, pc, contactMain }) => {
  const { language, country, locale } = useLocale();
  const isChina = country === "cn";
  const path = useSlugPath();
  const canonical = generateUrl(
    locale,
    getPathWithoutLeadingOrTrailingSlash(path),
  );

  const { publicRuntimeConfig } = getConfig();

  const domain = publicRuntimeConfig.BASE_URL;
  const isLandingpage = checkLandingpage(path);

  useEffect(() => {
    document.documentElement.lang = getIsoLocale(locale);
  });

  const countryTitle = header?.base?.title
    ?.toUpperCase?.()
    ?.includes?.("KROHNE")
    ? header?.base?.title
    : `KROHNE ${header?.base?.title || "KROHNE Group"}`;

  // RSS alternate meta tag
  const countryName = country === "k" ? "global" : country;
  const rssFeed = isLandingpage
    ? []
    : [
        {
          type: "application/rss+xml",
          title: `RSS - ${countryTitle} (${language.toUpperCase()})`,
          href: `https://${domain}/rss/${countryName}/${language}/feed.xml`,
        },
        {
          type: "application/atom+xml",
          title: `Atom - ${countryTitle} (${language.toUpperCase()})`,
          href: `https://${domain}/rss/${countryName}/${language}/atom.xml`,
        },
        {
          type: "application/rss+json",
          title: `RSS JSON - ${countryTitle} (${language.toUpperCase()})`,
          href: `https://${domain}/rss/${countryName}/${language}/feed.json`,
        },
      ];

  let title = firstNotEmpty(
    entity?.meta_title,
    pc?.meta_title,
    entity?.title,
    pc?.title,
  );

  if (entity?.type) {
    if (isEmpty(entity?.meta_title)) {
      title =
        firstNotEmpty(
          entity?.meta_title,
          pc?.meta_title,
          entity?.title,
          pc?.title,
        ) +
        (entity?.parent_category
          ? " " + entity?.parent_category?.[0]?.label
          : "");
    }
  }

  let description =
    firstNotEmpty(
      entity?.meta_description,
      entity?.opengraph_description,
      pc?.meta_description,
      pc?.opengraph_description,
      "",
      entity?.subtitle,
      pc?.subtitle,
    ) || "";

  const getImageMedia = (media) =>
    Array.isArray(media) ? getImageMedia(media?.[0]) : media;

  let imageMedia = firstNotEmpty(
    ...[
      entity?.opengraph_image,
      entity?.meta_image,
      entity?.key_visual,
      entity?.images,
      pc?.key_visual,
    ].map((image) => (typeof image !== "object" ? null : getImageMedia(image))),
  );

  // Handle Kp variants

  const { query } = useRouter();
  if (entity?.type === "kp") {
    const slug = (query?.variant ??
      query?.slug?.[query?.slug.length - 1]) as string;

    const entityVariants = entity.kp_variants
      ? [entity, ...entity.kp_variants]
      : [entity];

    const activeVariant = findVariant(entityVariants, slug);

    title = !isEmpty(activeVariant?.meta_title)
      ? activeVariant.meta_title
      : !isEmpty(activeVariant?.label)
      ? activeVariant?.label +
        (!isEmpty(activeVariant?.product_positioning)
          ? " " + activeVariant?.product_positioning
          : entity?.parent_category
          ? " " + entity?.parent_category?.[0]?.label
          : "")
      : !isEmpty(activeVariant?.title)
      ? unescape(stripHtmlTags(activeVariant?.title))
      : title;

    description =
      !isEmpty(activeVariant?.subtitle) && activeVariant?.subtitle !== "null"
        ? activeVariant?.subtitle
        : !isEmpty(unescape(stripHtmlTags(activeVariant?.excerpt))) &&
          unescape(stripHtmlTags(activeVariant?.excerpt)) !== "null"
        ? unescape(stripHtmlTags(activeVariant?.excerpt))
        : description;

    imageMedia = !isEmpty(activeVariant?.key_visual)
      ? getImageMedia(activeVariant?.key_visual)
      : imageMedia;
  }

  const image = CloudinaryUrlHandler({
    media: imageMedia,
    width: 1200,
    ar: "ar169",
    background: "grey-triangle",
    isChina: country === "cn",
  });

  if (title?.trim().toUpperCase() === "KROHNE") title = null;

  const ogTitle = firstNotEmpty(
    entity?.opengraph_title,
    pc?.opengraph_title,
    entity?.label,
    pc?.label,
    title,
    countryTitle,
  );

  const languageAlternates =
    header &&
    Object.entries(header.paths).map(([pathLanguage, path]) => {
      const pathLocale = `${country}_${pathLanguage}`;
      return {
        hrefLang: getIsoLanguage(pathLanguage),
        href: generateUrl(pathLocale, path),
      };
    });

  const schemaBuilder = () => {
    if (!contactMain) return null;
    const {
      title: name,
      phone_1: telephone,
      email,
      street_address: streetAddress,
    } = contactMain;

    const schema = {
      name,
      telephone,
      email,
      url: generateUrl(locale, ""),
      image: generateUrl(locale, "assets/logo.svg"),
      "@context": "https://schema.org",
      "@type": "Organization",
    };

    if (streetAddress) {
      return {
        ...schema,
        address: {
          "@type": "PostalAddress",
          streetAddress: streetAddress.replace("\n", ", "),
        },
      };
    }

    return schema;
  };

  const robotsTag: string | null =
    entity?.robots_tag && isString(entity.robots_tag)
      ? entity.robots_tag
      : null;

  return (
    <>
      {/* Everything goes here is not supported or not available as props in NextSeo props */}
      <Head key={"overwrites"}>
        <meta
          httpEquiv="content-language"
          content={language}
          key="content-language"
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(pickBy(schemaBuilder(), (a) => !isEmpty(a))),
          }}
        />
      </Head>

      <NextSeo
        title={
          title ? `${stripHtmlTags(title)} | ${countryTitle}` : countryTitle
        }
        nofollow={
          robotsTag?.includes("nofollow") ||
          entity?.publishing_status?.[0]?.code !== "published"
        }
        noindex={
          robotsTag?.includes("noindex") ||
          entity?.publishing_status?.[0]?.code !== "published"
        }
        canonical={canonical}
        additionalMetaTags={[
          {
            name: "title",
            content: title ? stripHtmlTags(title) : countryTitle,
          },
          {
            httpEquiv: "content-type",
            content: "text/html; charset=utf-8",
          },
          {
            name: "viewport",
            content:
              "width=device-width, initial-scale=1, maximum-scale=5, user-scalable=no",
          },
          {
            name: "description",
            content: description && stripHtmlTags(description).trim(),
          },
          {
            name: "copyright",
            content: "KROHNE Messtechnik GmbH",
          },
          {
            name: "date",
            content: entity?._lastupdate,
          },
          {
            name: "author",
            content: "KROHNE Messtechnik GmbH",
          },
          { httpEquiv: "x-ua-compatible", content: "IE=Edge" },
          {
            name: "google-site-verification",
            content: "VN9rtmnvQ0wDWVE_WfAJv9O6mLwEmQacFxE_LzcejTo",
          },
          {
            property: "fb:admins",
            content: header?.base?.facebook_id,
          },
        ]}
        openGraph={{
          type: "article",
          siteName: header?.base?.title,
          title: stripHtmlTags(ogTitle),
          url: typeof window !== "undefined" ? document.URL : undefined,
          description: description && stripHtmlTags(description).trim(),
          ...(image && {
            images: [
              {
                height: 627,
                secureUrl: image,
                width: 1200,
                url: image,
              },
            ],
          }),
        }}
        languageAlternates={languageAlternates}
        additionalLinkTags={[
          {
            rel: "preconnect",
            href: isChina
              ? `https://${publicRuntimeConfig.CLOUDINARY_CHINA_BASE_URL}`
              : `https://${publicRuntimeConfig.CLOUDINARY_BASE_URL}`,
          },
          {
            rel: "preconnect",
            href: "https://app.usercentrics.eu",
          },
          {
            rel: "apple-touch-icon",
            sizes: "180x180",
            href: "/assets/apple-touch-icon.png",
          },
          {
            rel: "icon",
            sizes: "32x32",
            type: "image/png",
            href: "/assets/favicon-32x32.png",
          },
          {
            rel: "icon",
            sizes: "16x16",
            type: "image/png",
            href: "/assets/favicon-16x16.png",
          },
          {
            rel: "icon",
            sizes: "16x16",
            type: "image/png",
            href: "/favicon.ico",
          },
          { rel: "mainfest", href: "/site.webmanifest" },
          ...rssFeed.map((feed) => ({
            rel: "alternate",
            ...feed,
          })),
        ]}
      />
    </>
  );
};

export default PageHead;
