import { useEffect, FC } from "react";
import Script from "next/script";
import getConfig from "next/config";
import { PublicRuntimeConfigType } from "constants/types";

/**
 * Checks if the given link is a user centrics anchor link
 *
 * @param link Path to check, should be "#uc-..." if it is a user centrics anchor link
 * @returns true if the link is a user centrics anchor link
 * @see https://docs.usercentrics.com/#/anchor-links
 */
export const isUserCentricsAnchorLink = (link: string) => {
  return link.startsWith("#uc-");
};

function updatePrivacyLink() {
  const pagePolicyLink = "/privacy";
  const pageImprintLink = "/imprint";

  const privacyPolicyLink = document.getElementsByClassName(
    "uc-banner-privacyurl",
  )?.[0] || { href: "" };
  const privacyPolicyLink2 = document.getElementsByClassName(
    "uc-banner-privacyurl",
  )?.[1] || { href: "" };

  const imprintLink = document.getElementsByClassName(
    "uc-banner-imprinturl",
  )?.[0] || { href: "" };

  privacyPolicyLink.href = pagePolicyLink;
  privacyPolicyLink2.href = pagePolicyLink;

  imprintLink.href = pageImprintLink;
}

function etrackerSetCookieConsent() {
  if (typeof window.usercentrics !== "undefined") {
    try {
      const csx = window.usercentrics.getConsents("PPkCxv14a");
      if (csx && csx.consentStatus)
        window._etracker.enableCookies("krohne.com");
      else window._etracker.disableCookies("krohne.com");
    } catch (e) {}
  }
}

const Tracker: FC<{ trackingScript: string }> = ({ trackingScript }) => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      waitForUserCentrics(etrackerSetCookieConsent);

      window.addEventListener("ucEvents", function (e) {
        setTimeout(updatePrivacyLink, 500);
      });

      window.addEventListener(
        "onConsentStatusChange",
        () => waitForUserCentrics(etrackerSetCookieConsent),
        false,
      );
      window.dataLayer = window.dataLayer || [];
    }
  }, []);

  // Stellen Sie sicher, dass UserCentrics vollständig geladen ist
  function waitForUserCentrics(callback) {
    if (window.UC_UI && window.UC_UI.isInitialized()) {
      callback();
    } else {
      setTimeout(function () {
        waitForUserCentrics(callback);
      }, 100);
    }
  }
  const { publicRuntimeConfig }: PublicRuntimeConfigType = getConfig();

  return (
    <>
      {publicRuntimeConfig.BASE_URL === "krohnechina.com" ? null : (
        <Script
          id="usercentrics-cmp"
          src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
          data-settings-id="GfTQrOCon"
          async
        />
      )}
      <div
        dangerouslySetInnerHTML={{
          __html: trackingScript,
        }}
      ></div>
    </>
  );
};

export default Tracker;
