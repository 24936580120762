import { Cloudinary } from "components/cloudinary";
import { getLocale } from "helpers/locale/utils";
import { Heading } from "components/heading";
import { isObject } from "lodash";
import { Ws } from "types";
import ForceLocaleLink from "components/link";

interface LanguageCardProps {
  entity: Ws;
  title?: string;
}

const LanguageCard = ({ entity, title }: LanguageCardProps) => {
  const formatLabel = (label: string) => {
    if (label.includes("/")) {
      label = label.split("/")[0].trim();
    } else if (label.includes("(")) {
      label = label.split("(")[0].trim();
    }

    return label;
  };

  const hasPath = (language: any): language is { path: string } =>
    isObject(language) &&
    "path" in language &&
    typeof language.path === "string";

  const getPath = (language: any) =>
    hasPath(language) ? language.path : undefined;

  const country = entity?.country?.[0]?.code;
  const defaultLanguage = (entity?.default_language?.[0]?.code || "en").slice(
    0,
    2,
  );
  const defaultLocale = country
    ? getLocale(country, defaultLanguage)
    : undefined;

  const defaultPath = getPath(
    entity?.available_language.find(
      (language) => language.code === entity?.default_language?.[0]?.code,
    ),
  );

  const defaultHref = country
    ? defaultPath
    : entity?.website_protocol_and_hostname;

  return (
    <div className="language-card flex-start w-full">
      <ForceLocaleLink
        locale={defaultLocale}
        href={defaultHref}
        style={{
          minWidth: "30px",
          width: "30px",
          height: "30px",
        }}
      >
        <Cloudinary media={entity?.key_visual?.[0]} ar="ar11" />
      </ForceLocaleLink>

      <div className="pl-1">
        <ForceLocaleLink locale={defaultLocale} href={defaultHref}>
          <Heading
            title={title ?? entity?.title}
            className="font-weight-bold mb-0"
            stylingLevel="h6"
          />
        </ForceLocaleLink>
        <div className="language-card__languages flex-start-center flex-wrap gap-x-1">
          {entity?.available_language
            .sort(
              (a, b) =>
                (b.code === entity?.default_language?.[0]?.code ? 1 : 0) -
                (a.code === entity?.default_language?.[0]?.code ? 1 : 0),
            )
            .map((attributeOption, index) => {
              const locale = country
                ? getLocale(
                    country,
                    attributeOption.code.slice(0, 2) || defaultLanguage,
                  )
                : undefined;

              return (
                <div className="flex-start" key={index}>
                  <ForceLocaleLink
                    locale={locale}
                    href={
                      country
                        ? getPath(attributeOption) ?? defaultPath
                        : entity?.website_protocol_and_hostname
                    }
                    className="language-card-link d-block text-truncate"
                  >
                    {formatLabel(attributeOption.label)}
                  </ForceLocaleLink>
                  {index + 1 < entity.available_language.length ? (
                    <span>,</span>
                  ) : null}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LanguageCard;
