import { Cloudinary } from "components/cloudinary";
import { Im } from "types";
import { Slider } from "components/slider";
import { TipTapComponent } from "components/wrapper/components/text";
import { useMediaQuery } from "react-responsive";
import classNames from "classnames";
import SliderNavButton from "components/slider/slider-nav-button";
import * as React from "react";

interface QuoteItem {
  author: string;
  key_visual: Im;
  profession: string;
  quote: string;
}

export interface QuotesProps {
  grid: QuoteItem[];
  image_crop: boolean;
  random: boolean;
}
function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}
export const Quotes: React.FC<QuotesProps> = (props) => {
  const isMobile = useMediaQuery({
    query: `(max-width: 1024px)`,
  });

  const filteredGrid = React.useMemo(
    () => props.grid?.filter((g) => Boolean(g?.quote)),
    [props.grid],
  );
  const [randomGrid, setRandomGrid] = React.useState(filteredGrid);

  const navPrevButton = React.useRef(null);
  const navNextButton = React.useRef(null);

  React.useEffect(() => {
    if (!props.random && filteredGrid) {
      setRandomGrid(shuffle(filteredGrid));
    }
  }, [filteredGrid, props.random]);

  return (
    <>
      <div className="quotes mt-5">
        {isMobile ? (
          <div className="quotes-navigation">
            <SliderNavButton ref={navPrevButton} role="prev" />
            <SliderNavButton ref={navNextButton} role="next" />
          </div>
        ) : null}

        <Slider
          className={classNames({ round: !props.image_crop })}
          loop={false}
          navigation={isMobile ? null : "default"}
          navNextButton={isMobile ? navNextButton : null}
          navPrevButton={isMobile ? navPrevButton : null}
          slidesPerView={isMobile ? 1 : 2}
          spaceBetween={isMobile ? 0 : 32}
          key={isMobile.toString()}
        >
          {randomGrid?.map((grid, index) => (
            <div
              key={index}
              className={classNames("quote", props.image_crop && "not-round")}
            >
              <div>
                {grid.key_visual && (
                  <Cloudinary media={grid.key_visual} ar="ar11" />
                )}
              </div>
              <figure className="text-left">
                <blockquote className="blockquote">
                  {/*
                    This condition exists because quotes got changed to use the texteditor but
                    there are still some places where they are still normal text in statamic.
                  */}
                  {typeof grid.quote === "string" ? (
                    grid.quote
                  ) : (
                    <TipTapComponent tree={grid.quote} />
                  )}
                </blockquote>
                {(grid.author || grid.profession) && (
                  <figcaption className="blockquote-footer">
                    <p className="author mb-0">{grid.author}</p>
                    <p className="profession">{grid.profession}</p>
                  </figcaption>
                )}
              </figure>
            </div>
          ))}
        </Slider>
      </div>
    </>
  );
};
