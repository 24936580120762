import { components, ControlProps } from "react-select";
import { Shape } from "components/shape";
import { colors } from "theme/theme";

const Control = ({ children, ...props }: ControlProps) => {
  return (
    <components.Control {...props}>
      {props.selectProps.isSearchable && !props.selectProps.hideSearchIcon && (
        <Shape variant="search" size={18} fill={colors.primary} />
      )}
      {children}
    </components.Control>
  );
};

export default Control;
