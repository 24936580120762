import { AdvancedVideo } from "@cloudinary/react";
import classNames from "classnames";
import { Button } from "components/button";
import {
  Cloudinary,
  CloudinaryVideo,
  useCloudinaryUrlGen,
} from "components/cloudinary";
import Link from "components/link";
import { LoadingSpinner } from "components/loading-spinner";
import { Shape } from "components/shape";
import { InteractiveMapMarker, InteractiveMapProps } from "constants/types";
import { useFullscreen, useLabels } from "helpers/hooks";
import { useLocale } from "helpers/locale";
import { generateRoute, useSlugPath } from "helpers/routing";
import NextImage from "next/image";
import { useRouter } from "next/router";
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CSSTransition } from "react-transition-group";
import { colors } from "theme/theme";
import { Im, Vi } from "types";

// how long should be waited on video to be loaded, in ms
const ZOOM_VIDEO_LOAD_TIMEOUT = 8000;

const InteractiveMap: FC<InteractiveMapProps> = ({
  background,
  markers,
  parentIndustryBackground,
  breadcrumb,
  pc,
}) => {
  const pageLevel = breadcrumb?.length - 1;

  const cld = useCloudinaryUrlGen();
  const Media = background.__typename === "Vi" ? CloudinaryVideo : Cloudinary;

  const [backToIndustryLabel] = useLabels(["ui-942", "back to industry"]);
  const parentPath = breadcrumb.at(-2)?.url;
  const { push, prefetch } = useRouter();
  const currentSlugPath = useSlugPath();
  const { locale } = useLocale();
  const [fullscreen, toggleFullscreen] = useFullscreen();

  useEffect(() => {
    if (typeof document === "undefined") return;

    const body = document.querySelector("body");
    body.classList.add("fullscreen-only-interactive-map");

    return () => {
      body.classList.remove("fullscreen-only-interactive-map");
    };
  }, []);

  const zoomOutVideo = pc[0]?.video_zoom_out?.[0];

  const nextUrl = useRef<InteractiveMapMarker["url"]>();
  const [activeZoomVideo, setActiveZoomVideo] = useState<Vi>();
  const [canPlayZoomVideo, setCanPlayZoomVideo] = useState(false);
  const [shouldPlayZoomVideo, setShouldPlayZoomVideo] = useState(false);
  const [zoomVideo, setZoomVideo] = useState<HTMLVideoElement>();
  const zoomVideoRef = useCallback((node: AdvancedVideo) => {
    if (!node) return;
    setZoomVideo(node.videoRef?.current);
  }, []);
  const zoomVideoTimeout = useRef<NodeJS.Timeout>();
  const [backgroundPosterAfterZoom, setBackgroundPosterAfterZoom] = useState<
    Vi | Im
  >();
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const markersRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // reset state when interactive map changes
    setActiveZoomVideo(undefined);
    setBackgroundPosterAfterZoom(undefined);
    setCanPlayZoomVideo(false);
    setShouldPlayZoomVideo(false);
    setNextPageLoading(false);
    setZoomVideo(undefined);
    nextUrl.current = undefined;
  }, [background, markers]);

  const canPlayTroughHandler = useCallback(() => {
    if (!canPlayZoomVideo) setCanPlayZoomVideo(true);
  }, [canPlayZoomVideo]);

  const endedHandler = useCallback(() => {
    if (zoomVideoTimeout.current) clearTimeout(zoomVideoTimeout.current);
    if (!nextUrl.current) return;
    document.documentElement.dataset.noLoading = "true";
    void push(nextUrl.current, undefined, { scroll: false });
    setNextPageLoading(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextUrl.current]);

  const markerHoverHandler = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, marker: InteractiveMapMarker) => {
      nextUrl.current = generateRoute(
        locale,
        marker?.url,
        locale,
        currentSlugPath,
      );
      if (
        !marker?.zoomVideo ||
        activeZoomVideo === marker.zoomVideo ||
        shouldPlayZoomVideo
      )
        return;
      setCanPlayZoomVideo(false);
      setActiveZoomVideo(marker.zoomVideo);
      setBackgroundPosterAfterZoom(marker.backgroundVideo);
    },
    [activeZoomVideo, currentSlugPath, locale, shouldPlayZoomVideo],
  );

  const markerClickHandler = useCallback(
    (event: MouseEvent<HTMLAnchorElement>, marker: InteractiveMapMarker) => {
      nextUrl.current = generateRoute(
        locale,
        marker?.url,
        locale,
        currentSlugPath,
      );
      if (!marker?.zoomVideo) return;
      event.preventDefault();

      // Prefetch page that should be loaded after video ends
      void prefetch(nextUrl.current);

      if (activeZoomVideo !== marker.zoomVideo) {
        setCanPlayZoomVideo(false);
        setActiveZoomVideo(marker.zoomVideo);
        setBackgroundPosterAfterZoom(marker.backgroundVideo);
      }
      setShouldPlayZoomVideo(true);
      zoomVideoTimeout.current = setTimeout(() => {
        if (!canPlayZoomVideo) {
          endedHandler();
        }
      }, ZOOM_VIDEO_LOAD_TIMEOUT);
    },
    [
      activeZoomVideo,
      canPlayZoomVideo,
      currentSlugPath,
      endedHandler,
      locale,
      prefetch,
    ],
  );

  const playZoomOut = useCallback(
    (event: MouseEvent) => {
      nextUrl.current = generateRoute(
        locale,
        parentPath,
        locale,
        currentSlugPath,
      );
      if (!zoomOutVideo) return;
      event.preventDefault();

      // Prefetch page that should be loaded after video ends
      void prefetch(nextUrl.current);

      if (activeZoomVideo !== zoomOutVideo) {
        setCanPlayZoomVideo(false);
        setActiveZoomVideo(zoomOutVideo);
        setBackgroundPosterAfterZoom(parentIndustryBackground);
      }
      setShouldPlayZoomVideo(true);
      zoomVideoTimeout.current = setTimeout(() => {
        if (!canPlayZoomVideo) {
          endedHandler();
        }
      }, ZOOM_VIDEO_LOAD_TIMEOUT);
    },
    [
      activeZoomVideo,
      canPlayZoomVideo,
      currentSlugPath,
      endedHandler,
      locale,
      parentPath,
      prefetch,
      zoomOutVideo,
    ],
  );

  useEffect(() => {
    if (!canPlayZoomVideo || !shouldPlayZoomVideo || !zoomVideo) return;

    setTimeout(() => void zoomVideo.play(), 1);
  }, [canPlayZoomVideo, shouldPlayZoomVideo, zoomVideo]);

  useEffect(() => {
    if (!zoomVideo) return;
    zoomVideo.addEventListener("canplaythrough", canPlayTroughHandler);
    zoomVideo.addEventListener("ended", endedHandler);

    return () => {
      zoomVideo.removeEventListener("canplaythrough", canPlayTroughHandler);
      zoomVideo.removeEventListener("ended", endedHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomVideo]);

  const backgroundVideoPoster = useMemo(() => {
    return backgroundPosterAfterZoom &&
      backgroundPosterAfterZoom.__typename === "Vi"
      ? cld
          .video(backgroundPosterAfterZoom.digital_asset_id)
          .format("webp")
          .toURL()
      : background.__typename === "Vi"
      ? cld.video(background.digital_asset_id).format("webp").toURL()
      : undefined;
  }, [backgroundPosterAfterZoom, background, cld]);

  useEffect(() => {
    // Preload poster of child pages
    if (markers) {
      markers.forEach((marker) => {
        const { backgroundVideo } = marker;
        if (!backgroundVideo) return;

        const posterUrl = cld
          .video(backgroundVideo.digital_asset_id)
          .format("webp")
          .toURL();
        const img = new Image();
        img.src = posterUrl;
      });
    }

    if (!parentIndustryBackground) return;
    const posterUrl = cld
      .video(parentIndustryBackground.digital_asset_id)
      .format("webp")
      .toURL();
    const img = new Image();
    img.src = posterUrl;
  }, [markers, parentIndustryBackground]);

  return (
    <div className="interactive-map h-full">
      <div className="interactive-map__background">
        <Media
          media={background}
          arBackground="ar169"
          background="grey-triangle"
          loop
          muted
          hideControls
          poster={background}
          priority={true}
        />
        {backgroundVideoPoster && (
          <NextImage
            src={backgroundVideoPoster}
            width={1920}
            height={1080}
            alt=""
            priority={true}
            className="interactive-map__background__poster"
          />
        )}
      </div>

      <div
        className={classNames(
          "zoomVideo",
          shouldPlayZoomVideo && canPlayZoomVideo && "active",
        )}
      >
        {activeZoomVideo && (
          <CloudinaryVideo
            media={activeZoomVideo}
            arBackground="ar169"
            background="grey-triangle"
            hideControls
            noAutoPlay
            muted
            poster={background}
            ref={zoomVideoRef}
          />
        )}
      </div>

      {((shouldPlayZoomVideo && !canPlayZoomVideo) || nextPageLoading) && (
        <LoadingSpinner color={colors.white} />
      )}

      <CSSTransition
        nodeRef={markersRef}
        in={!shouldPlayZoomVideo}
        timeout={300}
        classNames="markers"
        appear
      >
        <div className={classNames("markers")} ref={markersRef}>
          {markers?.map((marker, index) => {
            const { url, coordinates, label } = marker;
            return (
              <Link
                href={url}
                key={`${index}-${label}`}
                className="marker"
                style={{
                  left: `${coordinates.x * 100}%`,
                  top: `${coordinates.y * 100}%`,
                }}
                onMouseEnter={(event) => markerHoverHandler(event, marker)}
                onClick={(event) => markerClickHandler(event, marker)}
              >
                <>
                  <Shape
                    variant="plus-circle"
                    fill={colors.orange}
                    size={30}
                    className="icon"
                  />
                  <Shape
                    variant="caret-right-circle"
                    fill={colors.orange}
                    size={30}
                    className="icon hover"
                  />
                  <p className="label">{label}</p>
                </>
              </Link>
            );
          })}
        </div>
      </CSSTransition>

      <div className="interactive-map__buttons">
        {pageLevel === 3 && (
          <Button
            label={backToIndustryLabel.label}
            icon="caret-left"
            onClick={playZoomOut}
            url={parentPath}
          />
        )}
        <Button
          variant="white"
          label=""
          icon={fullscreen ? "windowed" : "fullscreen"}
          onlyIcon
          onClick={toggleFullscreen}
        />
      </div>
    </div>
  );
};

export default InteractiveMap;
