import Head from "next/head";
import { createContext, useCallback, useContext, useState } from "react";

const PreloadContext = createContext({
  addPreload: (hrefs) => {},
});

export const PreloadProvider = ({ children }) => {
  const [preloads, setPreloads] = useState([]);

  const addPreload = useCallback((hrefs) => {
    setPreloads((prev) => {
      const newPreloads = hrefs.filter(
        (href) => href && !prev.includes(href.href),
      );
      return [...prev, ...newPreloads];
    });
  }, []);

  return (
    <PreloadContext.Provider value={{ addPreload }}>
      <Head>
        {preloads.map(({ href, media }) => (
          <link
            key={href}
            rel="preload"
            fetchpriority="high"
            as="image"
            href={href}
            type="image/webp"
            media={media}
          />
        ))}
      </Head>
      {children}
    </PreloadContext.Provider>
  );
};

export const usePreload = () => {
  return useContext(PreloadContext);
};
