import Image from "next/image";

const Logo = ({ className }: { className?: string }) => {
  return (
    <Image
      src="/assets/logo.svg"
      alt="Krohne Logo"
      className={className}
      width={150}
      height={82}
      priority={true}
    />
  );
};

export default Logo;
