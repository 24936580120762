export type Provider = "facebook" | "linkedin" | "twitter" | "email";

export const getShareLink = (
  provider: Provider,
  { url, title }: { title?: string; url: string },
): string => {
  provider = provider.toLocaleLowerCase() as Provider;
  if (!["facebook", "linkedin", "twitter", "email"].includes(provider))
    return "";

  const encode = (str: string) => encodeURIComponent(str);

  switch (provider) {
    case "facebook":
      return `http://www.facebook.com/sharer/sharer.php?u=${encode(url)}${
        title ? `&title=${encode(title)}` : ""
      }`;

    case "twitter":
      return `https://twitter.com/intent/tweet?url=${encode(url)}${
        title ? `&text=${encode(title)}` : ""
      }`;

    case "linkedin":
      return `https://www.linkedin.com/sharing/share-offsite/?url=${encode(
        url,
      )}${title ? `&title=${encode(title)}` : ""}`;

    case "email":
      return `mailto:?body=${encode(url)}${
        title ? `&subject=${encode(title)}` : ""
      }`;
  }
};

export const getSocialShareLink = (
  share: any,
  url: string,
  title: string,
): { icon; provider; url } => {
  const provider = share.label.split(" ")[0];

  const encode_ = (str: string) => encodeURIComponent(str);

  const providers = {
    facebook: `http://www.facebook.com/sharer/sharer.php?u=${encode_(url)}${
      title ? `&title=${encode_(title)}` : ""
    }`,
    twitter: `https://twitter.com/intent/tweet?url=${encode_(url)}${
      title ? `&text=${encode_(title)}` : ""
    }`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encode_(
      url,
    )}${title ? `&title=${encode_(title)}` : ""}`,
  };

  return {
    provider,
    icon: /[^/]*$/
      .exec(share.key_visual?.[0].link_absolute_path)[0]
      .split(".")[0],
    url: Object.hasOwn(providers, provider.toLocaleLowerCase())
      ? providers[provider.toLocaleLowerCase()]
      : null,
  };
};
