import { Quotes, QuotesProps } from "components/quotes";
import { Container } from "components/container";

import { getEdgeSettings } from "components/wrapper/components";
import HeadingWrapper from "../heading";
import { FC } from "react";
import { WrapperComponentProps } from "constants/types";
import { getSpeakingId } from "helpers/text-processing";

interface QuotesWrapperProps extends WrapperComponentProps, QuotesProps {}

const QuotesWrapper: FC<QuotesWrapperProps> = (props) => {
  const anchorNavId =
    props.anchor_navigation && props.anchor_navigation_title
      ? getSpeakingId({ title: props.anchor_navigation_title })
      : null;

  return (
    <Container
      edge={getEdgeSettings(props)}
      marginTop={props.margin_top}
      marginBottom={props.margin_bottom}
      background={props.background_color}
      layout={props.header_layout}
      id={anchorNavId ? anchorNavId : `component__quotes-${props.index}`}
      className="section component__quotes"
    >
      <HeadingWrapper {...props} />
      <Quotes {...props} />
    </Container>
  );
};

export default QuotesWrapper;
