import { FC, ReactNode } from "react";
import { Icon, Shape } from "../shape";
import { colors } from "../../theme/theme";
import { sanitize } from "helpers/text-processing";
import classNames from "classnames";

export const ListItemWithShape: FC<{
  children: ReactNode;
  fill?: string;
  variant?: Icon;
}> = ({ children, variant = "caret-right", fill = colors.darkBlue }) => (
  <>
    <span
      dangerouslySetInnerHTML={{
        __html: sanitize(children),
      }}
    />{" "}
    <Shape variant={variant} fill={fill} size={12} />
  </>
);

export const List: FC<{
  children: ReactNode;
  className?: string;
  variant?: "arrow-list" | "styled-list-wrapper" | "check-list";
}> = ({ variant = "arrow-list", className, children }) => (
  <ul className={`${variant} ${className || ""}`.trim()}>{children}</ul>
);

export const StyledList: FC<{
  columns: string;
  enabled: boolean;
  layout: "narrow" | "" | "normal" | "medium";
  list: string[];
  list_icon: Icon;
  type: string;
}> = (props) => {
  return (
    <ul
      className={classNames(
        "list-inline",
        "list-styled",
        !props.list_icon && "mt-2",
        props.list_icon && "p-3 shadow",
        props.columns !== "1" ? "row" : "col",
        props.layout,
      )}
    >
      {props.list?.map((item, index) => (
        <li
          className={classNames(
            "list-inline-item",
            props.list_icon && "icon mb-2 px-0",
            "d-flex",
            `col-lg-${props.columns ? 12 / parseInt(props.columns) : 4}`,
            "col-md-6",
            "col-sm-12",
          )}
          key={index}
        >
          <p
            className={classNames(
              props.layout === "normal" && "h4",
              props.layout === "medium" && "h5",
              props.list_icon && "d-flex",
            )}
          >
            {props.list_icon && (
              <Shape
                variant={props.list_icon as Icon}
                fill={colors.blue}
                className="mr-2"
              />
            )}
            {item}
          </p>
        </li>
      ))}
    </ul>
  );
};
